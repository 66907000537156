const constants = {
    IS_LOGGED_IN: "ItalianNaturally" + '.isLoggedin',
    IS_ADMIN: "ItalianNaturally" + '.isAdmin',
    CSRF_TOKEN: "ItalianNaturally" + '.csrf_token',
    AUTH_TOKEN: "ItalianNaturally" + '.auth_token',
    GIMAGE_URL: "ItalianNaturally" + '.g_image_url',
    GID: "ItalianNaturally" + '.g_id',
    AUTH_ROLE: "ItalianNaturally" + '.auth_role',
    USER_ID: "ItalianNaturally" + '.userID',
    USER_NAME: "ItalianNaturally" + '.username',
    USER_EMAIL: "ItalianNaturally" + '.useremail',
    USER_DATA: "ItalianNaturally" + '.userInfo',
    LANGUAGE: "ItalianNaturally" + '.language',
    USER_SUBSCRIPTION_ID: "ItalianNaturally" + '.user_subscription_id'
}

export default constants;