import React, { useContext } from 'react';
import { AppContext } from '../../App';
import Modal from 'react-bootstrap/Modal';
import AnalysisModal from '../Copy/AnalysisModal'
import HowToModal from '../Copy/HowToModal'
import ConversationModal from '../Copy/ConversationModal'
import TravelScenarios from '../Copy/TravelScenarios'
import MethodModal from '../Copy/MethodModal'
import LessonsChapter1 from '../Copy/LessonsChapter1'
import WordCompositionCh1Modal from '../Copy/WordCompositionCh1Modal'
import CultureChapter1 from '../Copy/CultureChapter1'
import Register from '../Register';

const InModal = () => {

    const { dispatch, state } = useContext(AppContext);

    const open = state.modal.isOpen;

    const params = {
        register: {
            title: 'Create an account',
            size:'md'            
        },        
        analysis: {
            title: 'About our word analysis',
            size:'lg'            
        },
        howTo: {
            title:'Course structure (how-to)',
            size:'lg'            
        },
        conversation: {
            title:'Our conversations',
            size:'lg'            
        },
        method: {
            title:'Our innovative method',
            size:'lg'            
        },
        lessonsCh1: {
            title:'Grammar and Language Lessons of Chapter 1',
            size:'md'            
        },
        cultureCh1: {
            title:'Culture Notes of Chapter 1',
            size:'md'
        },
        travelScenarios : {
            title:'Travel Scenarios in the Vacation Mini Course',
            size:'md'
        }
    }

    return <Modal 
        show={open}
        size={params[state.modal.type].size}
        onHide={()=>dispatch({ type: 'CLOSE_MODAL' })}
    >
        {params[state.modal.type].title && <Modal.Header closeButton>
            <Modal.Title className="fs-3 text__lora">{params[state.modal.type].title}</Modal.Title>
        </Modal.Header>}
        <Modal.Body className='bg-transparent'>
            { state.modal.type=='register' && <Register page='splash' /> }
            { state.modal.type=='analysis' && <AnalysisModal /> }
            { state.modal.type=='howTo' && <HowToModal /> }
            { state.modal.type=='conversation' && <ConversationModal /> }
            { state.modal.type=='method' && <MethodModal /> }
            { state.modal.type=='lessonsCh1' && <LessonsChapter1 /> }
            { state.modal.type=='wordCompositionCh1' && <WordCompositionCh1Modal /> }
            { state.modal.type=='cultureCh1' && <CultureChapter1 /> }
            { state.modal.type=='travelScenarios' && <TravelScenarios /> }
        </Modal.Body>
        {/*<Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button>
        </Modal.Footer>*/}
    </Modal>
}

export default InModal;