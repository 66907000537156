import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

const TravelScenarios = () => {
	return (
					<>
            <p>
                Here are some of the settings of conversations in the Vacation Cramming course!
            </p>
            <ListGroup className="list-group-flush list-unstyled mt-3 mb-4">
            	<ListGroup.Item>Window shopping at the market</ListGroup.Item>
				<ListGroup.Item>Getting through a crowded space</ListGroup.Item>
				<ListGroup.Item>Getting in line at the museum</ListGroup.Item>
				<ListGroup.Item>A quick stop at the caffè</ListGroup.Item>
				<ListGroup.Item>The right time to use "Ciao!"</ListGroup.Item>
				<ListGroup.Item>Asking around for the bathroom</ListGroup.Item>
				<ListGroup.Item>Meeting with a local who doesnt speak English</ListGroup.Item>
				<ListGroup.Item>First stop at the restaurant</ListGroup.Item>
				<ListGroup.Item>First meeting someone</ListGroup.Item>
				<ListGroup.Item>Requesting help in English</ListGroup.Item>
				<ListGroup.Item>Sharing a bit about yourself</ListGroup.Item>
				<ListGroup.Item>Withdrawing cash</ListGroup.Item>
				<ListGroup.Item>Deciding between bus or train</ListGroup.Item>
				<ListGroup.Item>Out for aperitivo</ListGroup.Item>
				<ListGroup.Item>The impatient waiter</ListGroup.Item>
				<ListGroup.Item>Ready to make a purchase while out shopping</ListGroup.Item>
				<ListGroup.Item>Responding to someone who offers help</ListGroup.Item>
				<ListGroup.Item>Getting connected to WiFi</ListGroup.Item>
				<ListGroup.Item>Small talk</ListGroup.Item>
				<ListGroup.Item>Calling in a reservation</ListGroup.Item>
            </ListGroup>
            <Button variant="w-100 btn btn-lg btn-outline-primary">7 Days Free</Button>
          </>
	)
}

export default TravelScenarios;