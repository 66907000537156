import React, { Component } from 'react';

const audioType = 'audio/*';

class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {},
      miliseconds: 0,
      recording: false,
      medianotFound: false,
      audios: [],
      audioBlob: null,
      stream: null
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  handleAudioPause(e) {
    e.preventDefault();
    clearInterval(this.timer);
    this.mediaRecorder.pause();
    this.setState({ pauseRecord: true });
  }

  handleAudioStart(e) {
    e.preventDefault();
    this.mediaRecorder.resume();
    this.startTimer();
    this.setState({ pauseRecord: false });
  }

  startTimer() {
    // if (this.timer === 0 && this.state.seconds > 0) {
    this.timer = setInterval(this.countDown, 100);
    // }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.

    this.setState(prevState => {
      const miliseconds = prevState.miliseconds + 100;
      return ({ time: this.milisecondsToTime(miliseconds), miliseconds: miliseconds });
    });

    this.props.handleCountDown(this.state.miliseconds);
  }

  milisecondsToTime(milisecs) {

    let secs = milisecs / 1000;
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);


    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
      ms: milisecs
    };
    return obj;
  }

  async initRecorder() {
    navigator.getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;
    if (navigator.mediaDevices) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        if (this.props.mimeTypeToUseWhenRecording) {
          this.mediaRecorder = new MediaRecorder(stream, { mimeType: this.props.mimeTypeToUseWhenRecording });
        } else {
          this.mediaRecorder = new MediaRecorder(stream);
        }
        this.chunks = [];
        this.mediaRecorder.ondataavailable = e => {

          // generate url from blob
          const audioURL = URL.createObjectURL(e.data);
          // append videoURL to list of saved videos for rendering
          const audios = [audioURL];
          this.setState({ audios, audioBlob: e.data });
          this.props.handleAudioStop({
            url: audioURL,
            blob: e.data,
            chunks: [],
            duration: this.state.time
          });

        };

        this.stream = stream;
        this.props.handleNoMic(true); // false = no mic access
      } catch(err) {
        this.props.handleNoMic(false); // false = no mic access
        console.log('issue getting mic access');
        console.log(err);
      }
    } else {
      this.setState({ medianotFound: true });
      this.props.handleNoMic(false); // false = no mic access
      console.log('Media Decives will work only with SSL.....');
    }
  }

  stopRecording() {
    clearInterval(this.timer);
    this.setState({ time: {} });
    // stop the recorder

    if (this.state.medianotFound) {
      // do nothing with the mediarecorder
    } else {
      try {
        if (this.stream.getAudioTracks) {
          const tracks = this.stream.getAudioTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        } else {
          console.log('No Tracks Found')
        }

        this.mediaRecorder.stop();
      } catch {
        //
      }
      

    }

    // say that we're not recording and reset everything to before
    this.setState({ 
      pauseRecord: false,
      time: {},
      miliseconds: 0,
      recording: false,
      medianotFound: false,
      audios: [],
      audioBlob: null });
    
  }

  handleReset() {
    if (this.state.recording) {
      this.stopRecording();
    }
    this.setState({
      time: {},
      miliseconds: 0,
      recording: false,
      medianotFound: false,
      audios: [],
      audioBlob: null
    }, () => {

      this.props.handleReset(this.state);
    });

  }

  async startRecording() {

    try {
      // wipe old data chunks
      this.chunks = [];

      await this.initRecorder();
      // start recorder with 10ms buffer
      if (this.mediaRecorder.medianotFound) {
        this.startTimer();
      } else {
        this.mediaRecorder.start();
        this.startTimer();
        // say that we're recording
        this.setState({ recording: true });
      }
      
    } catch {
      console.log('error starting recording');
      this.startTimer();
    }    
  }

  render() {
    return null
  }

}

export default Recorder;

Recorder.defaultProps = {
  hideHeader: false,
  mimeTypeToUseWhenRecording: null,
  handleCountDown: (data) => {},
}