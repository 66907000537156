import React, { Fragment } from "react";
import {NavLink, useHistory} from "react-router-dom";
import Header from "../components/layout/Header";
import AuthAPI from '../utils/AuthAPI';
import Helper from '../utils/Helper';
import useCountDown from "react-countdown-hook";


function PasswordRecovery() {
    const history = useHistory();
	const [email, setEmail] = React.useState('');
	const [emailSent, setEmailSent] = React.useState(false);
    const [timeLeft, actions] = useCountDown(30000, 100);


    const next = () => {
        Helper.showSpinner();
        AuthAPI.sendRecoveryEmail({email: email})
            .then(res => {
                Helper.hideSpinner();
                if (res.meta.code === 200) {
                    actions.start();
                    setEmailSent(true);
                }
            })
            .catch(err => {
                Helper.hideSpinner();
				if(!err.response) {
					Helper.showToast('error', 5000, `Caspita! Something went wrong, if it keeps happening please write andrew@italianaturally.com.`, '');
					return;
				}
				const error = err.response.data;
				if(error.response.status >= 500) {
					Helper.showToast('error', 5000, error.response.statusText, error.response.status);
					return;
				}
				if(error.meta.code === 400) {
					if (error.response.errors) {
						const errors = error.response.errors;
						for (const property in errors) {
							Helper.showToast('warning', 5000, `${errors[property]}`, error.meta.code);
						}
					}
					if (error.response.error) {
						const error_msg = error.response.error;
						Helper.showToast('warning', 5000, error_msg, error.meta.code);
					}
				}
            });
    }

    const cancel = () => {
        history.goBack();
    }

	return (
        <div>
            <div className="auth password d-md-flex">
                <div className="container-fluid">
                    <div className="row">
                        <Header />
                        {/*  */}
                        <div className="recovery-form">
                            <div className={emailSent ? "d-none" : ""}>
                                <h4 className="text-headline mb-3">Password recovery</h4>
                                <p className="text-subline mb-4">Enter your email to reset password</p>
                                <div className={email && !Helper.validMail(email) ? "form-group invalid" : "form-group"}>
                                    <label>E-mail</label>
                                    <input type="email" 
                                        className={email.length > 0 ? "form-control valid" : "form-control" }
                                        name="email" 
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)} />
                                    {email && !Helper.validMail(email) && 
                                        <small className="err-msg">The email you entered is incorrect. Try to again.</small>
                                    }
                                </div>
                                <div className="row mx-0">
                                    <button className="btn btn-next mx-auto mb-3"
                                        disabled={!email || !Helper.validMail(email)}
                                        onClick={() => next()}
                                    >Next</button>
                                </div>
                                <div className="row mx-0">
                                    <button className="btn btn-back mx-auto"
                                        onClick={() => cancel()}
                                    >Cancel</button>
                                </div>
                            </div>
                            {/*  */}
                            <div className={!emailSent ? "d-none" : "thankyou-msg"}>
                                <h4 className="text-headline mb-3">Thank you!</h4>
                                <p className="text-subline mb-4">Click the link we emailed to <strong>{email}</strong> to create your new password</p>

                                <p className="mb-0 mt-5 pt-5">
                                    <button type="button" className="btn btn-link btn-resend" disabled={timeLeft > 0}
                                        onClick={() => next()}
                                    >Resend</button>
                                    <small> in 00:{(timeLeft / 1000).toFixed(0).toString().padStart(2, '0')}</small>
                                </p>
                                <p>
                                    <small>(or write to us at help@digivaxenterprise.com)</small>
                                </p>
                            </div>
                        </div>
                        {/*  */}
                    </div>
                </div>
            </div>
        </div>
	);
}

export default PasswordRecovery;