import Const from '../utils/Constants';
import Helper from '../utils/Helper';

export const initialState = {
    token: localStorage.getItem(Const.AUTH_TOKEN),
    auth: { 
            authentication_token:null, 
            googleID:localStorage.getItem(Const.GID), 
            googleImageURL:localStorage.getItem(Const.GIMAGE_URL), 
            googleName:null, googleBearerAccessToken:null, 
            googleIDToken:null },
    user: { email: localStorage.getItem(Const.USER_EMAIL) },
    loading: false,
};

const authReducer = (state, action) => {

    switch (action.type) {
    
        case 'LOGIN_SUCCESS':
            Helper.showToast('success', 5000, `Ciao! Parliamo!`, '');
            localStorage.setItem(Const.AUTH_TOKEN, action.payload.data.user.authentication_token);
            localStorage.setItem(Const.USER_EMAIL, action.payload.data.email);
            localStorage.setItem(Const.USER_SUBSCRIPTION_ID, action.payload.data.subscription_id);
            try {
                localStorage.setItem(Const.GIMAGE_URL, action.payload.data.user.googleImageURL ?? '');
                localStorage.setItem(Const.GID, action.payload.data.user.googleID ?? '');
            } catch {}
            return {
                ...state,
                token: action.payload.data.user.authentication_token,
                auth: { ...action.payload.data.user },
                user: { email:action.payload.data.email }
            };
        case 'LOGOUT_SUCCESS':
            Helper.showToast('success', 5000, `A presto!`, '');
            // localStorage.removeItem(Const.AUTH_TOKEN);
            // localStorage.removeItem(Const.USER_EMAIL);
            // localStorage.removeItem(Const.GIMAGE_URL);
            // localStorage.removeItem(Const.GID);
            localStorage.clear();
            document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            return {
                ...state,
                auth: { authentication_token:null, googleID:null, googleImageURL:null, googleName:null, googleBearerAccessToken:null, googleIDToken:null },
                user: {email:null},
                token: null,
            };
        // case 'GET_USER_SUCCESS':
        //     return {
        //         ...state,
        //         user: action.payload.data
        //     };

        default:
            return state;
    }
};

export default authReducer;
