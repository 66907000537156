import React from 'react';
import { AppContext } from "../../App";
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Logout} from '../../assets/icon/logout.svg'

const LogoutButton = (props) => {

	const { dispatch, state } = React.useContext(AppContext);

	const {height=30} = props;
	return (
		<IconButton disableRipple={true} size="large" onClick={() => dispatch({type: "LOGOUT_REQUEST",payload: {}})}>
		    <Logout style={{height:height, width:'auto'}} />
		</IconButton>
		)
	
}

export default LogoutButton