import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import HTMLRenderer from 'react-html-renderer'
import PlayWord from '../PlayWord'
import PlayWordButton from '../PlayWordButton'

export default function BasicModal(props) {
  const {buttonText=null, title=null, copy=null} = props
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button 
        sx={{fontFamily: "Cera Pro",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight:'14px',
          color:'#353535'
        }} 
        onClick={handleClickOpen}>{buttonText}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {typeof copy=='string' ? <HTMLRenderer html={copy} components={{playword:PlayWord, playwordbutton:PlayWordButton}} /> : copy}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
