import React, { useState } from "react";
import { AppContext } from "../../App";
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import NavFooter from '../../components/layout/NavFooter'
import { Modal } from 'react-responsive-modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

const Contact = () => {

    const [validated, setValidated] = useState(false);

    const { dispatch, state } = React.useContext(AppContext);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidated(true);
    };
    
    return (
        <>
        <NavFooter loginButton={true} conversationListButton={false} pwaInstallButton={true} />
        {/*  */}
        <Header 
            conversationListButton={ state.auth.token } 
            aboutCourseButton = { !state.auth.token } 
            contactButton={true}
            pwaInstallButton={false} 
            faqButton={true} 
            pricingButton={!state.auth.token} 
            aboutUsButton={true}  />
        <div className="container">
            <div className="row my-3 justify-content-center">
                <Form className="col-sm-12 col-md-10 col-lg-8">
                    <Row className="p-4 d-flex flex-row">
                        <h1 className="text-center text__lora">Contact us</h1>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com" required />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Ask us anything!</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            required
                            rows={5}
                            placeholder="Write us a message - we usually respond in a few hours!" />
                        <Form.Control.Feedback type="invalid">
                            Your message is empty!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Button type="submit" variant="primary text-white">Send</Button>
                </Form>
            </div>
            
            <div className="row my-3 justify-content-center tryItBanner">
                <div className="flex-row col-12 d-flex justify-content-around align-items-center py-5 flex-wrap">
                    <div className='fw-semibold text__banner--weight'>Ready to start speaking?</div>
                    <button className="buttonTryIt text__inter fw-normal layout__drop-shadow--white p-3" onClick=''>Try it for free!</button>
                </div>
            </div>
        </div>

        <Footer />
        </>
    );
}

export default Contact;