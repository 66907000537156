import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

const LessonsChapter1 = () => {
	return (
					<>
            <p>
                Here are some of the grammar, pronunciation, and topical lessons included in Chapter 1 of the main course!
            </p>
            <ListGroup className="list-group-flush list-unstyled mt-3 mb-4">
            	<ListGroup.Item>Singulars and Plurals</ListGroup.Item>
							<ListGroup.Item>There is, there are</ListGroup.Item>
							<ListGroup.Item>Formal "you"</ListGroup.Item>
							<ListGroup.Item>"To be" and "to have" in the present tense</ListGroup.Item>
							<ListGroup.Item>Conjugating verbs in the present tense</ListGroup.Item>
							<ListGroup.Item>"To like" in Italian</ListGroup.Item>
							<ListGroup.Item>Intro to prepositions</ListGroup.Item>
							<ListGroup.Item>Pronouncing C</ListGroup.Item>
							<ListGroup.Item>Superlatives</ListGroup.Item>
							<ListGroup.Item>Negations</ListGroup.Item>
							<ListGroup.Item>Commands</ListGroup.Item>
							<ListGroup.Item>Describing the temperature</ListGroup.Item>
							<ListGroup.Item>Weather</ListGroup.Item>
							<ListGroup.Item>Telling time</ListGroup.Item>
            </ListGroup>
            <Button variant="w-100 btn btn-lg btn-outline-primary">7 Days Free</Button>
          </>
	)
}

export default LessonsChapter1;