import React from "react";
import { NavLink } from 'react-router-dom';

function NoMatch() {
	return (
		<div className="App">
			<header className="App-header">
				<h1>404</h1>
				<h4>Not Found</h4>
				<NavLink to="/">Redirect to home page</NavLink>
			</header>
		</div>
	);
}

export default NoMatch;