import React from "react";
import {
	Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { Toaster } from 'react-hot-toast';

import './App.scss';

import {AuthRoutes, RegisterRoutes} from './auth/AuthRoutes';
import ConversationRoutes from './conversations/ConversationRoutes';
import PracticeRoutes from './practice/PracticeRoutes';
import Analysis from './how_use_purchase/Analysis';
import HowTo from './how_use_purchase/HowTo';
import Account from './account';
import ConfigureAccount from './account/ConfigureAccount';
import Main from './home/Main';
import Faq from './home/Main/Faq';
import OurStory from './home/Main/OurStory';
import Pricing from './home/Main/Pricing';
import Contact from './home/Main/Contact';
import Terms from './home/Terms';
import PrivacyPolicy from './home/PrivacyPolicy';
import NoMatch from './components/NoMatch';
import { reducer, initialState } from "./store/store";
import { applyMiddleware } from "./store/middleware";
import Helper from "./utils/Helper";
import AxiosConfig from './AxiosConfig';
import IdleTimer from "./utils/IdleTimer";
import AuthAPI from "./utils/AuthAPI";
import InModal from "./components/Modal/Modal"

export let outsideDispatch;
export const AppContext = React.createContext();

export const history = createBrowserHistory()

function App() {
	
	const [state, dispatch] = React.useReducer(reducer, initialState);
	
	const enhancedDispatch = applyMiddleware(dispatch);
  	outsideDispatch = enhancedDispatch;
	
	React.useEffect(() => {
		AxiosConfig.init();
	}, [])
	React.useEffect(() => {
		
		if (state.auth.token) {

			enhancedDispatch({
				type: "GET_USER_REQUEST",
				payload: {},
			})

			if (Helper.getSubdomain() !== '') {
				enhancedDispatch({
					type: "GET_REGISTRATION_FIELDS_REQUEST",
					payload: Helper.getSubdomain(),
				})
			}

			// Auto Logout
			const timer = new IdleTimer({
				timeout: 60 * 60 * 12, //expire after 12 hours
				onTimeout: () => {
					// logout
					enhancedDispatch({
						type: "LOGOUT_REQUEST",
						payload: {},
					});
				},
				onExpired: () => {
					//do something if expired on load
				}
			});
		
			return () => {
				timer.cleanUp();
			};
		}
	}, [state.auth.token])

	return (
		<AppContext.Provider
			value={{
				state,
				dispatch: enhancedDispatch,
			}}
		>
			<Toaster />
			<InModal />
			<Router history={history}>
				{/* A <Switch> looks through its children <Route>s and
					renders the first one that matches the current URL. */}
				<Switch>
				<Route path="/heartbeat">
				    <h3>lub dub</h3>
				</Route>
				<Route path="/404" exact>
					<NoMatch />
				</Route>
				<Route path="/auth">
					<AuthRoutes />
				</Route>
				<Route path="/privacy">
					<PrivacyPolicy />
				</Route>
				<Route path="/terms">
					<Terms />
				</Route>
				<Route path="/conversations">
					<ConversationRoutes />
				</Route>
				<Route path="/practice">
					<PracticeRoutes />
				</Route>
				<Route path="/analysis">
					<Analysis />
				</Route>
				<Route path="/howto">
					<HowTo />
				</Route>
				<Route path="/pricing">
					<Pricing />
				</Route>
				<Route path="/faq">
					<Faq />
				</Route>
				<Route path="/story">
					<OurStory />
				</Route>
				<Route path="/contact">
					<Contact />
				</Route>
				<Route path="/account">
					<Account />
				</Route>
				<Route path="/inital-configure-subscriptions">
					<ConfigureAccount />
				</Route>
				<Route path='/login-sign-up'>
					<RegisterRoutes />
				</Route>
				<Route path="/">
					<Main />
					{/* <Redirect to="/home" /> */}
				</Route>
				</Switch>
			</Router>
		</AppContext.Provider>
	);
}

export default App;
