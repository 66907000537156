import Const from '../utils/Constants';
import axios from 'axios';

const PureGET = async (url) => {
    try {
        const config = {
        }
        const res = await axios.get(url, config);
        return res.data;
    } catch (err) {
        throw err;
    }

}

const GET = async (url) => {
    try {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem(Const.AUTH_TOKEN),
                'Authentication-Token': localStorage.getItem(Const.AUTH_TOKEN),
            }
        }
        const res = await axios.get(url, config);
        return res.data;
    } catch (err) {
        throw err;
    }

}

const POST = async (url, data, headers = {}) => {
    try {
        const config = {
            headers: {
                ...headers,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem(Const.AUTH_TOKEN),
                'Authentication-Token': localStorage.getItem(Const.AUTH_TOKEN),
            }
        }
        const res = await axios.post(url, data, config);
        return res.data;
    } catch (err) {
        throw err;
    }
}

const PUT = async (url, data) => {
    try {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem(Const.AUTH_TOKEN),
                'Authentication-Token': localStorage.getItem(Const.AUTH_TOKEN),
            }
        }
        const res = await axios.put(url, data, config);
        return res.data;
    } catch (err) {
        throw err;
    }
}

const DELETE = async (url) => {
    try {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + localStorage.getItem(Const.AUTH_TOKEN),
                'Authentication-Token': localStorage.getItem(Const.AUTH_TOKEN),
            }
        }
        const res = await axios.delete(url, config);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export {
    PureGET,
    GET,
    POST,
    PUT,
    DELETE,
};

const Http = {
    PureGET,
    GET,
    POST,
    PUT,
    DELETE,
}

export default Http;