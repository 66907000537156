import React from "react";
import Header from "../components/layout/Header";
import {ReactComponent as Chapter1Stats} from '../assets/img/chapter_1_stats.svg'
import {ReactComponent as PracticeCardSVG} from './practiceCard.svg'
// import {ReactComponent as ScrollDown} from '../assets/icon/scroll-down.svg'
import {ReactComponent as ModalPopOut} from '../assets/icon/modal-popout.svg'
import {ReactComponent as CapeeshImg} from '../assets/img/main/capeesh.svg';
import {ReactComponent as ReplayImg} from '../assets/img/main/replay.svg';
import {ReactComponent as PlaybackSpeedImg} from '../assets/img/main/playbackSpeed.svg';
import { history } from "../App"
import './purchase.scss';

const capeeshFeedbackImg = require('../assets/img/feedback.png');
const recallImg = require('../assets/img/recall.png');
const hiddenImg = require('../assets/img/hidden.png');

function HowTo() {

    return (
        <>
            <Header isLogoCenter='true'/>
            <div className="container textMaterials howto">
                <div className="row py-1 py-lg-2 px-3">
                    <div className="pt-0 pt-lg-0 flex-column align-items-stretch">
                        <p><span role='button' onClick={() => history.push("/")}>return to <u>home</u></span></p>
                        <div>
                            <h1 className="text__lora text__lora-blog--title">Speaking is the foundation of our course</h1>
                            <p className="text__lora text__lora-blog--article">
                                We know that it's awkward talking alone to your phone or computer! But you know what's even worse?
                                That look of confusion on someone's face when your pronunciation is all wrong, and, despite your best efforts, they don't understand you.
                            </p>

                            <p className="text__lora text__lora-blog--article">
                                In the end, talking alone in the private of your own room is easy compared to walking up to a stranger in Italy and asking for a recommendation!
                                And besides, <span className='bold'>our system never saves your voice. Recordings are temporary, and just for you.</span>
                            </p>

                            <p className="text__lora text__lora-blog--article">
                                Although we focus on dialogue, each conversation is accompanied by a brief grammar/cultural lesson that has been curated by a university Italian professor.
                                Sometimes it is important to give context, especially when it comes to the <span className='bold'>numerous idioms used in daily Italian.</span>
                            </p>
                            
                            <h4 className="text__lora text__lora-blog--title">How to get the most out of this course</h4>

                            <div className="row pt-2 mb-4 align-items-start">
                                <div className="col-lg-4 d-flex flex-column align-items-center justify-content-start px-4">
                                    <div className='howto__bubbles howto__bubbles--1 my-2'></div>
                                    <p className="  my-2">Listen and Speak Along with Conversations</p>
                                </div>
                                <div className="col-lg-4 d-flex flex-column align-items-center justify-content-start px-4">
                                    <div className='howto__bubbles howto__bubbles--2 my-2'></div>
                                    <p className="  my-2">Work on your Pronunciation</p>
                                </div>
                                <div className="col-lg-4 d-flex flex-column align-items-center justify-content-start px-4">
                                    <div className='howto__bubbles howto__bubbles--3 my-2'></div>
                                    <p className="  my-2">Practice</p>
                                </div>                    
                            </div>

                            <p className="text__lora text__lora-blog--article">
                                Try to spend <span>20 minutes each day</span> working through the conversations.
                            </p>

                            <h4 className="text__lora text__lora-blog--title">Listen, without grand expectations</h4>

                            <div className='d-flex flex-row flex-wrap'>
                                <div className="col-12 col-lg-7 pl-0">
                                    <p className="text__lora text__lora-blog--article">
                                        We recommend that you start with listening - no pressure, just try to figure out where the words start and stop. Can you understand anything?
                                    </p>

                                    <div className='d-flex flex-row pb-1'>
                                        <div><PlaybackSpeedImg style={{margin:'10px', width:'45px', height:'auto'}} /></div>
                                        <div className='d-flex align-items-center'>
                                            <span className="pl-2 text__lora text__lora-blog--article">Don't be afraid to slow down the playback at any point!</span>
                                        </div>
                                    </div>

                                    <p className="text__lora text__lora-blog--article">
                                        Once you feel ready, move on to the next step: talk along with the native speakers. We know, it's awkward at first, but you'll get used to it! Do this until you think you're ready to try on your own.
                                    </p>
                                </div>
                                <div className="col-12 col-lg-5 justify-content-center d-flex align-items-center">
                                    <img style={{height:"270px"}} src={hiddenImg.default} />
                                </div>
                            </div>


                            <h4 className="text__lora text__lora-blog--title">Work on your pronunciation and pacing</h4>

                            <p className="text__lora text__lora-blog--article">
                                Next, you'll speak each half of the conversation alone. Remember to slow it down! Once you're done, listen to yourself and the native speaker, back to back!
                            </p>

                            <div className='d-flex flex-row align-items-center justify-content-center flex-wrap'>
                                <div className="col-12 col-lg-6 d-flex justify-content-center">
                                    <img style={{width:"80%", height:"auto"}} src={capeeshFeedbackImg.default} />
                                </div>
                                <div className="col-12 col-lg-6 p-0 p-lg-2">
                                    <p className="text__lora text__lora-blog--article">Words that we didn't quite understand will be highlighted.</p>
                                    <p className="text__lora text__lora-blog--article">The CapeeshScore, a smiley (or frowny) face, will let you know how well we understood you.</p>
                                </div>
                            </div>

                            <div className='d-flex flex-row'>
                                <div className="align-items-start d-flex"><ReplayImg style={{margin:'0px', width:'45px', height:'auto'}} /></div>
                                <div className="d-flex align-items-start pl-5">
                                    <p className="text__lora text__lora-blog--article">TIP! These feedback tools are helpful, but it's more important that you listen back to yourself (by clicking the smiley face above). If the CapeeshScore is disabled on your device, you'll see this icon instead.</p>
                                </div>
                            </div>

                            <h4 className="text__lora text__lora-blog--title">Recall Italian</h4>

                            <p className="text__lora text__lora-blog--article">
                                By now you've repeated this conversation dozens of times, and it's time to recall the Italian from memory!
                            </p>

                            <div className='d-flex flex-row flex-wrap'>
                                <div className="col-12 col-lg-6 pl-0">
                                    <p className="text__lora text__lora-blog--article">Words that we didn't quite understand will be highlighted.</p>
                                    <p className="text__lora text__lora-blog--article">The CapeeshScore, a smiley (or frowny) face, will let you know how well we understood you.</p>
                                </div>
                                <div className="col-12 col-lg-6 justify-content-center d-flex align-items-center">
                                    <img style={{maxWidth:"80%", maxHeight:"200px"}} src={recallImg.default} />
                                </div>
                            </div>

                            <h4 className="text__lora text__lora-blog--title">We help you retain what you've learned</h4>

                            <p className="text__lora text__lora-blog--article">Once you've finished a conversation, jump over to Practice Mode. We keep track of how well you've learned something, and use that to optimize your practice.</p>

                            <div className="my-4 d-flex justify-content-center"><PracticeCardSVG style={{width:'80%', height:'auto'}}/></div>

                            <p className="text__lora text__lora-blog--article">If it was easy for you to remember, we'll show it to you less often. If it was hard to remember, we'll prompt you again soon!</p>

                            <h4 className="text__lora text__lora-blog--title">Enough reading, let's talk! Parliamo!</h4>

                        </div>
                    </div>
                </div>
                
                <div className="row py-0 py-lg-1 cell-splash my-4">
                    <div className="pt-0 pt-lg-0 flex-column align-items-stretch">
                        <div className="d-flex flex-row justify-content-center m-4">
                            <button className="buttonTryIt text__inter fw-normal layout__drop-shadow p-3" onClick={() => history.push("/login-sign-up")}>Continue to the Course!</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HowTo;