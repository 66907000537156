import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from "../App";

const IsMobile = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { dispatch, state } = useContext(AppContext);
  const isMobileScreen = () => window.matchMedia('(max-width: 767px)').matches;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { windowWidth, isMobileScreen };

};

export default IsMobile;
