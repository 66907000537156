import { GET, POST } from './Http';

const apiBase = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : '/api';

// ========== User Login =========== //
const login = async (credential) => {
    const url = `${apiBase}/user/userlogin?include_auth_token`;
    return POST(url, credential);
}

// ========== User Login Google =========== //
const loginGoogle = async (credentialGoogle) => {
    const url = `${apiBase}/user/login_google_account`;
    return POST(url, credentialGoogle);
}

// ========== User Logout =========== //
const logout = async () => {
    const url = `${apiBase}/logout`;
    return POST(url, {});
}
// ========= Register ========= //
const register = async (data) => { // step 1. email, password sign up
    const url = `${apiBase}/user/register`;
    return POST(url, data);
}

const registerGoogle = async (data) => { // step 1. email, password sign up
    const url = `${apiBase}/user/register_google_account`;
    return POST(url, data);
}

const registerPartner = async (data) => { // step 2. organization register
    const url = `${apiBase}/org/register_partner`;
    return POST(url, data);
}

// ========= Forgot Password ========== //
const sendRecoveryEmail = async (data) => {
    const url = `${apiBase}/reset`;
    return POST(url, data);
}

const resetPassword = async (token, data) => {
    const url = `${apiBase}/reset/${token}`;
    return POST(url, data);
}

// ========== Confirm email and activate account //

const confirmEmail = async (token) => {
    const url = `${apiBase}/user/confirm_email/${token}`;
    return GET(url);
}

// ====== resend confirmation email

const sendConfirmation = async (data) => {
    const url = `${apiBase}/confirm`;
    return POST(url, data);
}

// ========= Check Admin ========== //
const isAdmin = async () => {
    const url = `${apiBase}/user/isadmin`;
    return GET(url);
}

// ========= ./Check Admin ========== //

const AuthAPI = {
    login: login,
    loginGoogle: loginGoogle,
    logout: logout,
    register: register,
    registerGoogle:registerGoogle,
    registerPartner: registerPartner,
    sendRecoveryEmail: sendRecoveryEmail,
    resetPassword: resetPassword,
    confirmEmail: confirmEmail,
    confirmEmail:confirmEmail,
    isAdmin: isAdmin,
    sendConfirmation:sendConfirmation
}

export default AuthAPI;
