import React, { useState, useEffect } from 'react';
import { useLocation, NavLink} from 'react-router-dom';
import { AppContext, history } from "../../../App"
import {ReactComponent as StudioRinascitaIcon} from '../../../assets/icon/studio_rinascita.svg'
import './footer.scss';
import Button from 'react-bootstrap/Button';

const Footer = () => {

    return (
        <footer className="">
            <div className="justify-content-between pb-4 container footer">
                <StudioRinascitaIcon className="footer__logo"/>
                <div className='footer__buttons'> 
                    <Button variant="link" className="fs-6 p-0 m-0 text-dark border-0" onClick={() => history.push('/privacy')}>privacy policy</Button><br/>
                    <Button variant="link" className="fs-6 p-0 m-0 text-dark border-0" onClick={() => history.push('/terms')}>terms of service</Button><br/>
                    © 2024 studio rinascita
                </div>
            </div>
        </footer>
    );
}

export default Footer;