import whatThe from './what-the.svg'
import whatTheReplay from './what-the-replay.svg'
import playback from './playback.svg'

const CapeeshScore = (props) => {
  const { score, use=null } = props;

  let thisStyles = null;
  let numberStyle = null;
  let color = null;

  if (score=='?') {
    color = "hsl(0,0%,50%)";
  } else {
    color = "hsl("+(1.3*score).toString()+",85%, 45%)";
  }

  if (use=='conversationAbout') {
      thisStyles = {
      margin: '0 4px 0 4px',
      paddingRight:'16px',
      height:'25px',
      width:'40px',
      backgroundImage:"url("+ whatThe +")",
      backgroundSize: '22px 25px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      fontWeight:'700',
      color:color
    }

    numberStyle = {width:'100%', textAlign:'right', display:'block', paddingTop:"0", lineHeight:'15px'};

  } else if (use=='conversationLine') {
    thisStyles = {
      // margin: '0 4px 0 4px',
      // paddingRight:'23px',
      // height:'40px',
      // width:'48px',
      // backgroundImage:"url("+ whatTheReplay +")",
      // backgroundSize:' 45px auto',
      // backgroundRepeat:' no-repeat',
      // backgroundPosition:' right center',
      // fontWeight:'700',
      // color:color

      display:'block',
      position:'absolute',
      top:'-3px',
      
      backgroundColor:'white',
      borderRadius:'18px',

      height:'40px',
      width:'40px',
      paddingTop:'3px',

      
      backgroundImage:"url("+ whatTheReplay +")",

      backgroundSize: 'auto 27px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right bottom',

      fontWeight:'700',
      textAlign:'center',
      lineHeight:'13px',
    }


    numberStyle = {width:'100%', textAlign:'left', display:'block', paddingTop:"0",paddingLeft:"2px"};

  } else if (use=='conversationLineCapeeshDisabled') {
    thisStyles = {
      // margin: '0 4px 0 4px',
      // paddingRight:'23px',
      // height:'40px',
      // width:'48px',
      // backgroundImage:"url("+ whatTheReplay +")",
      // backgroundSize:' 45px auto',
      // backgroundRepeat:' no-repeat',
      // backgroundPosition:' right center',
      // fontWeight:'700',
      // color:color

      display:'block',
      position:'absolute',
      top:'-3px',
      
      backgroundColor:'white',
      borderRadius:'18px',

      height:'40px',
      width:'40px',
      paddingTop:'3px',

      
      backgroundImage:"url("+ playback +")",

      backgroundSize: 'auto 27px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',

      fontWeight:'700',
      textAlign:'center',
      lineHeight:'13px',
    }


    numberStyle = {width:'100%', textAlign:'left', display:'block', paddingTop:"0",paddingLeft:"2px"};

  } else if (use=='practiceReview') {
    thisStyles = {
      margin: '0 4px 0 4px',
      paddingRight:'40px',
      height:'90px',
      width:'90px',
      backgroundImage:"url("+ whatThe +")",
      backgroundSize:' auto 80px',
      backgroundRepeat:' no-repeat',
      backgroundPosition:' right bottom',
      fontWeight:'700',
      fontSize:'42px',
      lineHeight:'42px',
      color:color
    }

    numberStyle = {width:'100%', textAlign:'left', display:'block', paddingTop:"0"};
  }

  const faceStyle = {
    fill:'gold'
  }

  const mouthStyle = {
    fill:'none',
    stroke:'#000',
    strokeWidth:'6px',
    strokeLinecap:'round'
  }

  const leftEyebrowStyle = {
    transform:'rotate(80deg)',
    transformOrigin:'100px 82px',
    transition: 'all .5s linear'
  }

  const rightEyebrowStyle = {
    transform:'rotate(100deg)',
    transformOrigin:'156px 82px',
    transition: 'all .5s linear'
  }

  return (
    
      <div style={thisStyles} className={use.includes('conversationLine')?'capeesh-score':''}>
        { use === 'conversationAbout' ?
          <div style={numberStyle}>{score}</div>
          : 
          <div style={numberStyle}>
            { use !== 'conversationLineCapeeshDisabled' && 
              <svg width={use=='conversationLine' ? "20" : "40"} height={use=='conversationLine' ? "20" : "40"} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="256" cy="256" r="256" style={{fill:"hsl("+(1.3*score).toString()+",85%, 45%)"}}/>
              <path d={`M ${155-2*(score/100)} ${379-43*(score/100)}C${201-2*(score/100)} ${323+77.5*(score/100)} 308.5 ${294+111*(score/100)} 363 ${353-16*(score/100)}`} stroke="white" style={{fill:'none'}} strokeWidth="50" strokeLinecap="round"/>
              <circle cx="178" cy="206" r={`${55-15*(score/100)}`} fill="white"/>
              <circle cx="340" cy="206" r="40" fill="white"/>
              </svg>
            }
          </div>
        }
      </div>
  );
};

export default CapeeshScore;