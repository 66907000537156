import React from 'react';
import { AppContext, history } from "../App";
import { useLocation } from 'react-router-dom';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Helper from "../utils/Helper";
import { Modal } from 'react-responsive-modal';
import UserAPI from '../utils/UserAPI';
import moment from 'moment';
import SubscriptionAPI from '../utils/SubscriptionAPI';
import { makeStyles } from '@material-ui/core/styles';
import PaymentForm from '../components/PaymentForm'
import StripePaymentForm from "../components/StripePaymentForm";
import Register from "../components/Register";
import './_account.scss'

const authData = {
    apiLoginId: '9U5vymjGW5U',
    clientKey: '46r2Dw7SLxS96V6X',
};

// main section img
//const bgOverviewDesktop = require('../assets/img/main/bgOverview-Desktop.svg');
const conversationImg = require('../assets/img/main/conversation_sample.png');


const family = require('../assets/img/about/family1.jpg');
const learningBrainIcon = require('../assets/icon/learningBrain.svg');
const speakListenIcon = require('../assets/icon/speakListen.svg');
const graphIcon = require('../assets/icon/graph.svg');


const Account = () => {
    const { dispatch, state } = React.useContext(AppContext);
    const subscribeRef = React.useRef(null);
    const riskFreeRef = React.useRef(null);
    const faqRef = React.useRef(null);
    const location = useLocation();

    const [conf, setConf] = React.useState({ mainCourse: true, vacationCourse: false, tastingCourse: false, annual: false });
    const [cart, setCart] = React.useState({ total: null });
    const [open, setOpen] = React.useState(false);
    const [stripeClientSecret, setStripeClientSecret] = React.useState(null);
    const [needsPaymentInfo, setNeedsPaymentInfo] = React.useState(true);
    const [subscriptions, setSubscriptions] = React.useState({ user_setup_intent_id: 1, active_customer: false, active_subscriptions: [], inactive_subscriptions: [] });

    const [foundSubscription, setFoundSubscription] = React.useState({
        about: null,
        amount: null,
        code: "",
        id: null,
        is_annual: true,
        is_lifetime: false,
        name: null
    })

    const [modalType, setModalType] = React.useState('pay-modal'); // pay-modal | thanks-modal
    const [modalData, setModalData] = React.useState(null);

    const onOpenCancelModal = () => {
        setModalType('cancel-modal');
        setOpen(true);
    }
    const onOpenModifyModal = () => {
        setModalType('modify-modal');
        setOpen(true);
    }
    const onOpenUpdateStripeIntentModal = () => {
        setModalType('update-stripe-intent-modal');
        setOpen(true);
    }
    const onOpenPayModal = () => {
        setModalType('pay-modal');
        setOpen(true);
    }
    const onCloseModal = () => {
        setOpen(false);
        setModalData(null);
    }
    const modalCancelStripeSubscription = (id) => {
        setModalType('cancel-stripe-modal');
        setModalData(id);
        setOpen(true);
    }

    const updateConf = (dat) => {
        // console.log(dat);
        // console.log(conf);
        setConf((prev) => {
            return {
                ...prev,
                ...dat
            }
        })
    }

    //console.log(subscriptions);

    const cancelStripeSubscription = (subscription_id) => {
        SubscriptionAPI.cancelStripeSubscription(subscription_id)
            .then(res => {
                Helper.showToast('info', 3000, "You successfully canceled your subscription. We're sorry to see you go!");
            })
            .catch(err => {
                Helper.readError(err);
            })
    }

    const cancelSubscription = () => {
        dispatch({
            type: "CANCEL_SUBSCRIPTION",
        });
    }

    const useStyles = makeStyles(theme => ({
        iconButtonLabel: {
            display: 'flex',
            flexDirection: 'column',
        },
    }));

    const classes = useStyles();

    React.useEffect(() => {
        console.log(conf);
        if (conf) {
            let code = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
            if (conf.mainCourse) code[0] = '1';
            if (conf.vacationCourse) code[1] = '1';
            if (conf.tastingCourse) code[2] = '1';
            SubscriptionAPI.getPrice({ code: code.join(''), is_annual: conf.annual })
                .then(res => {
                    if (res.data) setFoundSubscription(res.data);
                }).catch(err => {
                    setFoundSubscription({
                        about: null,
                        amount: null,
                        code: "",
                        id: null,
                        is_annual: true,
                        is_lifetime: false,
                        name: null
                    })
                    console.log(err);
                })
        }

    }, [conf])

    React.useEffect(() => {

        //console.log(location);

        SubscriptionAPI.getIntent()
            .then(res => {
                setStripeClientSecret(res.data.stripe_client_secret);
                setNeedsPaymentInfo(res.data.needs_payment_info);
            })
            .catch(err => {
                Helper.readError(err);
            })

        UserAPI.getSubscriptions()
            .then(res => {
                if (res.data) {
                    setSubscriptions(res.data);
                }
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                    history.push(`/login-sign-up?redirectTo=${window.location.pathname}`)
                }
            });

    }, [location.pathname]);

    console.log(subscriptions);

    React.useEffect(() => {

        if (location.hash === '#subscribe') {
            // subscribeRef.current.scrollIntoView();
        }

    }, [location.hash])

    return (
        <>
            <div>
                <Header isLogoCenter='true' conversationListButton={false} pwaInstallButton={false} />
                {/*  */}
                {!subscriptions.user_setup_intent_id || needsPaymentInfo ?
                    <div className="login-container d-flex flex-column justify-content-center align-items-center">
                        <Register startStep={2} />
                    </div>
                    :
                    <div className="account">
                        <div className='account__title justify-content-between justify-content-md-start align-items-center pb-3'>
                            <h1>My Account</h1>
                            <div className="mx-3"><button className="button__layout--ghost button__layout--link h6" onClick={() => dispatch({ type: "LOGOUT_REQUEST", payload: {} })} >(Logout)</button></div>
                        </div>
                        <div className='account__row'>
                            <div className='w-100'>
                                <div className="text__inter text__inter--thin">Account Information</div>
                                <hr className='account__separator' />
                            </div>
                        </div>
                            <div style={{ minWidth: '220px' }} className="my-2 p-0">
                                <div className="pt-2 pb-4">
                                    <div className='pb-2'>Your Details</div>
                                    <div className='accountInfo'>
                                        <div>{state.auth.user.email} </div>
                                        <div>User since {subscriptions.user_since} </div>
                                        <div>Subscribed: {subscriptions.active_customer ? 'Yes' : 'No'} </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ minWidth: '220px' }} className="my-2 p-0">
                                <div className="pt-2 pb-4">
                                    <div className='pb-2'>Tools</div>
                                    <div className='accountInfo'>
                                        <div>Reset password</div>
                                        <div>Update email</div>
                                        <div>Request refund</div>
                                    </div>
                                </div>
                            </div>
                        <div className='account__row mt-2'>
                            <div className='w-100'>
                                <div className="text__inter text__inter--thin">Payment Method</div>
                                <hr className='account__separator' />
                            </div>
                        </div>
                        <div className="account__row mb-4">
                            <div style={{ minWidth: '220px' }} className="my-2 p-0">
                                <div className="pt-2 pb-4">
                                    <div className='pb-2'>Status</div>
                                    <div className='accountInfo'>
                                        <div>{needsPaymentInfo ? 'Failing' : 'Confirmed'}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ minWidth: '220px' }} className="my-2 p-0">
                                <div className="pt-2 pb-4">
                                    <div className='pb-2'>Tools</div>
                                    <div className='accountInfo'>
                                        <div><button onClick={onOpenUpdateStripeIntentModal} className='p-0 button__layout--ghost button__layout--link'>Update Payment Method</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='account__row mt-4'>
                            <div className='w-100'>
                                <div className="text__inter text__inter--thin">Subscriptions</div>
                                <hr className='account__separator' />
                            </div>
                        </div>
                        <div className='account__row'>
                            <div className="col-12 my-2 p-0">
                                <div className="pt-2 pb-4">
                                    <table className='w-100 account__table-subscription'>
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}><small>ACTIVE SUBSCRIPTIONS</small></td>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <th>Start Date</th>
                                                <th>Date Last Renewed</th>
                                                <th>Actions</th>
                                            </tr>
                                            {subscriptions.active_subscriptions.length > 0 ? subscriptions.active_subscriptions.map((s, i) =>
                                                <tr>
                                                    <td>{s.name}</td>
                                                    <td>{s.date_purchased}</td>
                                                    <td>{s.date_last_renewed}</td>
                                                    <td>Cancel | Modify</td>
                                                </tr>


                                            ) : <tr>
                                                <td colSpan={4}>No active subscriptions</td>
                                            </tr>
                                            }
                                            <tr>
                                                <td colSpan={4} className='pt-4'><small>INACTIVE SUBSCRIPTIONS</small></td>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <th>Start Date</th>
                                                <th>Date Last Renewed</th>
                                                <th>Actions</th>
                                            </tr>
                                            {subscriptions.inactive_subscriptions.length > 0 ? subscriptions.inactive_subscriptions.map((s, i) =>
                                                <tr>
                                                    <td>{s.name}</td>
                                                    <td>{s.date_purchased}</td>
                                                    <td>{s.date_last_renewed}</td>
                                                    <td>{!s.canceled && <button onClick={() => modalCancelStripeSubscription(s.id)} className="p-0 button__layout--ghost button__layout--link">Cancel</button>}{(s.date_purchased > moment().subtract(30, 'days')) && ' | Request Refund'} </td>
                                                </tr>


                                            ) : <tr>
                                                <td colSpan={4}>No inactive subscriptions</td>
                                            </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Footer />
            </div>
            <Modal
                open={open}
                onClose={onCloseModal}
                center
                classNames={{ root: 'pay-modal' }}
            >
                {modalType === 'pay-modal' ?
                    <PaymentForm
                        buttonText={Helper.formatPrice(foundSubscription.amount) + " Secure Checkout"}
                        subscriptionId={foundSubscription.id}
                        header={foundSubscription.name}
                        anticipatedPrice={foundSubscription.amount}
                        footer={['Recurring charges can be canceled anytime with a single click.',
                            'Existing subscriptions will be canceled, and you will be refunded the remainer of the your subscription period.']} />
                    :
                    modalType === 'cancel-modal' ?
                        <div  className=''>
                            <h4>Uffa!</h4>
                            <div className="text__inter para m-2">You'd like to cancel your subscription? Is there something we could improve?</div>
                            <div className='text__inter para m-2'>Click the button below to immediately cancel all future charges!</div>
                            <div className="text__inter para m-2">If you have complaints or suggestions please write andrew@italianaturally.com. We would truly appreciate the chance
                                to improve the course!</div>
                            <button onClick={cancelSubscription}  className=' m-2  d-block fw-semibold text__inter text__inter--18 active'>Confirm Cancel</button>
                            <button onClick={() => setModalType('modify-modal')}  className=' m-2  d-block fw-semibold text__inter text__inter--18 active'>Modify Courses or Payment Schedule</button>
                        </div>
                        :
                        modalType === 'modify-modal' ?
                            <div  className=''>
                                <h4>Con Piacere!</h4>
                                <div className="text__inter para m-2">You'd like to add or remove courses or change your payment frequency?</div>
                                <div className="text__inter para m-2">Please write an email to andrew@italianaturally.com with your request and we'll turn it around ASAP!</div>
                                <div className='text__inter para m-2'>Feel free to take the opportunity to make requests and give feedback at the same time!</div>
                            </div>
                            :
                            modalType === 'update-stripe-intent-modal' ?
                                <div className='pt-2 pl-2 pr-2 pb-2'>
                                    <StripePaymentForm stripeClientSecret={stripeClientSecret} buttonText='Update Payment Method' />
                                </div>
                                :
                                modalType === 'cancel-stripe-modal' &&
                                <div  className=''>
                                    <h4>Uffa!</h4>
                                    <div className="text__inter para m-2">You'd like to cancel your subscription? Is there something we could improve?</div>
                                    <div className='text__inter para m-2'>Click the button below to immediately cancel all future charges!</div>
                                    <div className="text__inter para m-2">If you have complaints or suggestions please write andrew@italianaturally.com. We would truly appreciate the chance
                                        to improve the course!</div>
                                    <button onClick={() => cancelStripeSubscription(modalData)}  className=' m-2  d-block fw-semibold text__inter text__inter--18 active'>Confirm Cancel</button>
                                    {/*<button onClick={() => setModalType('modify-modal')} style={{width:'100%'}}className=' m-2  d-block fw-semibold text__inter text__inter--18 active'>Modify Courses or Payment Schedule</button>*/}
                                </div>
                }
            </Modal>
        </>
    );
}

export default Account;
