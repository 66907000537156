import React, { useState } from "react";
import { AppContext } from "../../App";
import './our_story.scss'

import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import NavFooter from '../../components/layout/NavFooter'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import meImage from './me.webp';
import Stack from 'react-bootstrap/Stack';

import useModal from '../../components/Modal/useModal';

const OurStory = () => {

    const [validated, setValidated] = useState(false);

    const { openModal, closeModal } = useModal();

    const { dispatch, state } = React.useContext(AppContext);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidated(true);
    };
    
    return (
        <>
        <NavFooter loginButton={true} conversationListButton={false} pwaInstallButton={true} />
        {/*  */}
        <Header 
            conversationListButton={ state.auth.token } 
            aboutCourseButton = { !state.auth.token } 
            contactButton={true} pwaInstallButton={false} 
            faqButton={true} 
            pricingButton={!state.auth.token} 
            aboutUsButton={true}  />
        <div className="container">
            
            <Row className="p-4 d-flex flex-row">
                <h1 className="text-center text__lora">Our Story</h1>
            </Row>
            <Row className="mb-5 p-5 justify-content-around bg-blue-light-gray rounded-5">
                <Col xs={12} md={8} >
                    <p className="fs-6 fs-md-4">Hi! I'm Andrew Bogaard, the founder of Italian Naturally, currently living in the beautiful city of Turin, Italy. 
                    I studied Spanish for 8 years but never got very far when it came to conversing with native speakers. I figured I was bad at language. 
                    I couldn't understand their fast pace of speaking, and when it was my time to speak, I often had trouble being understood. Luckily, when I 
                    moved to Switzerland, I decided to try a different approach - I studied French with a fantastic course called French Together. The aim was 
                    to learn the language naturally - through conversation.</p>
                    
                    <p className="fs-6 fs-md-4">Listening to short conversations, and imitating them as I spoke, I made really quick gains that got me actually 
                    communicating. And fast! So when I moved to Turin, I thought, why not do the same for Italian?</p>

                    <p className="fs-6 fs-md-4">Although this little section is about me, this course is the product of dozens and dozens of (mostly Italian) contributors - 
                    including my wife, Paola, past professors, and friends and family based here in Italy. </p>
                    
                    <Stack direction="horizontal" gap={3}>
                      <div className="fs-6 fs-md-4">We've put together a great selection of short conversations for you to be a part of, whether it's to prep for your upcoming trip,
                    work on your pronunciation, or learn the language! Please give it a whirl and let us know what you think!</div>
                      <div className="p-2"> <Button variant="primary text-white text-nowrap" onClick={() => openModal('register')}>Try It For Free!</Button></div>
                    </Stack>

                </Col>
                <Col xs={12} md={4} className="align-items-start justify-content-center">
                    <img src={meImage} alt="Andrew" id="photo_andrew"/>
                </Col>
            </Row>

        </div>

        <Footer />
        </>
    );
}

export default OurStory;