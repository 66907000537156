import { history } from "../../App";
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../assets/icon/account.svg'

const AccountButton = (props) => {
	const {height='30px', width='auto'} = props;
	return (
		<IconButton disableRipple={true} size="large" onClick={() => history.push('/account')}>
		    <Icon style={{height:height, width:width}} />
		</IconButton>
		)
	
}

export default AccountButton