import React from "react";
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import HTMLRenderer from 'react-html-renderer'
import PlayWord from '../PlayWord'
import PlayWordButton from '../PlayWordButton'
import {ReactComponent as ExpandMoreIcon} from '../../assets/icon/expand_more.svg'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  backgroundColor: "transparent !important",
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  padding:'0px',
  minHeight:'30px',
  maxheight:'30px',
  flexDirection: 'row',
  backgroundColor: "transparent !important",
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    //marginLeft: theme.spacing(1),
    margin:'0px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  padding:'0px',
  backgroundColor: "transparent !important",
  border: 'none',
}));

const GrammarAccordion = (props) => {
  const { innerHTML, title } = props;

  return (
  <div className="grammar2" >
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="fw-semibold text__inter text__inter--18 accordionTitle">{title}</div>
        <div className="text__inter text__inter--small fw-semibold accordionTitleDetails"></div>
      </AccordionSummary>
      <AccordionDetails>
        <HTMLRenderer html={innerHTML} components={{playword:PlayWord, playwordbutton:PlayWordButton}} />
      </AccordionDetails>
    </Accordion>
  </div>
  )
};

export default GrammarAccordion