import React, { useEffect, useRef } from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import Header from "../components/layout/Header";
import Helper from "../utils/Helper";
import { AppContext } from "../App";
import Const from '../utils/Constants';
import AuthAPI from "../utils/AuthAPI";
import GLogin from '../components/GoogleOAUTH/GLogin';

const clientID = "71680960706-3vre4iushtpejgv11c256e7st6irlf7f.apps.googleusercontent.com";

//const iphone = require('../assets/img/iphone.svg');

const RequestConfirmationEmail = (props) => {
	const { dispatch, state } = React.useContext(AppContext);
	const history = useHistory();
	const search = useLocation().search;
	const [inputParams, setInputParams] = React.useState({
		email: '',
	});
	const location = useLocation();

	useEffect(() => {
		if (state.auth.auth && localStorage.getItem(Const.AUTH_TOKEN)) {
			// if (state.auth.auth.token) { // update this with a test to see if token is active
			// 	console.log(state);
			// 	console.log('not authed');
			// 	// const protocol = window.location.protocol;
			// 	// window.location = `${protocol}//italiannaturally.${Helper.getHost()}/auth/login`;
			// } else {
			// 	const params = new URLSearchParams(search)
			// 	if (params.get("redirectTo")) {
			// 		history.push("/conversations");
			// 	} else {
			// 		history.push("/conversations");
			// 	}
			// }
		}
	}, [state.auth.auth])

    const handleChangeInput = (e) => {
		setInputParams({...inputParams, [e.target.name]: e.target.value});
	}

	const handleKeyPress = (e, name) => {
		if(e.key === "Enter") {
			if(name === 'email') {
				sendConfirmation();
			}
		}
	}

    const sendConfirmation = () => {
		Helper.showSpinner();
		AuthAPI.sendConfirmation({'email':inputParams.email})
			.then(res => {
				Helper.hideSpinner();
				if(res.meta.code === 200) {
					Helper.showToast('success', 3000, 'We sent another confirmation email to the email provided.', '');
				}
			})
			.catch(err => {
				Helper.hideSpinner();
				if(!err.response) {
					Helper.showToast('error', 5000, `Caspita! Something went wrong, if it keeps happening please write andrew@italianaturally.com.`, '');
					return;
				}
				if (err.response.status === 400 && err.response.data) {
					const error = err.response.data;
					if (error.response.errors) {
						const errors = error.response.errors;
						for (const property in errors) {
							Helper.showToast('warning', 5000, `${errors[property]}`, error.meta.code);
						}
					}
					if (error.response.error) {
						const error_msg = error.response.error;
						Helper.showToast('warning', 5000, error_msg, error.meta.code);
					}
					return;
				}
				Helper.showToast('error', 5000, err.response.statusText, err.response.status);
			})
	}

	return (
		<div className="container-fluid">
			<Header />
			<div className="layout__container--single">
				{/* <div className="ml-auto p-4 start-account">
					<span className="">Don't have an account? </span><span className="register" onClick={() => history.push("/auth/register")}>Get started</span>
				</div> */}
				<div className="login-form sticky-top layout__offset-top-80">
					<h1 className="">Didn't recieve confirmation email?</h1>
					<div className="form1-entry-input email my-4">
							<input type="email" 
								className={inputParams.email.length > 0 ? "form-control valid" : "form-control" }
								name="email" 
								autoFocus={true}
								placeholder="Email Address"
								value={inputParams.email} 
								onChange={(e) => handleChangeInput(e)} 
								onKeyPress={(e) => handleKeyPress(e, 'email')}
								/>
							{/*{inputParams.email && !Helper.validMail(inputParams.email) && 
								<div className="err-msg">The email you entered is incorrect. Try to again.</div>
							}*/}
					</div>

					<div className="layout__form-submit justify-content-start align-items-center">
						<div className="loginSelf p-2">
							<button type="button" 
							className="background__button--access text__color--white"
							disabled={!inputParams.email || !Helper.validMail(inputParams.email)} 
							onClick={sendConfirmation}
							>Send Confirmation Email</button>
						</div>							
					</div>
					<div className='d-flex flex-column align-items-start mt-3'>
						<button className="under-links" onClick={() => 'redirecttohashedloginpage'}>Don't have an account? Register Here</button>
						<button className="under-links" onClick={() => 'redirecttohashedloginpage'}>Already have an account? Login Here</button>
						<button className="under-links" onClick={() => 'redirecttohashedloginpage'}>Forgot password?</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RequestConfirmationEmail;