import React, { useEffect } from "react";
import { useParams, NavLink, useHistory } from "react-router-dom";
import AuthAPI from "../utils/AuthAPI";
import Helper from '../utils/Helper';
import { AppContext } from "../App"
import { get } from "lodash";
import Header from "../components/layout/Header";
import {ReactComponent as VaBene} from '../assets/icon/vabene.svg';
import {ReactComponent as WhatThe} from '../assets/icon/what-the.svg';
import SubscriptionAPI from "../utils/SubscriptionAPI";
import { history } from "../App"
import Loader from "react-spinners/SyncLoader";
import './_account.scss';



const ConfigureAccount = () => {
	const { dispatch, state } = React.useContext(AppContext);
	const { setup_intent, setup_intent_client_secret } = useParams()
	const [stage, setStage]=  React.useState('UNKNOWN')
	const [message, setMessage] = React.useState('')
	const [counter, setCounter] = React.useState(5);

	useEffect(() => {
		Helper.showSpinner();
		submit();
	}, [])

	// React.useEffect(() => {

	// 	if (counter==0) {
	// 		history.push('/conversations');
	// 	}

	// 	counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	// }, [counter]);

	const submit = () => {

		SubscriptionAPI.checkIntentAndSubscribe()
        // if success, then hit the api and if we are on register flow, set them up with 7 day account and update their setup intent status
          .then(res => {
              // setStage('SUCCESS');
              // console.log(res.data);
              history.push('/conversations');
          }).catch(err => {
              setStage('FAILURE');
              console.dir(err);
              Helper.readError(err);
          })

	}

	return (
		<div className="container-fluid">
			<Header />
			<div className="layout__container--single">
				{/* <div className="ml-auto p-4 start-account">
					<span className="">Don't have an account? </span><span className="register" onClick={() => history.push("/auth/register")}>Get started</span>
				</div> */}
				<div className="sticky-top layout__offset-top-80 align-items-center d-flex flex-column">
					{stage=='UNKNOWN' && <>
						<Loader style={{margin:'25px'}} size={50} color='#00AB67'/>
						<h4 className="text-title mt-4">Configuring your account</h4>
					</>}
					{stage=='SUCCESS' && <>
						<VaBene style={{width:'200px', height:'auto'}}/>
						<h4 className="text-title mt-4">Configuring your account</h4>
						<h6 className="text-subline">Good luck with your first conversations! Let us know how it goes: andrew@italianaturally.com!</h6>
						<p>We will take you directly to your conversations in {counter} seconds.</p>
					</>}
					{stage=='FAILURE' && <>
						<WhatThe style={{width:'200px', height:'auto'}}/>
						<h4 className="text-title mt-4 ">Caspita!</h4>
						<h6 className="text-subline ">{message}</h6>
						<p>Redirecting to login in {counter} seconds.</p>
						{/*<NavLink className="button__layout--big-link pt-4 greenPrimary" to="/login-sign-up">Log In</NavLink>*/}
					</>}
				</div>		
			</div>
		</div>
	);
}

export default ConfigureAccount;