import React, { forwardRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import useModal from '../../components/Modal/useModal';

const CallActionBanner = forwardRef((props, ref) => {

    const { openModal, closeModal } = useModal();

    return (<>
        <Row className="my-3 justify-content-center">

            <Col md={12} lg={8} className="p-5 rounded bg-info d-flex flex-row justify-content-around align-items-center">
                <div className='fw-semibold text-white'>Ready to start speaking?</div>
                <div>
                    <Button variant="light" onClick={() => openModal('register')}>Try it for free!</Button>
                </div>
            </Col>

        </Row>
    </>
    )
})

export default CallActionBanner;

