import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AnalysisModal = () => {
	return (<>
        <Row className="text-center bg-green-light-gray rounded-top-5">
            <h3 className="mt-5 fw-light">Understand How We Created the Course</h3>
        </Row>
        <Row className="mb-5 p-5 justify-content-around bg-green-light-gray rounded-bottom-5">
            <Col xs={12} md={5} >
                <p className="fs-6 fs-md-4">When you study a language in school, or from a textbook, you'll march through basic seeming
                words. Like "to swim". Pretty basic verb, right? Probably should know that one, right?</p>

                <p className="fs-6 fs-md-4">Not really! We ran an analysis of more than 1.7M words used in Italian subtitles, and, as far as verbs
                go, <span className="fw-bold">there are 392 other verbs used more frequently than nuotare (to swim)</span>.</p>

                <p className="fs-6 fs-md-4">We have some good news, though! Only 74 verbs accounted for 80% of the verbs used in our
                analysis (read more about the analysis here.</p>

                <p className="fs-6 fs-md-4">Put another way, <span className="fw-bold">if you learn the right 74 verbs, you'll understand spoken verbs about 80% of the
                time!</span> We built the course with this in mind:</p>

                <p className="fs-6 fs-md-4">We didn't stop at single words, we also looked at two, three, and four word combinations.</p>

                <p className="fs-6 fs-md-4">Immersion is great because you learn the words people actually use. We've tried to create the next 
                best thing - dialogues driven by an analysis of these words and phrases.</p>
            </Col>
            <Col xs={12} md={5} className="my-1 align-items-start justify-content-center d-flex flex-row">
                <div className='my-2 d-flex flex-column'>
                    <div className="analysis_point analysis__img-selective">
                        <h4>Learn the words you need most</h4>
                        <p className='fs-6 fs-md-4 '>The first 20 conversations of the Main Course include over 57% of the words used in all of the subtitles we analyzed.</p>
                    </div>
                    
                    <div className="analysis_point analysis__img-immersion">
                        <h4>Emulate focused immersion</h4>
                        <p className=' fs-6 fs-md-4'>We also looked for repeated two, three, and four word phrases. This way you learn expressions and tenses used most frequently,
                        without memorizing tables.</p>
                    </div>
                    <div className="analysis_point analysis__img-puzzle">
                        <h4>We broke it down by part of speech</h4>
                        <p className='fs-6 fs-md-4'>You may be surprised by what we discovered:</p>
                        <div>
                            <div className="analysis_point_li">74 verbs make up 80% of those spoken</div>
                            <div className="analysis_point_li">92 adverbs make up 98%</div>
                            <div className="analysis_point_li">152 nouns make up 50%</div>
                            <div className="analysis_point_li">114 adjectives make up 80%</div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </>
	)
}

export default AnalysisModal;