import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {ReactComponent as Tips} from '../../assets/icon/tips2.svg'
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

// const getStageLabel = (stage, stages) => {

//     if (progress==null) progress=0;

//     let obj = stages.filter(e => e.progress==progress);
//     return obj[0].name
// }

// const getStages = (stages) => {
//   return stages.map(d => d.progress);
// }

// const getNextStage = (stage, stages) => {

//     if (stage==null) stage=0;

//     if (stage==100) return 0;

//     const allStages = getStages(stages)
    
//     return allStages.filter(v => +v > stage)[0];
// }

export default function ProgressStepper(props) {
  const {stageIndex=null, updateCallback=null, label=null, description=null, maxStageIndex=null} = props

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, background:'none' }}>
      <MobileStepper
        variant="text"
        steps={maxStageIndex}
        position="static"
        activeStep={stageIndex}
        sx={{
          pb:0,
          pt:0,
          pr:0,
          pl:0,
          background:'none'
        }}
        nextButton={
          <Button
            size="small"
            onClick={() => updateCallback(stageIndex+1)}
            disabled={stageIndex === maxStageIndex - 1}
          >
            Next
              <svg style={{stroke:'#75b6fe'}} className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowForwardIosIcon"><path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path></svg>
          </Button>
        }
        backButton={
          <Button size="small" onClick={() => updateCallback(stageIndex-1)} disabled={stageIndex === 0}>
              <svg style={{stroke:'#75b6fe'}} className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowBackIosNewIcon"><path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z"></path></svg>
            Back
          </Button>
        }
      />
      <Box sx={{ width:300, maxWidth: 400, flexGrow: 1 }}>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 20,
            pl: 1,
            mt:-0.5,
            fontSize:11,
            bgcolor: 'none',
          }}
        >
          You're on step {label}
        </Typography>
      </Box>
    </Box>
  );
}