import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import OffCanvas from '../components/OffCanvas';
import { useParams, useLocation, Link} from 'react-router-dom';
import { history } from "../App"
// import moment from 'moment';
import Helper from '../utils/Helper';
import HomeAPI from '../utils/HomeAPI';
import ConversationAPI from '../utils/ConversationAPI';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Switch from "react-switch";
import Box from '@mui/material/Box';
import toast from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';
import GrammarAccordion from '../components/GrammarAccordion';
import PlayWord from '../components/PlayWord'
import PlayWordButton from '../components/PlayWordButton'
import HTMLRenderer from 'react-html-renderer'
import CapeeshScore from '../components/CapeeshScore'
import BasicModal from '../components/BasicModal'
import ConversationPlayer from './ConversationPlayer'
import IsMobile from '../utils/IsMobile';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {ReactComponent as Chalkboard} from '../assets/icon/chalkboard.svg'

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

function Conversation() {

    const location = useLocation();
    let { id, chapter, lesson, variant=1 } = useParams();

    const [conversation, setConversation] = React.useState();
    const [conversationCapeeshScores, setConversationCapeeshScores] = React.useState(null);
    const [capeeshDisabled, setCapeeshDisabled] = React.useState(false);
    const [playbackDisabled, setPlaybackDisabled] = React.useState(true);
    const [isMic, setIsMic] = React.useState(true);

    const apiBase = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : '/api';

    const { windowWidth, isMobileScreen } = IsMobile();

    console.log(isMobileScreen());

    const calcAvCapeeshScore = (dat) => {

        if (!dat) return "?";

        if (dat.length<conversation.lines.filter(a => a.position!=='s').length) return "?";

        const scores = dat.map(d => d.capeesh_score);
        return Math.round(scores.reduce((p,c) => p+c, 0) / scores.length)

    }

    const getSpeakerEmoji = (sex) => {
        if (sex=='f') {
            return '👩';
        } else {
            return '👨';
        }
    }

    const getCapeeshScores = () => {
        if (id) {
            ConversationAPI.getConversationCapeeshScores(id)
                .then(res => {
                    if (res.data) {
                        setConversationCapeeshScores(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            ConversationAPI.getConversationCapeeshScores(chapter, lesson)
                .then(res => {
                    if (res.data) {
                        setConversationCapeeshScores(res.data);
                    }
                })
                .catch(err => {
                   console.log(err);
                });
        }
    }

    React.useEffect(() => {

        if (conversation) getCapeeshScores();

    }, [conversation]);

    React.useEffect(() => {

        const toastId = toast.loading("Loading some real accents...")

        setPlaybackDisabled(!Helper.browserCompatibilityRecorder()); // keep playback disabled and therefore dont try to run recorder
                        
        window.scrollTo({top:0, behavior:'smooth'});

        if (id) {
            
            ConversationAPI.getMiniConversationDetails(id)
                .then(res => {
                    if (res.data) {
                        setConversation(res.data);
                    };
                    toast.success('Fatto!', {
                        id:toastId,
                    });
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        toast.error(err.response.data.response.error, {
                            id:toastId,
                        });
                    } else {
                        toast.error('Something went wrong. If it keeps happening, please write andrew@italianaturally.com', {
                            id:toastId,
                        });
                    }
                    if (err.response.status==401) {
                        history.push(`/login-sign-up?redirectTo=${window.location.pathname}`)
                    }
                });

            ConversationAPI.getConversationCapeeshScores(id)
                .then(res => {
                    if (res.data) {
                        setConversationCapeeshScores(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            ConversationAPI.getConversationDetails(chapter, lesson, variant)
                .then(res => {
                    if (res.data) {
                        setConversation(res.data);
                        toast('Ti presento \n'+getSpeakerEmoji(res.data.left_actor_sex)+res.data.left_actor_name+' ' + res.data.left_actor_location + '📍 \n e '+getSpeakerEmoji(res.data.right_actor_sex)+res.data.right_actor_name+' '+res.data.right_actor_location+'📍', {
                            id:toastId,
                            icon:'👋',
                        });
                    }
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        toast.error(err.response.data.response.error, {
                            id:toastId,
                        });
                    } else {
                        toast.error('Something went wrong. If it keeps happening, please write andrew@italianaturally.com', {
                            id:toastId,
                        });
                    }
                    if (err.response.status==401) {
                        history.push(`/login-sign-up?redirectTo=${window.location.pathname}`)
                    }
                });

            ConversationAPI.getConversationCapeeshScores(chapter, lesson)
                .then(res => {
                    if (res.data) {
                        setConversationCapeeshScores(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    }, [location.pathname]);

    return (
        // <Header loginButton={ !state.auth.token } conversationListButton={ false } aboutCourseButton = {true} contactButton = {true} pwaInstallButton={false} faqButton={true} pricingButton={true} aboutUsButton={true}  />
        <div className="container">
            <Header 
                conversationListButton={true} 
                practiceButton={true} 
                showSettings={true}
                contactButton={true}
                pwaInstallButton={true}
                faqButton={true}
                aboutUsButton={true}
                audio_disabled={ !Helper.browserCompatibilityAudio() }
                mic_disabled={!isMic}
                playback_disabled={playbackDisabled}
                capeesh_disabled={capeeshDisabled}
                capeeshDisableCallback={setCapeeshDisabled}
                showRecorderSettings={true}
            />
            
            {conversation && conversation.lecture==lesson ?
                <Row className="m-1 m-md-4 flex-row align-items-end justify-content-around flex-wrap-reverse"> {/*className="conversation__row flex-row-reverse conversation__lesson-container">*/}                
                    <Col xs={12} md={7} lg={6} className="p-0">
                        <ConversationPlayer
                        conversation={conversation} 
                        capeeshDisabled={capeeshDisabled} 
                        setCapeeshDisabled={setCapeeshDisabled}
                        playbackDisabled={playbackDisabled}
                        isMic={isMic} />
                    </Col>
                    <Col md={5} lg={5} className="sticky-md-top sticky-offset d-xs-none d-md-flex flex-column p-0"> {/*className='wrapper_about_conversation col-sm-12 col-lg-5 d-flex flex-column'>*/}
                        <div className="d-flex flex-column w-100">
                            <div className="d-flex flex-row w-100 align-items-center">
                                <h1 className="text__lora d-block text-nowrap text-truncate">{conversation.title}</h1>
                                
                                {isMobileScreen() ? 
                                    <OffCanvas 
                                        buttonText="Lessons"
                                        className="ms-auto"
                                        title={conversation.title}
                                        copy={<>
                                            <div>
                                                <HTMLRenderer html={conversation.about} components={{playword:PlayWord, playwordbutton:PlayWordButton}} />
                                            </div>
                                            <div>
                                                <GrammarAccordion title="Lesson" innerHTML ={conversation.lesson} />
                                            </div>
                                        </>}
                                    />
                                    :
                                    <div className='ml-2'>
                                        <CapeeshScore use="conversationAbout" score={calcAvCapeeshScore(conversationCapeeshScores)} />
                                    </div>
                                }
{/*                                <div className='ml-2'>
                                    <ProgressRing
                                        radius={ 13 }
                                        stroke={ 2.5 }
                                        progress={ stage }
                                    />
                                </div>*/}
                            </div>
                            <div className="mt-3">
                                {/*<small>Conversation navigator</small>*/}
                                <Pagination 
                                count={conversation.n_lectures} 
                                page={conversation.lecture} 
                                // siblingCount={1} 
                                // boundaryCount={1}
                                onChange={(ev,ind) => history.push('/conversations/course/'+ conversation.chapter.toString()+'/'+(ind).toString())} />
                            </div>
                            {!isMobileScreen() ? <>
                                <div className="mt-2">
                                    <HTMLRenderer html={conversation.about} components={{playword:PlayWord, playwordbutton:PlayWordButton}} />
                                </div>
                                <div>
                                    <GrammarAccordion title="Lesson" innerHTML ={conversation.lesson} />
                                </div>
                            </> : <>

                            </>}
                        </div>
                        
                    </Col>
                </Row > : 

                // HERE IS FILLER

                <Row className="m-1 m-md-4 flex-row justify-content-around">
                    <Col xs={12} md={8} lg={6}>
                        <div className='location_tree'>
                            <div><Skeleton variant="text" width={350} height={35} /></div>
                        </div>
                        <div className="controls-container sticky-top layout__offset-top-80">
                            <div className='control_selector d-inline-flex align-items-end'>
                                <div className={`tab active'}`}></div>
                                <div className={`tab`}></div>
                                <div className="ml-auto pr-1">
                                    <button className="btn-play-conversation"></button>
                                </div>
                            </div>
                            <div className='controls'>
                            </div>
                        </div>
                        <div id='conversation-page' className='conversation-page d-flex flex-column'>
                            <Stack width="100%" spacing={0.5}>
                                <Box sx={{width:"30%"}}><Skeleton variant="text" height={50} /></Box>
                                <Box sx={{width:"30%", alignSelf:"flex-end"}}><Skeleton variant="text" height={50} /></Box>
                                <Box sx={{width:"30%"}}><Skeleton variant="text" height={50} /></Box>
                                <Box sx={{width:"30%", alignSelf:"flex-end"}}><Skeleton variant="text" height={50} /></Box>
                                <Box sx={{width:"30%"}}><Skeleton variant="text" height={50} /></Box>
                                <Box sx={{width:"30%", alignSelf:"flex-end"}}><Skeleton variant="text" height={50} /></Box>
                                <Box sx={{width:"30%"}}><Skeleton variant="text" height={50} /></Box>
                                <Box sx={{width:"30%", alignSelf:"flex-end"}}><Skeleton variant="text" height={50} /></Box>
                            </Stack>
                        </div>
                    </Col>
                    <Col md={4} lg={3} className="d-xs-none d-md-flex flex-column">
                        <div className="about_conversation">
                            <div className="flex-row align-items-center d-none d-md-flex">
                                <div>
                                    <Skeleton variant="text" width={100} height={55} />
                                </div>
                                <div className='ml-2'>
                                    <Skeleton variant="circular" ml="auto" width={20} height={20} />
                                </div>
                                <div className='ml-2'>
                                    <Skeleton variant="circular" ml="auto" width={20} height={20} />
                                </div>
                            </div>
                            <div className=" d-none d-md-flex">
                                <Stack width="100%" spacing={1} padding={2}>
                                    <Skeleton variant="text" width="100%" height={15} />
                                    <Skeleton variant="text" width="100%" height={15} />
                                    <Skeleton variant="text" width="100%" height={15} />
                                </Stack>
                            </div>
                        </div>
                        <div className="grammar2 d-none d-md-flex">
                            <Stack width="100%" spacing={1} padding={2}>
                                <Skeleton variant="text" width="100%" pb={3} height={45} />
                                <Skeleton variant="text" width="100%" height={15} />
                                <Skeleton variant="text" width="100%" height={15} />
                                <Skeleton variant="text" width="100%" height={15} />
                            </Stack>
                        </div>
                    </Col>
                </Row>

                //END FILLER 
                
        }        
        </div>
    );
}

export default Conversation;