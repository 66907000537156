import { GET, POST, DELETE, PUT } from './Http';

const apiBase = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : '/api';

const cancelStripeSubscription = async (id) => {
    const url = `${apiBase}/subscription/${id}/cancel`;
    return PUT(url);
}

const getIntent = async () => {
    const url = `${apiBase}/stripe_subscription/get_intent`;
    return GET(url);
}

const checkIntentAndSubscribe = async () => {
    const url = `${apiBase}/stripe_subscription/confirm_intent_and_subscribe`;
    return GET(url);
}

const createStripeIntent = async (payload) => {
    const url = `${apiBase}/stripe_subscription/create_intent_token`;
    return POST(url, payload);
}

const getPrice = async (payload) => {
    const url = `${apiBase}/subscription/get_price`;
    return POST(url, payload);
}

const subscribe = async (payload) => {
    const url = `${apiBase}/subscription/subscribe`;
    return POST(url, payload);
}

const cancelSubscription = async () => {
    const url = `${apiBase}/subscription/cancel`;
    return DELETE(url);
}

const subscribeFamily = async (payload) => {
    const url = `${apiBase}/stripe_subscription/subscribe_family`;
    return POST(url, payload);
}

const SubscriptionAPI = {
    cancelStripeSubscription: cancelStripeSubscription,
    getIntent: getIntent,
    createStripeIntent: createStripeIntent,
    getPrice: getPrice,
    subscribe: subscribe,
    cancelSubscription: cancelSubscription,
    checkIntentAndSubscribe: checkIntentAndSubscribe,
    subscribeFamily: subscribeFamily
}

export default SubscriptionAPI;
