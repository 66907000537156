import React from 'react';
import Header from '../components/layout/Header';
import { useParams, useLocation, Link } from 'react-router-dom';
// import moment from 'moment';
import Helper from '../utils/Helper';
import HomeAPI from '../utils/HomeAPI';
import ConversationAPI from '../utils/ConversationAPI';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


const MiniCourseList = () => {

    const location = useLocation();
    const [miniCourseList, setConversationList] = React.useState(null);
    const [activeCourse, setActiveCourse] = React.useState(1);

    React.useEffect(() => {
           
        ConversationAPI.getListMiniCourses()
            .then(res => {
                if (res.data) {
                    console.log(res);
                    setConversationList(res.data);
                }
            })
            .catch(err => {
                Helper.hideSpinner();
            });

    }, [location]);

    return (
        <div className="container-fluid">
            <Header />
                <div className="panel__under-construction"><h1>Mini Courses Under Construction</h1></div>
                <div className="row container-fluid flex-row-reverse">
                    <div className="conversation col-sm-12 col-lg-7 pb-5 pt-2">
                        <div className='conversation-page p-2'>
                            {miniCourseList && miniCourseList.map((miniCourse, index) => (
                                <div key={index}>
                                    {miniCourse.title}
                                    <ul>
                                        {miniCourse.conversations.map((conversation, index) => (
                                            activeCourse === miniCourse.id && (
                                                <li>{conversation.title}</li>
                                                )
                                            ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default MiniCourseList;
