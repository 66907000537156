import combineReducers from 'react-combine-reducers';
import authReducer, { initialState as authInitialState } from '../reducers/authReducer';
import modalReducer, { initialState as modalInitialState } from '../reducers/modalReducer';
import userReducer, { initialState as dataUserInitialState } from '../reducers/userReducer';

export const [reducer, initialState] = combineReducers({
  auth: [authReducer, authInitialState],
  modal: [modalReducer, modalInitialState],
  user: [userReducer, dataUserInitialState],
});
