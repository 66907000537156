import React from 'react';
import Header from '../components/layout/Header';
import _ from "lodash";
import { useLocation } from 'react-router-dom';
// import moment from 'moment';
import Helper from '../utils/Helper';
import PracticeAPI from '../utils/PracticeAPI';
import ConversationAPI from '../utils/ConversationAPI';
import 'react-responsive-modal/styles.css';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Recorder from '../components/AudioRecorder3'
import ProgressBar from "../components/ProgressBar";
import CapeeshScore from '../components/CapeeshScore'
// import RecorderVisualization from '../components/AudioRecorder/components'
import { ReactComponent as YouSpeaker } from './youSpeaker.svg'
import { ReactComponent as NativeSpeaker } from './nativeSpeaker.svg'
import { ReactComponent as RecordAhead } from './recordAhead.svg'
import { ReactComponent as Ahead } from './ahead.svg'
import { ReactComponent as MicrophoneOn } from '../assets/icon/microphone-on.svg'
import { ReactComponent as MicrophoneOff } from '../assets/icon/microphone-off.svg'
import { ReactComponent as Hidden } from '../assets/icon/hidden.svg'
import { ReactComponent as NotHidden } from '../assets/icon/notHidden.svg'
import { ReactComponent as Itaglish } from '../assets/icon/it_uk_flag.svg'
import { ReactComponent as English } from '../assets/icon/uk_flag.svg'
import { ReactComponent as Slow } from '../assets/icon/slow-speed.svg'
import { ReactComponent as Normal } from '../assets/icon/italian-speed.svg'
import { ReactComponent as NoMic } from '../assets/icon/mutedMic.svg'
import { ReactComponent as IncompleteConversation } from '../assets/icon/incomplete_conversation.svg'
import { ReactComponent as ComeOn } from '../assets/icon/come-on.svg';
import './_practice.scss';


function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

const Practice = () => {

    const location = useLocation();

    const recorderRef = React.useRef();
    const audioRef = React.useRef();
    const recordingAudioRef = React.useRef();

    const [isMic, setIsMic] = React.useState(true);
    const [isSlow, setIsSlow] = React.useState(false);
    const [isFailure, setIsFailure] = React.useState(false);
    const [isItaglish, setIsItaglish] = React.useState(false);

    const [isReview, setIsReview] = React.useState(false);
    const [isRecord, setIsRecord] = React.useState(true);
    const [isHint, setIsHint] = React.useState(true);
    const [showEnglish, setShowEnglish] = React.useState(true);
    const [playTime, setPlayTime] = React.useState(null);
    const [audioData, setAudioData] = React.useState({ blob: null, url: null, capeesh: null });
    const [isRecording, setIsRecording] = React.useState(false);
    const [practiceLine, setPracticeLine] = React.useState({
        "capeesh_score": null,
        "conversations_id": null,
        "easiness": null,
        "id": null,
        "line_id": null,
        "literal_translation": null,
        "next_at": null,
        "quality_score": null,
        "repetitions": null,
        "reviewed_at": null,
        "text": null,
        "translation": null,
        "true_repetitions": null,
        "user_id": null,
        "audio": null,
        "duration": null
    });

    const playRecording = (whatRef) => {
        whatRef.current.load();
        whatRef.current.play();
    }

    const SaveRecording = (data) => {
        setAudioData({
            blob: data.blob,
            url: data.url
        });
        blobToBase64(data.blob)
            .then(audio_dat => {
                var data = {
                    line_id: practiceLine.line_id,
                    audio_dat: audio_dat
                };
                ConversationAPI.scoreRecording(data)
                    .then(res => {
                        if (res.success && res.data) {

                            setAudioData((oldDat) => {
                                return {
                                    ...oldDat,
                                    capeesh: res.data.capeesh_score
                                }
                            });

                            setIsReview(true);
                            console.log('student: ' + res.data.student_recognized_as);
                            console.log('native: ' + res.data.native_recognized_as);
                            console.log('target: ' + res.data.target);
                        } else {
                            setIsReview(true);
                        }
                    })
                    .catch(err => {
                        setIsReview(true);
                    });
            });

    }

    const startRecording = () => {

        const timeStretch = isSlow ? 0.5 : .7;
        const playTime = 1000 + practiceLine.duration * 1 / timeStretch;

        setPlayTime(playTime);

        setIsRecording(true);

    }

    const controlRecorder = (time) => {

        if (time > playTime) {
            stopRecording();
        }
    }

    const stopRecording = () => {

        setPlayTime(null);

        if (isRecording)
            setIsRecording(false);

        if (recorderRef.current.state.recording)
            recorderRef.current.stopRecording();
    }

    React.useEffect(() => {
        if (isRecording) {
            recorderRef.current.startRecording();
        }
    }, [isRecording])

    const submitCard = (quality) => {
        const dat = {
            capeesh_score: audioData.capeesh,
            id: practiceLine.id,
            quality_score: quality
        };

        // const feedback5 = ['Grande!', 'Fantastico!', 'Bravo!', 'Forte!', 'Figo!'];
        // const feedback1 = ['Dai!', 'Forza!', 'Ce la fai!', 'La prossima volta!', "Va be'"];

        if (quality >= 4) {
            toast(_.sample(['Grande!', 'Fantastico!', 'Bravo!', 'Forte!', 'Figo!']), {
                icon: '🎉',
            });
        } else if (quality <= 1) {
            toast(_.sample(['Dai!', 'Forza!', 'Ce la fai!', 'La prossima volta!', "Va be'"]), {
                icon: '💪',
            });
        }

        PracticeAPI.submit(dat)
            .then(res => {
                PracticeAPI.serve()
                    .then(res => {
                        setPracticeLine(res.data);
                        setIsReview(false);
                    })
                    .catch(err => {
                        console.log(err);
                    });

            }).catch(err => {
                Helper.showToast('error', 5000, err.response.data.error);
            })
    }

    React.useEffect(() => {

        if (isReview) {
            setTimeout(() => playRecording(audioRef), 500);
        }

    }, [isReview])

    React.useEffect(() => {

        if (!isReview && practiceLine.id == null) {

            PracticeAPI.serve()
                .then(res => {
                    if (res.data) {
                        setPracticeLine(res.data);
                    } else {
                        console.log(res);
                    }
                })
                .catch(err => {
                    setIsFailure(true);
                    console.log(err);
                });

        }

        if (!isReview) {
            // clear all recordings and audio
            setAudioData({ blob: null, url: null, capeesh: null });
        }

    }, [isReview]);

    return (
        <div className="container-fluid">
            <Header 
                conversationListButton={ true } 
                aboutCourseButton = { false } 
                contactButton={true}
                pwaInstallButton={false} 
                faqButton={true} 
                pricingButton={false} 
                aboutUsButton={true}  />
            <Recorder ref={recorderRef} handleNoMic={(dat) => setIsMic(dat)} handleAudioStop={data => SaveRecording(data)} handleCountDown={data => controlRecorder(data)} />

            <div>
                <audio ref={audioRef}>
                    <source src={isSlow ? practiceLine.audio_slow : practiceLine.audio} />
                </audio>
            </div>

            <div className="recording_container">
                <audio ref={recordingAudioRef}>
                    <source src={audioData.url} type='audio/ogg' />
                    <source src={audioData.url} type='audio/mpeg' />
                </audio>
            </div>

            {!isMic &&
                <Tooltip enterTouchDelay={0} title="We do not have access to your microphone, or you do not have one.">
                    <div className="micStatus"><NoMic /></div>
                </Tooltip>
            }
            <div className="container practice d-flex flex-column align-items-center justify-content-start" >
                <div className='mt-2 mb-1'>
                    <h1 className='text__lora'>Practice What You've Learned</h1>
                </div>
                <div className="mt-1 mb-3 paraThick " >
                    Practice mode deals phrases from conversations that you’ve completed - look for the indicator by the conversation ( <IncompleteConversation className="practice__incomplete-icon" /> indicates an incomplete conversation).
                </div>
                {/*<div className="panel__under-construction"><h4>Under Construction</h4></div>*/}
                {isFailure ?
                    <div className="d-flex flex-column align-items-center justify-content-center practice__card">
                        <div className="text__lora  py-3">Uffa!</div>
                        <div className="mb-3 text-center fs-5">You don't have anything to practice yet! Please finish at least one conversation, then come back to practice the lines you learned.</div>
                        <div>
                            <ComeOn
                                height={250}
                            />
                        </div>

                    </div>
                    :
                    isReview ? (
                        <div className="d-flex flex-column align-items-center justify-content-center practice__card">
                            <div className="text__lora  py-3">Review</div>
                            <div className="practice__card-text--italian text__lora">{practiceLine.text}</div>
                            <div className="practice__card-text--english text__lora">{practiceLine.translation}</div>
                            <div className="d-flex flex-row align-items-center justify-content-center py-5">
                                <div className="mx-3 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-end pb-3">Native
                                        <IconButton onClick={() => playRecording(audioRef)}>
                                            <NativeSpeaker className="playBack" />
                                        </IconButton>
                                    </div>
                                    {audioData.blob &&
                                        <div className="d-flex align-items-center justify-content-end">You
                                            <IconButton onClick={() => playRecording(recordingAudioRef)}>
                                                <YouSpeaker className="playBack" />
                                            </IconButton>
                                        </div>
                                    }
                                </div>
                                {audioData.capeesh &&
                                    <div className="mx-3"><CapeeshScore replay={false} use="practiceReview" score={Math.round(audioData.capeesh)} /></div>
                                }
                            </div>
                            <div className=''>How Well Did You Do?</div>
                            <div className="practice__score d-inline-flex justify-content-around w-100">
                                <div className="d-inline-flex">
                                    <Tooltip placement="top" title="Don't Even Recognize It" enterTouchDelay={0}>
                                        <IconButton onClick={() => submitCard(0)}>
                                            <div className='practice__score-circle practice__score-circle-0'></div>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip placement="top" title="Recognized It" enterTouchDelay={0}>
                                        <IconButton onClick={() => submitCard(1)}>
                                            <div className='practice__score-circle practice__score-circle-1'></div>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className='d-flex flex-grow-1 justify-content-center'>
                                    <Tooltip placement="top" title="Familiar" enterTouchDelay={0}>
                                        <IconButton onClick={() => submitCard(2)}>
                                            <div className='practice__score-circle practice__score-circle-2'></div>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip placement="top" title="Familiar" enterTouchDelay={0}>
                                        <IconButton onClick={() => submitCard(3)}>
                                            <div className='practice__score-circle practice__score-circle-3'></div>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="d-inline-flex">
                                    <Tooltip placement="top" title="Somewhat Easy to Recall" enterTouchDelay={0}>
                                        <IconButton onClick={() => submitCard(4)}>
                                            <div className='practice__score-circle practice__score-circle-4'></div>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip placement="top" title="Very Easy to Recall" enterTouchDelay={0}>
                                        <IconButton onClick={() => submitCard(5)}>
                                            <div className='practice__score-circle practice__score-circle-5'></div>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="d-inline-flex justify-content-around w-100">
                                <div className="d-inline-flex">
                                    Incorrect
                                </div>
                                <div title="It's familiar and I got a part of it right." className='justify-content-center d-flex flex-grow-1'>
                                    Partly Correct
                                </div>
                                <div className="d-inline-flex">
                                    Correct
                                </div>
                            </div>
                        </div>
                    ) : (

                        <div className="d-flex flex-column align-items-center justify-content-center practice__card">

                            {/*<ProgressBar ref={progressBarRef} showText={false} total={recordTime/1000} bgcolor={'hsl(360, 100%, 100%)'} />*/}

                            <h2 className="text__lora  py-3">{isRecord ? 'Speak Aloud In Italian' : 'Say Phrase to Yourself or Aloud in Italian'} </h2>
                            <div className="practice__card-title">
                                <div>{isRecord && isRecording && <ProgressBar showText={false} bgcolor={'hsl(360, 100%, 100%)'} total={playTime / 1000} />} </div>
                                <div className="practice__card-text--italian ">{isItaglish ? practiceLine.literal_translation : practiceLine.translation}</div>
                                {isHint && <div className="practice__card-hint ">{practiceLine.text}</div>}
                            </div>

                            <div className=''>
                                <IconButton size="small" onClick={() => isRecord ? startRecording() : setIsReview(true)}>{isRecord ? <RecordAhead /> : <Ahead />}</IconButton>
                            </div>

                            <div className="practice__panel">
                                <div className="control_panel_icon_group">
                                    <Tooltip placement="right" enterTouchDelay={0} disableFocusListener title="Toggle Playback Speed">
                                        <IconButton onClick={() => setIsSlow(!isSlow)} size="small">
                                            {isSlow ? <Slow /> : <Normal />}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="control_panel_icon_group">
                                    <Tooltip placement="right" enterTouchDelay={0} title="Toggle Itaglish (literal translation)">
                                        <IconButton onClick={() => setIsItaglish(!isItaglish)} size="small">
                                            {isItaglish ? <Itaglish /> : <English />}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="control_panel_icon_group">
                                    <Tooltip placement="right" enterTouchDelay={0} title="Record Yourself">
                                        <IconButton onClick={() => setIsRecord(!isRecord)}>
                                            {isRecord ? <MicrophoneOn /> : <MicrophoneOff />}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="control_panel_icon_group">
                                    <Tooltip placement="right" enterTouchDelay={0} title="Show Hint">
                                        <IconButton onClick={() => setIsHint(!isHint)}>
                                            {isHint ? <NotHidden className="practice__card-tooltip-icon" /> : <Hidden className="practice__card-tooltip-icon" />}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                    )}
            </div>
        </div >

    );
}

export default Practice;