import React, { useContext } from 'react';
import { AppContext } from "../../App";

const useModal = () => {
    const { dispatch } = useContext(AppContext);

    const openModal = (type) => {
        dispatch({ type: 'OPEN_MODAL', payload: { type: type } });
    };

    // You can also add closeModal here if needed
    const closeModal = () => {
        dispatch({ type: 'CLOSE_MODAL' });
    };

    return { openModal, closeModal };
};

export default useModal;