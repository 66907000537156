import * as React from 'react';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {ReactComponent as CompareRecordings} from '../../assets/icon/compare-recordings.svg'
import {ReactComponent as Playback} from '../../assets/icon/playback.svg'
import {ReactComponent as NoPlayback} from '../../assets/icon/noplayback.svg'
import {ReactComponent as NoMic} from '../../assets/icon/mutedMic.svg'
import {ReactComponent as Mic} from '../../assets/icon/mic.svg'
import {ReactComponent as Cog} from '../../assets/icon/cog.svg'
import {ReactComponent as Capeesh} from './what-the.svg'
import {ReactComponent as Account} from '../../assets/icon/account.svg'
import {ReactComponent as PracticeMode} from '../../assets/icon/practice-mode.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: 380,
      transform: "translateZ(0px)",
      flexGrow: 1
    },
    fab: {
      backgroundColor: "#ffffff !important"
    },
    actionsEnabled: {
      backgroundColor: "#00AB67 !important"
    },
    actionsDisabled: {
      backgroundColor: "#E98463 !important"
    }
    // speedDial: {
    //   position: "absolute",
    //   bottom: theme.spacing(2),
    //   right: '50px'
    // },
    // fab: {
    //   backgroundColor: "#019920"
    // }
  })
);


export default function SpeedDialSettings(props) {
  const { 
    mic_disabled=true, 
    playback_disabled=true, 
    capeesh_disabled=false, 
    capeeshDisableCallback=null } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Backdrop open={open} sx={{zIndex:1300}}/>
      <SpeedDial
        ariaLabel="Tech Settings"
        sx={{ position: 'fixed', top: -10, right: -30, zIndex:1300}}
        classes={{ fab: classes.fab }}
        icon={<SpeedDialIcon icon={<Cog />} openIcon={<Cog />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="down"
        color='white'
      > 
        <SpeedDialAction
          sx={{ paddingRight:5}}
          key='mic_settings'
          classes = {{fab:mic_disabled ? classes.actionsDisabled : classes.actionsEnabled}}
          icon={mic_disabled ? <NoMic style={{fill:'#FFFFFF'}}/> : <Mic style={{height:'25px', width:'auto', fill:'#FFFFFF'}}/>}
          tooltipTitle={mic_disabled ? 'We do not have access to your microphone, but you can still do the course!' : 'Your microphone is connected.'}
          tooltipOpen
          onClick={handleClose}
        />

        <SpeedDialAction
          sx={{ paddingRight:5}}
          key='playback_settings'
          classes = {{fab:playback_disabled ? classes.actionsDisabled : classes.actionsEnabled}}
          icon={playback_disabled ? <NoPlayback style={{height:'25px', width:'auto', stroke:'#FFFFFF'}}/> : <Playback style={{stroke:'#FFFFFF'}}/>}
          tooltipTitle={playback_disabled ? 'This device does not support recording of your speech.' : 'You can record and playback your speech.'}
          tooltipOpen
          onClick={handleClose}
        />

        <SpeedDialAction
          sx={{ paddingRight:5, marginTop:5}}
          key='capeesh_settings'
          classes = {{fab:(mic_disabled | playback_disabled) | capeesh_disabled ? classes.actionsDisabled : classes.actionsEnabled}}
          icon={mic_disabled | playback_disabled ? <Capeesh style={{height:'25px', width:'auto', stroke:'#ffffff'}} /> : (capeesh_disabled ? <Capeesh style={{height:'25px', width:'auto', stroke:'#ffffff'}} /> : <Capeesh style={{height:'25px', width:'auto', stroke:'#FFFFFF'}} />)}
          tooltipTitle={mic_disabled | playback_disabled ? 'Capeesh score unavailable without mic or recording.' : (capeesh_disabled ? 'Click to enable Capeesh scores' : 'Click to disable Capeesh scores')}
          tooltipOpen
          onClick={!(mic_disabled | playback_disabled) ? () => capeeshDisableCallback(!capeesh_disabled) : handleClose}
        />

      </SpeedDial>
    </>
  );
}
