import React, { useEffect, useRef } from "react";
import { useLocation, Link } from 'react-router-dom';
import { AppContext, history } from "../../App";
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import NavFooter from '../../components/layout/NavFooter'
import Register from '../../components/Register';
import useModal from '../../components/Modal/useModal';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import carouselDat from './carouselDat';
import {ReactComponent as Furbo} from '../../assets/icon/furbo.svg'
import {ReactComponent as Check} from '../../assets/icon/check.svg'
import {ReactComponent as VaBene} from '../../assets/icon/vabene.svg';
import {ReactComponent as Good} from '../../assets/icon/good.svg';
import {ReactComponent as ComeOn} from '../../assets/icon/come-on.svg';
import CallActionBanner from '../../components/CallAction/CallActionBanner';
import AnalysisHomePage from '../../components/Copy/AnalysisHomePage';
import MethodHomePage from '../../components/Copy/MethodHomePage';
import ConversationHomePage from '../../components/Copy/ConversationHomePage';
import {ReactComponent as Pisa} from './pisa.svg';
import { scrollToRef, getRandomXsValue } from '../../utils/Helper';
import AuthAPI from "../../utils/AuthAPI";
import UserAPI from '../../utils/UserAPI';
import DealColor from '../../components/DealColor'
import './main.scss'
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Nav from 'react-bootstrap/Nav';
import Placeholder from 'react-bootstrap/Placeholder';

const Main = () => {
    const { dispatch, state } = React.useContext(AppContext);
    const location = useLocation();

    const howUseRef = React.useRef(null);
    const analysisRef = React.useRef(null);
    const conversationRef = React.useRef(null);
    const methodRef = React.useRef(null);

    
    const [stats, setStats] = React.useState([]);
    const [subscriptions, setSubscriptions] = React.useState({ user_setup_intent_id: 1, active_customer: false, active_subscriptions: [], inactive_subscriptions: [] });

    const [carouselIndex, setCarouselIndex] = React.useState(0);

    const handleCarouselSelect = (selectedIndex) => {
        console.log(selectedIndex);
        setCarouselIndex(parseInt(selectedIndex));
      };

    const { openModal, closeModal } = useModal();

    const bootstrapThemes = [
        'primary',
        'info',
        'warning',
        'danger',
        'success',
        'warning',
        'info'];

    const getSubscribedConversationCount = (dat) => {

        console.log(dat);

        const conversationSubscribed = dat.find(item => item.label === 'Conversations Subscribed');

        // Access the 'major' property of the found element
        const n_subscribed = conversationSubscribed ? conversationSubscribed.major : null;

        return n_subscribed

    }

    React.useEffect(() => {

        if (state.auth.token) {
            UserAPI.getSubscriptions()
            .then(res => {
                if (res.data) {
                    setSubscriptions(res.data);
                }
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 401) {
                    history.push(`/login-sign-up?redirectTo=${window.location.pathname}`)
                }
            });

            UserAPI.getStats()
            .then(res => {
                if (res.data) {
                    setStats(res.data);
                }
            })
            .catch(err => {
                console.log(err)
            });
        }
        

    }, [location.pathname]);
    
    return (
        <>
        {/*<NavFooter loginButton={true} conversationListButton={false} pwaInstallButton={true} />*/}
        {/*  */}

        { !state.auth.token ? 
            <>
                <Header 
                    loginButton={ true }
                    methodRef={methodRef}
                    conversationRef={conversationRef}
                    analysisRef={analysisRef}
                    howUseRef={howUseRef}
                    conversationListButton={ false }
                    aboutCourseButton = {true}
                    contactButton = {true}
                    pwaInstallButton={false}
                    faqButton={true}
                    pricingButton={true}
                    aboutUsButton={true}  />
                <div className="container">
                    <div className="d-flex justify-content-center row my-5">
                        <div className="flex-column align-items-center">
                            <div className="text-center my-3 px-1">
                                <h1 className="text__lora">Learn Italian the Natural Way</h1>
                            </div>
                            <div className="text-center my-3 px-1">
                                Fast track your conversational skills using practical dialogue, with a focus on listening and speaking - Similar to immersion, but from anywhere!
                            </div>
                            <div className="my-5 px-1 d-flex flex-row justify-content-center align-items-center">
                                <div className="fw-normal fs-5 mx-3">Ready to try the first conversation?</div>
                                <Button variant="warning text-white">Try It For Free!</Button>
                            </div>
                        </div>
                    </div>
                    {/*<Row className="justify-content-center">
                        <Col md={12} lg={8}>
                            <p className="lh-base fw-normal">Hi! I'm Andrew Bogaard, the founder of Italian Naturally, currently living in the beautiful city of Turin, Italy. I studied Spanish for 8 years but never got
                            very far when it came to conversing with native speakers. I figured I was bad at language. I couldn't understand their fast pace of speaking, and when it was my time to speak,
                            I often had trouble being understood. Luckily, when I moved 
                            to Switzerland, I decided to try a different approach - I studied French with a fantastic course called French Together. The aim was to learn the language naturally - through conversation.</p>

                            <p className="lh-base fw-normal">Listening to short conversations, and imitating them as I spoke, I made really quick gains that got me actually communicating. And fast! So when I moved to Turin, I thought, why not do the same 
                            for Italian?</p>
                        </Col>
                    </Row>*/}

                    <Row className="text-center my-3">
                            <h2 className="fw-light">Why Choose Italian Naturally?</h2>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={6} lg={4}>
                            <div className='py-3 pe-3 d-flex-column justify-content-center justify-content-md-start'>
                                <div className="my-4 text-center text-md-start">
                                    <Furbo
                                        height={70}
                                    />
                                </div>
                                <div className="my-1">
                                    <h5 className="text-center text-md-start">Practical and Engaging Learning Experience</h5>
                                </div>
                                <div className="text-center text-md-start">
                                    <p className="lh-lg">Become conversational faster than traditional language courses by focusing on practical dialogues and high-impact words.</p>
                                    <Button variant="primary text-white" onClick={()=>scrollToRef(conversationRef)}>Learn About Our Conversations</Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div className='py-3 ps-3 d-flex-column justify-content-center justify-content-md-start'>
                                <div className="my-4 text-center text-md-start">
                                    <VaBene
                                        height={70}
                                    />
                                </div>
                                <div className="my-1">
                                    <h5 className="text-center text-md-start">For Beginners and More Advanced Learners</h5>
                                </div>
                                <div className="text-center text-md-start">
                                    <p className="lh-lg">Whether you're starting from scratch or refining your pronunciation and accent, you’ll learn from our simulated immersion method!</p>
                                    <Button variant="info text-white" onClick={()=>scrollToRef(howUseRef)}>Explore Our Course Structure</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={6} lg={4}>
                            <div className='py-3 pe-3 d-flex-column justify-content-center justify-content-md-start'>
                                <div className="my-4 text-center text-md-start">
                                    <ComeOn
                                        height={70}
                                    />
                                </div>
                                <div className="my-1">
                                    <h5 className="text-center text-md-start">Focus on Listening and Speaking</h5>
                                </div>
                                <div className="text-center text-md-start">
                                    <p className="lh-lg">Train your ears and mouth. With Italian Naturally you’ll listen to real conversations and get speaking right away. Get feedback with voice recognition trained on real Italian speakers!</p>
                                    <Button variant="success text-white" onClick={() => scrollToRef(methodRef)}>Learn About Our Innovative Method</Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div className='py-3 ps-3 d-flex-column justify-content-center justify-content-md-start'>
                                <div className="my-4 text-center text-md-start">
                                    <Good
                                        height={70}
                                    />
                                </div>
                                <div className="my-1">
                                    <h5 className="text-center text-md-start">Simulated Immersion - From Anywhere</h5>
                                </div>
                                <div className="text-center text-md-start">
                                    <p className="lh-lg">Like immersion, we’ll expose you to the words used most. For example, if you learn the right 74 verbs you’ll understand spoken verbs 80% of the time!</p>
                                    <Button variant="warning text-white" onClick={() => scrollToRef(analysisRef)}>Learn How We Created The Course</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row ref={howUseRef} className="text-center bg-orange-light-gray mt-5 rounded-top-5">
                        <h2 className="fw-light mt-4">Explore Our Course Structure</h2>
                    </Row>

                    <Row className="text-center my-0 bg-orange-light-gray px-5">
                        <p className="lh-base fs-5 my-5">
                            With Italian Naturally you will work through conversations, using a seven-step approach to master listening, speaking, reading, and remembering.
                        </p>
                    </Row>

                    <Row className="mb-4 text-center justify-content-center pt-2 border-orange-light-gray border-5 rounded-bottom-5">
                        <Nav variant="underline justify-content-center" activeKey={carouselIndex} defaultActiveKey={carouselIndex} onSelect={ handleCarouselSelect }>
                            {carouselDat.map((dat,i) => 
                                <Nav.Item key={i}>
                                    <Nav.Link eventKey={i}>{dat['label']}</Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                        <Carousel indicators={false} activeIndex={carouselIndex} onSelect={handleCarouselSelect} interval={null} >
                            {carouselDat.map((dat,i) => 
                                <Carousel.Item className="text-center" key={i}>
                                    <img 
                                        src={dat['image']}
                                        alt={dat['label']} 
                                        className={`carouselImagev2 ${dat['tip'] && 'extra_margin'}`}
                                    />
                                    <Carousel.Caption className="text-center">
                                        <div className='d-flex flex-row align-items-center justify-content-center'>
                                            {React.createElement(dat['labelImage'], {className:'carouselLabelImage'})}
                                            <h3 style={{margin:0}}>{dat['label']}</h3>
                                        </div>
                                        <p>{dat['content']}</p>
                                        {dat['tip'] && <div className="rounded p-1 carouselTipDiv bg-light d-flex flex-row align-items-center">
                                            <div className="mx-2">
                                                {React.createElement(dat['tipImage'], {className:'carouselTipImage'})}
                                            </div>
                                            <div className="text-start">
                                                <small>{dat['tip']}</small>
                                            </div>
                                        </div>}
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )}
                        </Carousel>
                    </Row>

                    <CallActionBanner />
                          {/*<Carousel.Item>                  
                            <Carousel.Caption className='h-100 d-flex flex-column align-items-center justify-content-around'>
                                <div className="carouselHeaderDiv">
                                    <h4 className="text__inter">
                                        Step {' '}
                                        <Step1
                                            width={40}
                                            height={40} 
                                            text="Step 1" />
                                    </h4>
                                    <h3 className="text__inter fw-normal">Listen</h3>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 text-start" style={{height:60}}>
                                    <p className="fs-5 text__inter fw-normal">to the short conversation, without grand expectations. Try to understand something.</p>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 d-flex justify-content-center align-items-center carouselImageDiv">
                                    <img 
                                        src={listenImg.default}
                                        className="text-center carouselImage"
                                        alt="Listen"
                                    />
                                </div>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>                  
                            <Carousel.Caption className='h-100 d-flex flex-column align-items-center justify-content-around'>
                                <div className="carouselHeaderDiv">
                                    <h4 className="text__inter">
                                        Step {' '}
                                        <Step2
                                            width={40}
                                            height={40} 
                                            text="Step 2" />
                                    </h4>
                                    <h3 className="text__inter fw-normal">Speak along</h3>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 text-start" style={{height:60}}>
                                    <p className="fs-5 text__inter fw-normal">Speak together with the native speakers. Try to match their
                                        cadence.</p>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 d-flex justify-content-center align-items-center carouselImageDiv">
                                    <img 
                                        src={speakalongImg.default}
                                        className="text-center carouselImage"
                                        alt="Listen"
                                    />
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 carouselTipDiv bg-light d-flex flex-row align-items-center">
                                    <div><PlaybackSpeedImg 
                                        height={25}
                                        
                                    /></div>
                                    <div className="rounded text-start">
                                        <small>Tip: Slow down replay.</small>
                                    </div>
                                </div>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>                  
                            <Carousel.Caption className='h-100 d-flex flex-column align-items-center justify-content-around'>
                                <div className="carouselHeaderDiv">
                                    <h4 className="text__inter">
                                        Step {' '}
                                        <Step3
                                            width={40}
                                            height={40} 
                                            text="Step 3" />
                                    </h4>
                                    <h3 className="text__inter fw-normal">Reading</h3>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 text-start" style={{height:60}}>
                                    <p className="fs-5 text__inter fw-normal">Associate sounds with the written word. Continue speaking
                                        aloud, now alone.</p>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 d-flex justify-content-center align-items-center carouselImageDiv">
                                    <img                                 
                                        src={readingImg.default}
                                        className="text-center carouselImage"
                                        alt="Listen"
                                    />
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 bg-light d-flex flex-row align-items-center carouselTipDiv">
                                    <div><TranslationType 
                                        height={40}
                                        
                                    /></div>
                                    <div className="rounded text-start">
                                        <small>Tip: Toggle between literal and proper translations to learn unique Italian constructions.</small>
                                    </div>
                                </div>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>                  
                            <Carousel.Caption className='h-100 d-flex flex-column align-items-center justify-content-around'>
                                <div className="carouselHeaderDiv">
                                    <h4 className="text__inter">
                                        Step {' '}
                                        <Step4
                                            width={40}
                                            height={40} 
                                            text="Step 4" />
                                    </h4>
                                    <h3 className="text__inter fw-normal">Listen</h3>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 text-start" style={{height:60}}>
                                    <p className="fs-5 text__inter fw-normal">Participate in the conversation as each speaker.</p>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 d-flex justify-content-center align-items-center carouselImageDiv">
                                    <img 
                                        src={recordingImg.default}
                                        className="text-center carouselImage"
                                        alt="Listen"
                                    />
                                </div>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>                  
                            <Carousel.Caption className='h-100 d-flex flex-column align-items-center justify-content-around'>
                                <div className="carouselHeaderDiv">
                                    <h4 className="text__inter">
                                        Step {' '}
                                        <Step5
                                            width={40}
                                            height={40} 
                                            text="Step 5" />
                                    </h4>
                                    <h3 className="text__inter fw-normal">Pronounciation</h3>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 text-start" style={{height:60}}>
                                    <p className="fs-5 text__inter fw-normal">Listen back to yourself and compare the recording to the
                                        native speaker.</p>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 d-flex justify-content-center align-items-center carouselImageDiv">    
                                    <img 
                                        src={playbackImg.default}
                                        className="text-center carouselImage"
                                        alt="Listen"
                                    />
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 bg-light d-flex flex-row align-items-center carouselTipDiv">
                                    <div><ReplayImg 
                                        height={20}
                                        
                                    /></div>
                                    <div className="rounded text-start">
                                        <small>Tip: words we didn’t understand will be highlighted. Click the smiley-face to hear your voice back to back with the native speaker.</small>
                                    </div>
                                </div>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>                  
                            <Carousel.Caption className='h-100 d-flex flex-column align-items-center justify-content-around'>
                                <div className="carouselHeaderDiv">
                                    <h4 className="text__inter">
                                        Step {' '}
                                        <Step6
                                            width={40}
                                            height={40} 
                                            text="Step 6" />
                                    </h4>
                                    <h3 className="text__inter fw-normal">Recall</h3>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 text-start" style={{height:60}}>
                                    <p className="fs-5 text__inter fw-normal">By now you've repeated this conversation dozens of times! It
                                        will be easy to recall the Italian from memory.</p>
                                </div>
                                <div className="col-12 col-md-9 col-lg-6 d-flex justify-content-center align-items-center carouselImageDiv">    
                                    <img 
                                        src={recallImg.default}
                                        className="text-center carouselImage"
                                        alt="Listen"
                                    />
                                </div> 
                            </Carousel.Caption>
                          </Carousel.Item>*/}
{/*                        </Carousel>
                    </div>*/}

                    <Row className="text-center mt-5">
                        <h2 className="fw-light mt-4">Our Unique Approach to Learning</h2>
                    </Row>

                    <Row className="text-center my-5">
                        <p className="lh-base fs-4">
                            Our conversations are a product of extensive data analysis. Italian Naturally 
                            leverages over <span className="fw-semibold">1.7 million words from dialogues in 
                            Italian subtitles to design </span>dialogues that matter in 
                            <span className="fw-semibold"> real-world communication</span>.
                        </p>
                    </Row>

                    <AnalysisHomePage ref={analysisRef} />

                    <CallActionBanner />

                    <MethodHomePage ref={methodRef} />

                    <CallActionBanner />

                    <ConversationHomePage ref={conversationRef} />

                    {/*<div className="row pt-2 mx-0 focus_what_matters">
                        <div className="col d-flex justify-content-center align-items-center order-2">
                            <div className="">
                                <VaBene className="capeesh_img mb-4" alt="Va bene" />
                                <p>Train your ear with true conversations</p>
                            </div>
                        </div>
                        <div className="main__buttons col d-flex justify-content-center align-items-center order-1">
                            <div className="">
                                <ComeOn className="capeesh_img my-2" alt="Come on" />
                                <p>Learn to speak with proper pronunciation and cadence</p>
                            </div>
                        </div>
                        <div className="main__buttons col py-5 order-0">
                            <div className="">
                                <h3 className="">Focus on What Matters!</h3>
                            </div>
                        </div>
                        <div className="main__buttons col d-flex justify-content-center align-items-center order-3">
                            <div className="">
                                <Furbo className="capeesh_img mb-4" alt="Furbo" />
                                <p>Start Speaking Immediately!</p>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-center align-items-center order-4">
                            <div className="">
                                <Good className="capeesh_img mb-4" alt="Bene" />
                                <p>The most used words and phrases</p>
                            </div>
                        </div>
                    </div>*/}
                    {/*<div ref={walkthroughRef}  className="walkthroughRow row pt-4 justify-content-center align-items-center">
                        <h2 className="home-main__banner  ">6 Steps to Speaking!</h2>
                    </div>
                    <div className="walkthroughRow row pt-0 mt-4 pb-3 mb-5 justify-content-center align-items-start flex-wrap">
                        <div className='mb-5 d-flex flex-column justify-content-center col-lg-5'>
                            <div className='d-flex flex-row align-items-center'>
                                <div><Step1 /></div>
                                <div>
                                    <div className='stepTitle'>Listen</div>
                                    <div className='stepDetails'>Without grand expectations. Try to understand something.</div>
                                </div>
                            </div>
                            <div className="d-flex ml-5 justify-content-center">
                                <img src={listenImg.default} className="listen_img" alt="Listen" />
                            </div>
                        </div>
                        <div className='mb-5 d-flex flex-column justify-content-center col-lg-5'>
                            <div className='d-flex flex-row align-items-center'>
                                <div><Step2 /></div>
                                <div>
                                    <div className='stepTitle'>Speak along</div>
                                    <div className='stepDetails'>Speak together with the native speakers. Try to match their cadence.</div>
                                </div>
                            </div>
                            <div className="d-flex ml-5 justify-content-center">
                                <img src={speakalongImg.default} className="listen_img" alt="Listen" />
                            </div>
                            <div className="tip">
                                <PlaybackSpeedImg />
                                <small>Tip: Slow down replay.</small>
                            </div>
                        </div>
                        <div className='mb-5 d-flex flex-column justify-content-center col-lg-5'>
                            <div className='d-flex flex-row align-items-center'>
                                <div><Step3 /></div>
                                <div>
                                    <div className='stepTitle'>Reading</div>
                                    <div className='stepDetails'>Associate sounds with the written word. Continue speaking aloud, now alone.</div>
                                </div>
                            </div>
                            <div className="d-flex ml-5 justify-content-center">
                                <img src={readingImg.default} className="reading_img" alt="Reading" />
                            </div>
                            <div className="tip">
                                <TranslationType />
                                <small>Tip: toggle between literal and proper translations to learn unique Italian constructions.</small>
                            </div>
                        </div>
                        <div className='mb-5 d-flex flex-column justify-content-center col-lg-5'>
                            <div className='d-flex flex-row align-items-center'>
                                <div><Step4 /></div>
                                <div>
                                    <div className='stepTitle'>Play the part</div>
                                    <div className='stepDetails'>Participate in the conversation as each speaker.</div>
                                </div>
                            </div>
                            <div className="d-flex ml-5 justify-content-center">
                                <img src={recordingImg.default} className="reading_img" alt="Reading" />
                            </div>
                        </div>
                        <div className='mb-5 d-flex flex-column justify-content-center col-lg-5'>
                            <div className='d-flex flex-row align-items-center'>
                                <div><Step5 /></div>
                                <div>
                                    <div className='stepTitle'>Pronunciation</div>
                                    <div className='stepDetails'>Listen back to yourself and compare the recording to the native speaker.</div>
                                </div>
                            </div>
                            <div className="d-flex ml-5 justify-content-center">
                                <img src={playbackImg.default} className="listen_img" alt="Listen" />
                            </div>
                            <div className="tip">
                                <ReplayImg />
                                <small>Tip: words we didn’t understand will be highlighted. Click the smiley-face to hear your voice back to back with the native speaker.</small>
                            </div>
                        </div>
                        <div className='mb-5 d-flex flex-column justify-content-center col-lg-5'>
                            <div className='d-flex flex-row align-items-center'>
                                <div><Step6 /></div>
                                <div>
                                    <div className='stepTitle'>Recall</div>
                                    <div className='stepDetails'>By now you've repeated this conversation dozens of times! It will be easy to recall the Italian from memory.</div>
                                </div>
                            </div>
                            <div className="d-flex ml-5 justify-content-center">
                                <img src={recallImg.default} className="listen_img" alt="Listen" />
                            </div>
                        </div>
                        <div className="d-flex col-12 justify-content-center">
                            <button className="button__layout--ghost button__layout--link h6" onClick={() => history.push("/howto")}>Read more about how to use our course</button>
                        </div>
                    </div>*/}
{/*                    <div ref={getStartedRef} className="row pt-3 mt-5 pb-3 mb-5 justify-content-around align-items-start">
                        <div className='d-flex justify-content-center col-lg-6'>
                            <Register page='splash' />    
                        </div>
                        <div className='d-flex flex-column pl-lg-5 pl-3 pt-2 col-lg-6'>
                            <h3 ref={analysisRef} className="my-2">Our Word Analysis</h3>
                            <div className="">
                                <p>When you study a language in school, or from a textbook, you'll march through basic seeming words. Like "to swim". Pretty basic verb, right? Probably should know that one, right?</p>

                                <p>Not really! We ran an analysis of more than 1.7M words used in Italian subtitles, and, as far as verbs go, <b>there are 392 other verbs used more frequently than nuotare (to swim)</b>.</p>
                                <p>We have some good news, though! Only 74 verbs accounted for 80%!</p>

                                <p>Put another way, <b>if you learn the right 74 verbs, you'll understand spoken verbs about 80% of the time!</b> We built the course with this in mind.</p>
                                <p><Button onClick={() => history.push("/analysis")}>Read more about our analysis</Button></p>
                            </div>
                        </div>
                    </div>*/}
                                        
                    <Row className="my-5">
                        <Col xs={12} md={6} className='d-flex justify-content-center align-items-start'>
                            <Register page='splash' />    
                        </Col>
                        <Col xs={12} md={6} className="map_section">
                            <div className="col-12 d-flex-column">
                                <div className="">
                                    {/*<h5 className="text__color--light">used in conversation.<br/><small>(based on analysis of over 1.7M words in modern-day movies and television)</small>></h5>*/}
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>
            </>
        : 
            <>
                {/*<Header isLogoCenter='true' loginButton={ !state.auth.token } conversationListButton={ false } pwaInstallButton={false}  />*/}
                <Header 
                    loginButton={ !state.auth.token } 
                    conversationListButton={ true } 
                    practiceButton={true}
                    aboutCourseButton = {false} 
                    contactButton = {true} 
                    pwaInstallButton={false} 
                    faqButton={true} 
                    pricingButton={!subscriptions.active_customer} 
                    aboutUsButton={false}  
                />
                <Container>   
            
                    <Row className="m-1 m-md-4 d-flex flex-row align-items-end justify-contend-between">
                        <Col xs={12} md={5}>
                            <div>
                                <h1 className="text-start my-3 text__lora">Benvenuto!</h1>
                                <p>Welcome back to the course</p>
                                
                                <div>
                                <Badge pill bg="success" className="my-1">Access</Badge>
                                {subscriptions.active_customer ? 
                                    <>
                                    <p>Thanks for buying a subscription! You have access to {getSubscribedConversationCount(stats)} conversations.</p>
                                    <p>Your subscriptions include: { subscriptions.active_subscriptions.map((s, i) => <span key={i}>{s.name}{', '}</span>) }</p>
                                    </>
                                    : 
                                    <>
                                    <p>You are using free access and have access to {getSubscribedConversationCount(stats)} conversations!</p>
                                    <p>Click <Button variant="link m-0 p-0 border-0 align-top" href="/account#subscribe">here</Button> if you'd like to purchase the course!</p>
                                    </>
                                }
                                </div>
                            </div>
                        </Col>
                        <Col xs={8} md={5}>
                            <Button variant="success text-white shadow-sm m-2" href="/conversations" size="lg">Main Course Conversations</Button>
                            <Button variant="outline-success m-2 shadow-sm" href="" size="lg">Vacation Crash Course Conversations</Button>
                            <Button variant="outline-warning m-2 text-orange shadow-sm" size="lg">Practice what you've learned</Button>
                        </Col>
                        <Col xs={4} md={2} className="justify-content-center">
                            <Pisa 
                                height={150}
                            />
                        </Col>
                    </Row>
                    <Row className="m-1 m-md-4">
                        <Col>
                            <Badge pill bg="primary">
                                Progress
                            </Badge>
                        </Col>
                    </Row>
                    <Row className="m-1 m-md-4">
                        { stats.length !== 0 ? (
                            stats.map((s,i) => 
                                <Col xs="auto" className="p-3" key={i}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                <span className="display-2">{s.major}</span><span className="display-6">{s.minor}</span>
                                            </Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted fs-5 text__poppins">{s.label}</Card.Subtitle>
                                            <Card.Text>
                                                <ProgressBar striped variant={bootstrapThemes[i]} now={s.now} />
                                            </Card.Text>
                                            <Card.Link href={s.href}>{s.anchor}</Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        ) : (
                            Array.from({ length: 8 }).map((_, i) => (
                              <Col xs="auto" className="p-3" key={i}>
                                <Card style={{ width: '18rem' }}>
                                  <Card.Body>
                                    <Placeholder as={Card.Title} animation="glow">
                                      <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder as={Card.Subtitle} animation="glow">
                                      <Placeholder xs={getRandomXsValue(4,10)} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                      <Placeholder  className="w-100" />
                                    </Placeholder>
                                    <Placeholder.Button variant="link" xs={6} />
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))
                        )
                        }
                    </Row>

                </Container>
            </>
        }
        <Footer />

        {/*<Modal 
            open={open} 
            onClose={onCloseModal}
            center
            classNames = {{root: 'pay-modal'}}
            styles = {{root: {zIndex:'1999'}, modal : {padding:'50px', borderRadius:'5px', maxWidth:'90vw', maxHeight:'95vh'}}}
        >
            { modalType==='optimized' ?
                <img style={{width:'100%', minWidth:'700px', height:'auto', maxWidth:'88vw', maxHeight:'90vh'}} src={twoScreensImg.default} />
                :
                modalType==='mainCourse' ?
                <div className="d-flex flex-column">
                    <div className="">
                        <h4 className="text__lora "><Learn style={{padding:'10px', width:'60px', height:'auto'}} />The Main Course</h4>
                    </div>
                    <div className="course_details d-flex flex-column fake-ul">
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">For learning Italian holistically and <b>simulating language immersion</b></div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Dialogues are designed to include <b>words and phrases</b> most used in conversation</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Access to all of our special tools (record and listen to yourself, Capeesh score with immediate feedback that specified words to work on, slow motion) to help you speak clearly and to be understood</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Powered by our analysis of over <b>1.7M words</b> used in modern movie and television subtitles</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">The first 20 conversations include over 57% of the words used in the subtitles we analyzed (over 1.7M words)!</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Train your ear with over 14 voices of Italy from 10 different regions</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">20 conversations per chapter</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Grammar tips curated by a professor with decades of experience</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Culture notes and common expressions you won't learn in a textbook</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Second chapter coming Summer 2022</div>
                        </div>
                    </div>
                </div>
                :
                modalType==='vacationCourse' &&
                <div className="d-flex flex-column">
                    <div className="">
                        <h4 className="text__lora "><Learn style={{padding:'10px', width:'60px', height:'auto'}} />Vacation Crash Course</h4>
                    </div>
                    <div className="course_details d-flex flex-column fake-ul">
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Learn the exact phrases repeated often by Italians so that you can get more out of your trip to Italy!</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Shortcuts for understanding and responding, even if you miss some grammar</div>
                        </div>
                        <div className="text__inter fake-li">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Some of the topics covered include:</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Introducing yourself</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Asking questions about others</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Where’s the bathroom?</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Sorry, I don’t understand</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Ordering at the caffè</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Walkthrough at the restuarant, five ways</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Different events at the theatre</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">How much does it cost?</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">This and that</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Where you’ve been and where you're going</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Directions</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Telling time</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Calling in a reservation</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">Buying tickets</div>
                        </div>
                        <div className="fake-subli">
                            <span className="ic-check"><Check /></span>
                            <div className="text">At the train station</div>
                        </div>
                        
                    </div>
                </div>
            }
        </Modal>*/}
        </>
    );
}

export default Main;