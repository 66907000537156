import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { useParams, useLocation, Link} from 'react-router-dom';
import { history } from "../App"
import Helper from '../utils/Helper';
import HomeAPI from '../utils/HomeAPI';
import ConversationAPI from '../utils/ConversationAPI';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Switch from "react-switch";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import toast from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GrammarAccordion from '../components/GrammarAccordion';
import useAudioRecorder from '../components/AudioRecorder4';
import PlayWord from '../components/PlayWord'
import PlayWordButton from '../components/PlayWordButton'
import HTMLRenderer from 'react-html-renderer'
import TimedProgressBar from "../components/ProgressBar";
import ProgressRing from '../components/ProgressRing'
import CapeeshScore from '../components/CapeeshScore'
import BasicModal from '../components/BasicModal'
import ProgressStepper from '../components/ProgressStepper'

import SpeedDialSettings from '../components/SpeedDialSettings'

import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';

import {ReactComponent as LeftMuted} from '../assets/icon/left-muted.svg'
import {ReactComponent as LeftUnmuted} from '../assets/icon/left-unmuted.svg'
import {ReactComponent as RightMuted} from '../assets/icon/right-muted.svg'
import {ReactComponent as RightUnmuted} from '../assets/icon/right-unmuted.svg'
import {ReactComponent as TranslationOff} from '../assets/icon/translation-off.svg'
import {ReactComponent as TranslationOn} from '../assets/icon/translation-on.svg'
import {ReactComponent as MicrophoneOn} from '../assets/icon/microphone-on.svg'
import {ReactComponent as MicrophoneOff} from '../assets/icon/microphone-off.svg'
import {ReactComponent as WhatThe} from '../assets/icon/what-the.svg'
import {ReactComponent as Hidden} from '../assets/icon/hidden.svg'
import {ReactComponent as NotHidden} from '../assets/icon/notHidden.svg'
import {ReactComponent as ArrowRight} from '../assets/icon/arrow-right.svg'
import {ReactComponent as ArrowLeft} from '../assets/icon/arrow-left.svg'
import {ReactComponent as Book} from '../assets/icon/book.svg'
import {ReactComponent as Chalkboard} from '../assets/icon/chalkboard.svg'
import {ReactComponent as Itaglish} from '../assets/icon/it_uk_flag.svg'
import {ReactComponent as English} from '../assets/icon/uk_flag.svg'
import {ReactComponent as Slow} from '../assets/icon/slow-speed.svg'
import {ReactComponent as Normal} from '../assets/icon/italian-speed.svg'
import {ReactComponent as Tips} from '../assets/icon/tips2.svg'
import {ReactComponent as Mic} from '../assets/icon/mic.svg'
import {ReactComponent as NoMic} from '../assets/icon/mutedMic.svg'
import {ReactComponent as Capeesh} from '../components/CapeeshScore/what-the.svg'
import {ReactComponent as Stop} from '../assets/icon/stop.svg'
import {ReactComponent as Play} from '../assets/icon/play.svg'
import {ReactComponent as Backward} from '../assets/icon/backward.svg'
import {ReactComponent as Forward} from '../assets/icon/forward.svg'

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const stages = [
    {   progress:0, 
        label:null,
        name:'1: Listening', 
        instructions:<>Listen to the normal speed (<Normal style={{height:'15px', width:'auto', margin:'0px', backgroundColor:'white', borderRadius:'15px'}}/>) conversation twice, then listen to the slow conversation (<Slow style={{height:'15px', width:'auto', margin:'0px', backgroundColor:'white', borderRadius:'15px'}}/>) twice. It's normal that you don't understand! Try to get a sense where the words start and stop. Once you're comfortable, continue to step 2.</>,
        whereMuted : [],
        blurryText:true,
        showEnglish : false,
        isRecord : false,
        testingMode : false,
        //isSlow : false,
    },
    {   progress:20,
        label:null,
        name:'2: Speak along, left',
        instructions:"Click on single lines on the left side of the conversation, and speak along. Try to match the speaker's pronounciation, accent, and intonation. This is hard and awkward at first, but it will get easier. Once you're comfortable, continue to step 3.",
        whereMuted : [],
        blurryText:true,
        showEnglish : false,
        isRecord : false,
        testingMode : false,
        //isSlow : true,
    },
    {   progress:30,
        label:null,
        name:'3: Speak along, right',
        instructions:"Click on single lines on the right side of the conversation, and speak along. Try to match the speaker's pronounciation, accent, and intonation. This is hard and awkward at first, but it will get easier.",
        whereMuted : [],
        blurryText:true,
        showEnglish : false,
        isRecord : false,
        testingMode : false,
        //isSlow : true,
    },
    {   progress:50,
        label:null,
        name:'4: Read along',
        instructions:<>Click Play All, and read along. Try to associate the sounds of the language with the words. Toggle between 'Itaglish' (<Itaglish style={{height:'14px', width:'auto', margin:'0px'}}/>) and 'English' (<English style={{height:'14px', width:'auto', margin:'0px'}}/>) translations to see how the literal phrases in Italian are different from ours. Often you cannot directly translate from English into Italian -- you need to learn their way of saying things.</>,
        whereMuted : [],
        blurryText:false,
        showEnglish : true,
        isRecord : false,
        testingMode : false,
        //isSlow : true,
    },
    {   progress:70,
        label:null,
        name:'5: Speaking alone, left',
        instructions:<>Click 'Play All', and speak the left part (you can also do single lines). Don't worry if this is difficult at first, it is hard for everyone. Slow down (<Slow style={{height:'15px', width:'auto', margin:'0px', backgroundColor:'white', borderRadius:'15px'}}/>) the playback speed to give yourself more time. Once you're done, click the 'Capeesh' (<Capeesh style={{height:'15px', width:'auto', margin:'0px', backgroundColor:'white', borderRadius:'15px'}}/>) icon to listen to yourself and the native speaker back-to-back. Listen for where you could improve, and try as many times as you'd like!</>,
        whereMuted : ['l'],
        blurryText:false,
        showEnglish : true,
        isRecord : true,
        testingMode : false,
        //isSlow : true,
    },
    {   progress:80,
        label:null,
        name:'6: Speaking alone (right)',
        instructions:<>Click 'Play All', and speak the right part (you can also do single lines). Don't worry if this is difficult at first, it is hard for everyone. Slow down (<Slow style={{height:'15px', width:'auto', margin:'0px', backgroundColor:'white', borderRadius:'15px'}}/>) the playback speed to give yourself more time. Once you're done, click the 'Capeesh' (<Capeesh style={{height:'15px', width:'auto', margin:'0px', backgroundColor:'white', borderRadius:'15px'}}/>) icon to listen to yourself and the native speaker back-to-back. Listen for where you could improve, and try as many times as you'd like!</>,
        whereMuted : ['r'],
        blurryText:false,
        showEnglish : true,
        isRecord : true,
        testingMode : false,
        //isSlow : true,
    },
    {   progress:100,
        label:null,
        name:'7: Recall Italian',
        instructions:"As the final challenge, using the English or Itaglish translations, recall the Italian phrases and speak them aloud. Click 'Play All' to start the conversation. After you speak each line, the native speaker will repeat it for you.",
        whereMuted : ['l','r'],
        blurryText:false,
        showEnglish : false,
        isRecord : true,
        testingMode : true,
        //isSlow : false,
    }
]

const ConversationPlayer = (props) => {

    const {
        conversation=null, 
        capeeshDisabled=false, 
        setCapeeshDisabled=null,
        playbackDisabled=false} = props;

      const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
      } = useAudioRecorder();

    const location = useLocation();
    let { id, chapter, lesson, variant=1 } = useParams();

    const listenWalkthrough = React.useRef(null);
    const slowWalkthrough = React.useRef(null);
    const speakWalkthrough = React.useRef(null);
    const recordWalkthrough = React.useRef(null);

    const progressBarRef = React.useRef(null);

    let timeoutId = null;
    const [value, setValue] = React.useState(0);
    
    // const [trackProgress, setTrackProgress] = React.useState(null);
    const intervalRef = React.useRef();

    const recorderRef = React.useRef();

    const [whereMuted, setWhereMuted] = React.useState([]);
    
    const [audioRefs, setAudioRefs] = React.useState([]);

    const [recordingAudioRefs, setRecordingAudioRefs] = React.useState([]);
    const [lineMapping, setLineMapping] = React.useState({});

    const [conversationIndex, setConversationIndex] = React.useState(-1);
    const [isSlow, setIsSlow] = React.useState(false);
    const [isItaglish, setIsItaglish] = React.useState(false);
    const [playAll, setPlayAll] = React.useState(1);

    const [conversationCapeeshScores, setConversationCapeeshScores] = React.useState(null);

    const [showEnglish, setShowEnglish] = React.useState(true);
    const [isRecord, setIsRecord] = React.useState(false);
    const [blurryText, setBlurryText] = React.useState(true);
    const [testingMode, setTestingMode] = React.useState(false);

    const [isPlaying, setIsPlaying] = React.useState();
    const [isWalkthrough, setIsWalkthrough] = React.useState(true);

    const [audioData, setAudioData] = React.useState([{blob:null, url:null, capeesh:null}]);

    const [stage, setStage] = React.useState(0);

    const [recordTime, setRecordTime] = React.useState(null);
    const [lastRecordTime, setLastRecordTime] = React.useState(null);

    const apiBase = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : '/api';

    const calcAvCapeeshScore = (dat) => {

        if (!dat) return "?";

        if (dat.length<conversation.lines.filter(a => a.position!=='s').length) return "?";

        const scores = dat.map(d => d.capeesh_score);
        return Math.round(scores.reduce((p,c) => p+c, 0) / scores.length)

    }

    const updateConversationProgress = (index) => {
        if (stage!==index) {

            // setConversationIndex(0);

            const dat = {
                conversation_id:conversation.id,
                progress:stages[index].progress
            }
            ConversationAPI.updateProgress(dat)
                .then(res => {
                    if (res.success) {
                        setStage(index);
                        // setConversation(res.data);
                    }
                })
                .catch(err => {
                    Helper.readError(err);
                });
            }
    }

    const markActiveWalkthrough = (walkthroughRef) => {
        listenWalkthrough.current.style.background = 'none';
        slowWalkthrough.current.style.background = 'none';
        speakWalkthrough.current.style.background = 'none';
        recordWalkthrough.current.style.background = 'none';

        walkthroughRef.current.style.background = "#eeeeee";
    }

    const ToggleRecording = () => {
        if (!isRecord) {
            setIsRecord(true);
            if (whereMuted.length===0) {
                setWhereMuted(['r']);
            }
        } else {
            setIsRecord(false);
        }
    }

    const toggleWhereMuted = (side) => {
        setWhereMuted((prevState) => {
            const newState = prevState.includes(side) ? prevState.filter(el => el!==side) : [...prevState, side]
            if (newState.length==0)
                setIsRecord(false);
            return newState
        })
    }

    const playRecording = (whatRef) => {
        setIsPlaying(true);
        setPlayAll(0);
        whatRef.current.load();
        whatRef.current.play();
    }

    const startConversation = () => {
        setPlayAll(1);
        if (conversationIndex === -1) setConversationIndex(0);
    }

    const stopConversation = () => {

        audioRefs.map((audioRef) => {
            if (audioRef.current) {
                if (!audioRef.current.children[0].paused) {
                    audioRef.current.children[0].pause();
                    audioRef.current.children[0].currentTime = 0;
                }
            }
        })

        recordingAudioRefs.map((recorderRef) => { // iterate through all recordings and see if any are playing, if so, stop them
            if (recorderRef.current) {
                if (!recorderRef.current.paused) {
                    recorderRef.current.pause();
                    recorderRef.current.currentTime = 0;
                }
            }
        })
        
        if (testingMode) {
            setIsRecord(true);
        }

        stopRecording();

        setPlayAll(0);
        setConversationIndex(-1);
        setIsPlaying(false);
        clearTimeout(timeoutId);
    }

    const forcePlayLine = (thisRef) => {
        setIsPlaying(true)
        thisRef.current.children[0].muted = false;

        thisRef.current.children[0].load();
        thisRef.current.children[0].play()
    }

    const playLine = (index) => {
        setPlayAll(0);
        setConversationIndex(index);
    }

    const playConversationIndex = (ind) => {
        setPlayAll(0);
        setConversationIndex(ind);
    }

    const appendCapeeshScore = (index) => {
        //console.log('trying to get capeesh score');
        const newAudioData = [...audioData];
        blobToBase64(newAudioData[index].blob)
            .then(audio_dat => {
                var data = {
                    line_id: conversation.lines[index].id,
                    audio_dat: audio_dat
                };
                ConversationAPI.scoreRecording(data)
                .then(res => {
                    if (res.success && res.data) {
                        newAudioData[index].capeesh = res.data.capeesh_score;
                        newAudioData[index].words_to_improve = res.data.student_recognized_as;
                        conversation.lines[index].text = conversation.lines[index].text.replace(/<\/?[^>]+(>|$)/g, "").split(" ").map((word,i) => {
                            if (res.data.words_to_improve.includes(i)) {
                                return '<span class="text--wavy-underline">'+word+'</span>'
                            } else {
                                return word
                            }
                        }).join(' ')
                        //conversation.lines[index].text = '<span class="text--wavy-underline">'+conversation.lines[index].text+'</span>';
                        
                        setAudioData(newAudioData);
                        getCapeeshScores();
                        console.log('student: '+res.data.student_recognized_as);
                        console.log('native: '+res.data.native_recognized_as);
                        console.log('target: '+res.data.target);
                        console.log('words to work on : '+res.data.words_to_improve);
                    }
                })
                .catch(err => {
                    //console.log(err);
                    //console.log('couldnt get capeesh score');
                    //
                    Helper.readError(err);
                });
            });
    }

    const SetControlsByStage = () => {
        setWhereMuted(stages[stage].whereMuted);
        setShowEnglish(stages[stage].showEnglish)
        setIsRecord(stages[stage].isRecord);
        setBlurryText(stages[stage].blurryText);
        setTestingMode(stages[stage].testingMode);
    }

    React.useEffect(() => {
        
        setIsItaglish(false);
        setIsSlow(false);
        setWhereMuted(stages[stage].whereMuted);
        setShowEnglish(stages[stage].showEnglish)
        setIsRecord(stages[stage].isRecord);
        setBlurryText(stages[stage].blurryText);
        setTestingMode(stages[stage].testingMode);

        // setIsSlow(obj[0].isSlow); // no longer set this way

    }, [stage, lesson])

    React.useEffect(() => {
        setIsPlaying(false);
    }, [lesson])

    React.useEffect(() => { // if audiodata updated, try to get capeesh score

        if (capeeshDisabled) {
            return
        }

        const newAudioData = [...audioData];
        try {
            newAudioData.map((ad, index) => !ad.capeesh && ad.capeesh!==0 && ad.blob && appendCapeeshScore(index));
        } catch {
            //console.log('probably not initialized')
        }
        
    }, [audioData])

    const newSaveRecording = () => {
        if (playbackDisabled) {
            return
        }
        setAudioData((prevState) => {
            const newState = [...prevState]
            newState[conversationIndex] = {
                blob: recordingBlob,
                capeesh: null,
                recognized_as:null,
            }
            return newState
        });
    }

    const SaveRecording = (data) => {

        if (playbackDisabled) {
            return
        }

        setAudioData((prevState) => {
            const newState = [...prevState]
            newState[conversationIndex] = {
                blob: data.blob,
                url: data.url,
                capeesh: null,
                recognized_as:null,
            }
            return newState
        });
    }

    const UpdateConversationIndex = () => {
        if (conversationIndex < conversation.lines.length-1) {
            let i=1;
            while (i<conversation.lines.length) {
                if (conversation.lines[conversationIndex+i].position==='s')
                    i++
                else
                    break
            }

            setConversationIndex(conversationIndex + i);
        } else {
            setConversationIndex(-1);
        }
    }

    const advanceTrack = () => {

        timeoutId = setTimeout(() => {

            if (conversationIndex>-1) {
                if ((testingMode & isRecord) | (isRecord & whereMuted.includes(conversation.lines[conversationIndex].position))) { // if we just recorded and we are in testing mode, play the native speaker. if playAll is set, it will continue afterwards
                    setIsRecord(false);
                    audioRefs[conversationIndex].current.children[0].muted = false;
                    setIsPlaying(true);
                    audioRefs[conversationIndex].current.children[0].load();
                    audioRefs[conversationIndex].current.children[0].play();
                } else if ((testingMode & playAll & !isRecord) | (!isRecord & playAll)) { // here we just listened to the native speaker and we need to go to the next track to record
                    setIsRecord(true);
                    UpdateConversationIndex(); 
                } else if ((testingMode & !playAll & !isRecord) | (!isRecord & !playAll)) { // here we just listened to the native speaker and we need to set us up to record again, but not play next track
                    setIsRecord(true);
                    setConversationIndex(-1);
                } else if (playAll) {
                    UpdateConversationIndex();
                } else {
                    setIsPlaying(false);
                    setConversationIndex(-1);
                }
            } else {
                setIsPlaying(false);
            }
        }, 800)}


    const newControlRecorder = () => {

        const timeStretch = isSlow ? 0.75 : 1;
        const playTime = 1500 + conversation.lines[conversationIndex].duration * 1 / timeStretch;

        setRecordTime(playTime);
        setLastRecordTime(playTime);

    }

    // const controlRecorder = (time) => {
        
    //     const timeStretch = isSlow ? 0.75 : 1;
    //     const playTime = 1500 + conversation.lines[conversationIndex].duration * 1 / timeStretch;

    //     progressBarRef.current.updateProgress(100*time/playTime)
    //     // recordingAudioProgressRefs[conversationIndex].current.progress=100*time/playTime;
    //     //setTrackProgress(100*time/playTime); // update recording bar on 20221129

    //     if (time>playTime) {

    //         if (testingMode)
    //             setIsRecord(false);
    //         stopRecording();
    //         timeoutId = setTimeout(function(){
    //             advanceTrack();
    //         }, 800);
    //     }
    // }

    React.useEffect(() => {

        if (testingMode & conversationIndex!=-1 & isRecord) { // 
            newControlRecorder();
            
            setIsPlaying(true);

        } else if (conversationIndex > -1 & !testingMode) {
                        
            if (isRecord && whereMuted.includes(conversation.lines[conversationIndex].position)) {
                newControlRecorder();
            }

            setIsPlaying(true);

            if (whereMuted.includes(conversation.lines[conversationIndex].position)) {
                // audioRefs[conversationIndex].current.children[0].muted = true;
                // audioRefs[conversationIndex].current.children[0].playbackRate = 0.75;
            } else {
                audioRefs[conversationIndex].current.children[0].load();
                audioRefs[conversationIndex].current.children[0].play();
            }
            

        } else {
            setIsPlaying(false);
        }

        if (conversationIndex!=-1) { // check to make sure we're not already scrolled below
            let y = audioRefs[conversationIndex].current.getBoundingClientRect().top + window.pageYOffset - document.getElementById("pageHeader").getBoundingClientRect().height - 200;
                
            window.scrollTo({top:y, behavior:'smooth'});

        }

    }, [conversationIndex]);

    const initAudioDat = (conversation) => {
        if (conversation){

            //console.log(conversation);
            setAudioRefs((audioRefs) =>
                Array(conversation.lines.length)
                  .fill()
                  .map((_, i) => audioRefs[i] || React.createRef()),
            );
            
            setRecordingAudioRefs((audioRefs) =>
                Array(conversation.lines.length)
                  .fill()
                  .map((_, i) => audioRefs[i] || React.createRef()),
            );
            
            const newAudioData = Array(conversation.lines.length)
                .fill()
                .map(() => ({blob:null, capeesh:null}))
            
            setAudioData(newAudioData);
            
            let mapObj = {};
            conversation.lines.forEach((line, index) => {
                mapObj[line.line] = index;
            }
            );
            
            setLineMapping(mapObj);
        }
    }

    const getCapeeshScores = () => {
        if (id) {
            ConversationAPI.getConversationCapeeshScores(id)
                .then(res => {
                    if (res.data) {
                        setConversationCapeeshScores(res.data);
                    }
                })
                .catch(err => {
                    Helper.readError(err);
                });

        } else {
            ConversationAPI.getConversationCapeeshScores(chapter, lesson)
                .then(res => {
                    if (res.data) {
                        setConversationCapeeshScores(res.data);
                    }
                })
                .catch(err => {
                   Helper.readError(err);
                });
        }
    }

    React.useEffect(() => {
        if (conversation) {
            getCapeeshScores();
            initAudioDat(conversation);
            const index = stages.findIndex(x => x.progress==conversation.progress);
            if (index>=0) {
                setStage(index);
            }
        }

    }, [conversation]);

    React.useEffect(() => {
        if (recordTime>0) {
            startRecording(recordTime);
        }
    }, [recordTime])
    
    React.useEffect(() => {

        if (recordingBlob) {
            // getBlobDuration(recordingBlob).then((duration) => {console.log("blob duration: " + duration +' expected ' + lastRecordTime/1000)});
            newSaveRecording();
            advanceTrack();
        }
    }, [recordingBlob])

    return (
        <>
{/*            {conversation &&
                <SpeedDialSettings 
                    mic_disabled={!isMic} 
                    playback_disabled={ playbackDisabled } 
                    audio_disabled={ !Helper.browserCompatibilityAudio() } 
                    capeesh_disabled={capeeshDisabled}
                    capeeshDisableCallback={setCapeeshDisabled} /> 
            }*/}
            {/*<Recorder ref={recorderRef} handleNoMic={(dat)=>setPlaybackDisabled(!dat)} handleAudioStop={data => SaveRecording(data)} handleCountDown={data => controlRecorder(data)} />*/}
            {conversation && conversation.lines.length===audioData.length && conversation.lecture==lesson ?
                
                <div className="w-100">
                    {/*<div className='location_tree d-none d-md-flex'>
                        <div className="locationCourse">
                            <Link to={conversation.chapter_name ? "/conversations" : "/mini_courses"}>
                                <Chalkboard/>{conversation.chapter_name ? "Main Course" : "Mini-Courses"}
                            </Link>
                            <span>:</span>
                        </div>
                        <div className="locationChapter">
                            <Link to={conversation.chapter_name ? `/conversations/chapter/${conversation.chapter}` : `/mini_courses/${conversation.mini_course_id}`}>
                                <Book/>{conversation.chapter_name ? conversation.chapter_name : conversation.mini_course_name}
                            </Link> 
                            <span>:</span>
                        </div>
                        <div className="locationConversation">
                                <div className="treeTitle">{conversation.title}</div>
                        </div>
                    </div>*/}
                    <div className='d-flex d-md-none align-items-center justify-content-between'>
                        <div>
                            <BasicModal buttonText={<Tips style={{marginRight:'5px', width:'35px', height:'auto'}}/>} title='Instructions' copy={stages[stage].instructions} />    
                        </div>
                        <div className='pt-2 flex-column align-items-center justify-content-center' style={{width:'200px'}}>
                            <ProgressStepper 
                                stageIndex={stage}
                                updateCallback={updateConversationProgress}
                                label={stages[stage].name}
                                maxStageIndex={stages.length}
                            />
{/*                            <div className="d-flex align-items-center" style={{fontSize:'12px', fontWeight:'200'}}>
                                Step {stages[stage].name}.<br/>{stage===100 ? 'Click to start over.' : "Click to advance."}
                            </div>*/}
                        </div>
                    </div>
                    <div className="sticky-top sticky-offset d-none d-md-flex flex-column bg-white">
                        <div className='controls d-none d-md-flex'>
                            {isWalkthrough ? (
                                <div className="d-flex flex-row align-items-center w-100 justify-content-between">
                                    <div className="walkthrough-controls flex-row align-items-center mr-auto">
                                        <Tooltip enterTouchDelay={0} disableFocusListener title="Start Conversation">
                                            <span>
                                                <IconButton disabled={isPlaying} size="small" onClick={startConversation}>
                                                    <Play className='play_button' />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip enterTouchDelay={0} disableFocusListener title="Stop Conversation">
                                            <span>
                                                <IconButton disabled={!isPlaying} size="small" onClick={stopConversation}>
                                                    <Stop className='stop_button'/>
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip enterTouchDelay={0} disableFocusListener title="Toggle Playback Speed">
                                            <IconButton size="small" onClick={() => setIsSlow(!isSlow)}>
                                                {isSlow ? <Slow /> : <Normal />}
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip enterTouchDelay={0} disableFocusListener title="Toggle Itaglish (literal translation)">
                                            <span>
                                                <IconButton disabled={!(showEnglish | testingMode)} size="small" onClick={() => setIsItaglish(!isItaglish)}>
                                                    {isItaglish ? <Itaglish /> : <English  />}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div className="pr-1" style={{width:'200px'}}>
                                        <ProgressStepper 
                                            stageIndex={stage}
                                            updateCallback={updateConversationProgress}
                                            label={stages[stage].name}
                                            maxStageIndex={stages.length}
                                        />
                                    </div>
                                </div>   
                            ) : (
                                <div className='d-flex p2 w-100 align-items-center'>
                                    <div className="control-panel">
                                        <div className="p-2 d-inline-flex align-items-center ml-1">
                                            <div className="control_panel_icon_group">
                                                <Tooltip enterTouchDelay={0} title="Mute Left Native Vocals">
                                                    <IconButton color="success" size="small" onClick={() => toggleWhereMuted('l')} >
                                                        { whereMuted.includes('l') ? <LeftMuted/> : <LeftUnmuted/> }
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip enterTouchDelay={0}  title="Mute Right Native Vocals">
                                                    <IconButton size="small" onClick={() => toggleWhereMuted('r')} >
                                                        { whereMuted.includes('r') ? <RightMuted/> : <RightUnmuted/> }
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="control_panel_icon_group">
                                                <Tooltip enterTouchDelay={0} disableFocusListener title="Toggle Playback Speed">
                                                    <IconButton size="small" onClick={() => setIsSlow(!isSlow)} >
                                                        {isSlow ? <Slow />  : <Normal /> }
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="control_panel_icon_group">
                                                <Tooltip enterTouchDelay={0} title="Toggle Itaglish (literal translation)">
                                                    <IconButton size="small" onClick={() => setIsItaglish(!isItaglish)} >
                                                        { isItaglish ? <Itaglish />  :  <English/>}
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="control_panel_icon_group">
                                                <Tooltip enterTouchDelay={0} title="Show Translation">
                                                    <IconButton size="small" onClick={() => setShowEnglish(!showEnglish)}> 
                                                        {showEnglish ? <TranslationOn  /> : <TranslationOff />}
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="control_panel_icon_group">
                                                <Tooltip enterTouchDelay={0} title="Record Yourself">
                                                    <IconButton onClick={ToggleRecording}>
                                                        {isRecord ? <MicrophoneOn  /> : <MicrophoneOff />}
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="control_panel_icon_group">
                                                <Tooltip enterTouchDelay={0} title="Show text">
                                                    <IconButton onClick={() => setBlurryText(!blurryText)}>
                                                        {blurryText ? <Hidden style={{width:'25px', height:'auto'}} /> : <NotHidden style={{width:'25px', height:'auto'}} />}
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div id='conversation-page' className='conversation-page d-flex flex-column'>
                        <div className="conversation__instructions d-none d-md-flex">
                            <div style={{width:'25px', marginRight:'-7px'}}><Tips /></div>
                            <div className='conversation__instructions-motivation'>
                                {stages[stage].instructions}
                            </div>
                        </div>
                        {conversation && conversation.lines.map((conversation_line, index) => conversation_line.position=='s' ? (
                            <div className={"conversation_s"} key={index}>
                                {conversation_line.text}
                            </div>
                            ) : (
                            <div className={"conversation_" + conversation_line.position} key={index}>                                    
                                {audioData[index].blob && (
                                    <div className="recording_container">
                                        <audio ref={recordingAudioRefs[index]} id={'record' + index.toString()} onEnded={() => forcePlayLine(audioRefs[index], true)}>
                                            <source src={URL.createObjectURL(audioData[index].blob)} type='audio/ogg' />
                                            <source src={URL.createObjectURL(audioData[index].blob)} type='audio/mpeg' />
                                        </audio>

                                    </div>
                                    /* Mi ha detto che non vuole qualcosa di nuovo. */
                                )}
                                <div className="conversation_bubble" style={{ 'outline': conversationIndex===index ? "5px solid hsl(212, 0%, 88%)" : "none"}}>
                                    {(audioData[index].capeesh || audioData[index].capeesh===0) ? 
                                        <div onClick={() => playRecording(recordingAudioRefs[index])} >
                                            <CapeeshScore use="conversationLine" score={audioData[index].capeesh} />
                                        </div>
                                         : capeeshDisabled && audioData[index].url && 
                                        <div onClick={() => playRecording(recordingAudioRefs[index])} >
                                            <CapeeshScore use="conversationLineCapeeshDisabled" />
                                        </div>
                                    }
                                    <div className="conversation_bubble_text" onClick={() => playLine(index)}>
                                        {isRecording && conversationIndex===index &&
                                            //<ProgressBar ref={progressBarRef} showText={false} total={recordTime/1000} bgcolor={'hsl(360, 100%, 100%)'} />

                                            <TimedProgressBar striped={true} timeoutInSeconds={recordTime/1000} label="Speak now"/>
                                        }
                                        <div>
                                            <div className={`conversation_line ${blurryText && 'invisible'}`} to="#">{!testingMode ? <HTMLRenderer html={conversation_line.text} /> : isItaglish ? conversation_line.literal_translation : conversation_line.translation }</div>
                                        </div>
                                        {blurryText && <div className='hiddenText'><HTMLRenderer html={conversation_line.svg} /></div>}
                                        <div className={`translation ${blurryText && 'invisible'}`} style={{ 'display': showEnglish & !testingMode ? 'flex' : 'none'}}>{isItaglish ? conversation_line.literal_translation : conversation_line.translation}</div>
                                    </div>
                                </div>
                                {/*<div className="recording-indicator" style={isRecording && conversationIndex===index ? {display:"block"} : {visibility:"hidden"}}></div>*/}
                                <div ref={audioRefs[index]}>
                                   {/* <audio 
                                        src={conversation_line.id.audio}
                                        onEnded={advanceTrack}
                                    />*/}
                                    <audio key={conversation_line.id} onEnded={advanceTrack}>
                                        <source src={isSlow ? conversation_line.audio_slow : conversation_line.audio} />
                                    </audio>
                                </div>
                            </div>
                        ))}
                    <div className="conversation__speakers w-100 d-flex justify-content-between">
                        <div>{conversation.left_actor_name} {conversation.left_actor_location}</div>
                        <div>{conversation.right_actor_name} {conversation.right_actor_location}</div>
                    </div>
                    </div>
                </div>
                
                : 

                // HERE IS FILLER

                <div className="w-100">
                    <div className='location_tree'>
                        <div><Skeleton variant="text" width={350} height={35} /></div>
                    </div>
                    <div className="controls-container sticky-top layout__offset-top-80">
                        <div className='control_selector d-inline-flex align-items-end'>
                            <div className={`tab active'}`}><button className="btn-tab">WALKTHROUGH</button></div>
                            <div className={`tab`}><button className="btn-tab">MANUAL</button></div>
                            <div className="ml-auto pr-1">
                                <button className="btn-play-conversation"></button>
                            </div>
                        </div>
                        <div className='controls'>
                        </div>
                    </div>
                    <div id='conversation-page' className='conversation-page d-flex flex-column'>
                        <Stack width="100%" spacing={0.5}>
                            <Box sx={{width:"30%"}}><Skeleton variant="text" height={50} /></Box>
                            <Box sx={{width:"30%", alignSelf:"flex-end"}}><Skeleton variant="text" height={50} /></Box>
                            <Box sx={{width:"30%"}}><Skeleton variant="text" height={50} /></Box>
                            <Box sx={{width:"30%", alignSelf:"flex-end"}}><Skeleton variant="text" height={50} /></Box>
                            <Box sx={{width:"30%"}}><Skeleton variant="text" height={50} /></Box>
                            <Box sx={{width:"30%", alignSelf:"flex-end"}}><Skeleton variant="text" height={50} /></Box>
                            <Box sx={{width:"30%"}}><Skeleton variant="text" height={50} /></Box>
                            <Box sx={{width:"30%", alignSelf:"flex-end"}}><Skeleton variant="text" height={50} /></Box>
                        </Stack>
                    </div>
                </div>

                //END FILLER 
                
        }
        {conversation &&
        <Box sx={{ width: '100%' }} className="d-flex d-md-none control_panel_icon_group">
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <IconButton size="large"  onClick={() => setIsSlow(!isSlow)}>
                            {isSlow ? <Slow /> : <Normal />}
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton disabled={!(showEnglish | testingMode)} size="large" onClick={() => setIsItaglish(!isItaglish)}>
                            {isItaglish ? <Itaglish /> : <English  />}
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                       <IconButton disabled={conversation.lecture==1} size="large" onClick={() => history.push('/conversations/course/'+ conversation.chapter.toString()+'/'+(conversation.lecture-1).toString())}>
                            <Backward className='backward_button'/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton disabled={isPlaying} size="large" onClick={startConversation}>
                            <Play className='play_button' />
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton disabled={!isPlaying} size="large" onClick={stopConversation}>
                            <Stop className='stop_button'/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton disabled={conversation.lecture>=conversation.n_lectures} size="large" onClick={() => history.push('/conversations/course/'+ conversation.chapter.toString()+'/'+(conversation.lecture+1).toString())}>
                            <Forward className='forward_button'/>
                        </IconButton>
                    </Grid>


                </Grid>
            </Paper>
        </Box>
        }        
        </>
    );
}

export default ConversationPlayer;