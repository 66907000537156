import React, { useState, useEffect } from 'react';
import ProgressBar from "react-bootstrap/ProgressBar"

import './progress-bar.scss'

const TimedProgressBar = ({ timeoutInSeconds, variant="success", label="", striped=false }) => {
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const interval = timeoutInSeconds * 1000 / 100; // Calculate interval based on timeout
    let currentProgress = 0;
    
    const timer = setInterval(() => {
      currentProgress += 1;
      setCompleted(currentProgress);
      
      if (currentProgress >= 100) {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [timeoutInSeconds]);

  return (
    <ProgressBar striped={striped} variant={variant} label={label} min={0} max={100} now={completed} />
  );
};

export default TimedProgressBar;