// our blue:
const colorBluePrimary= 'hsl(212, 66%, 45%)';
const colorOrangePrimary= 'hsl(15, 66%, 55%)';
const colorGreenPrimary= 'hsl(152, 66%, 45%)';

// light mids
const colorGreenMid= 'hsl(152, 50%, 75%)';
const colorBlueMid= 'hsl(210, 100%, 80%)';
const colorOrangeMid= 'hsl(12, 100%, 85%)';

//lights
const colorOrangeLight= 'hsl(15, 100%, 98%)';
const colorBlueLight= 'hsl(212, 100%, 98%)';

//dark mids
const colorGreenMidDark = 'hsl(152, 50%, 65%)';
const colorBlueMidDark = 'hsl(212, 50%, 65%)';
const colorOrangeMidDark = 'hsl(15, 75%, 65%)';

//grays
const colorOrangeLightGray = 'hsl(15, 20%, 98%)';
const colorBlueLightGray = 'hsl(212, 20%, 98%)';
const colorGreenLightGray = 'hsl(152, 20%, 98%)';

const funColors = [
					colorBluePrimary,
					colorOrangePrimary,
					colorGreenPrimary,
					colorGreenMid,
					colorBlueMid,
					colorOrangeMid,
					colorGreenMidDark,
					colorBlueMidDark,
					colorOrangeMidDark
				];

 const DealColor = (index) => {

 	if (index==null) index = Math.floor(Math.random() * funColors.length);

	return funColors[index]
}

export default DealColor