export const initialState = {
    email: null
};

const userReducer = (state, action) => {

    switch (action.type) {
        case 'SET_SELECTED_EMPLOYEE':
            return {
                ...state,
                email: action.payload.data,
            };
        default:
            return state;
    }
};

export default userReducer;
