import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

const CultureChapter1 = () => {
	return (
		<>
            <p>
                The main course will teach you what you need to know to fit in!
            </p>
            <ListGroup className="list-group-flush list-unstyled mt-3 mb-4">
            	<ListGroup.Item>Coffee, Cappuccino (and when to drink it)</ListGroup.Item>
				<ListGroup.Item>L'ammazzacaffè</ListGroup.Item>
				<ListGroup.Item>Italian Hospitality</ListGroup.Item>
				<ListGroup.Item>The Elderly</ListGroup.Item>
				<ListGroup.Item>Proverbs</ListGroup.Item>
				<ListGroup.Item>Onomastico</ListGroup.Item>
				<ListGroup.Item>Common Expressions</ListGroup.Item>
				<ListGroup.Item>Cold Weather</ListGroup.Item>
				<ListGroup.Item>Aperitivo</ListGroup.Item>
				<ListGroup.Item>Cold Weather</ListGroup.Item>
				<ListGroup.Item>Trattoria, osteria, ristorante</ListGroup.Item>
				<ListGroup.Item>Meal coursing</ListGroup.Item>
				<ListGroup.Item>Reservations</ListGroup.Item>
			</ListGroup>
		</>
	)
}

export default CultureChapter1;