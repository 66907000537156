import {ReactComponent as CapeeshImg} from '../../assets/img/main/capeesh.svg';
import {ReactComponent as TranslationType} from '../../assets/img/main/translationtype.svg';
import {ReactComponent as ReplayImg} from '../../assets/img/main/replay.svg';
import {ReactComponent as PlaybackSpeedImg} from '../../assets/img/main/playbackSpeed.svg';
import {ReactComponent as Step1} from '../../assets/icon/1.svg'
import {ReactComponent as Step2} from '../../assets/icon/2.svg'
import {ReactComponent as Step3} from '../../assets/icon/3.svg'
import {ReactComponent as Step4} from '../../assets/icon/4.svg'
import {ReactComponent as Step5} from '../../assets/icon/5.svg'
import {ReactComponent as Step6} from '../../assets/icon/6.svg'

// carousel images
const listenImg = require('./conversation_hidden.svg');
const speakalongImg = require('../../assets/img/speakalong.svg');
const readingImg = require('../../assets/img/readingedited.jpg');
const playbackImg = require('../../assets/img/playbackedited_short.jpg');
const recordingImg = require('../../assets/img/recordingedited_sm.png');
const recallImg = require('../../assets/img/recalledited.png');

const carouselDat = [
	{
		label:'Listen',
		labelImage:Step1,
		content:'Listen to the short conversation, without grand expectations. Try to understand something.',
		image:listenImg.default,
		tip: null,
		tipImage: null
	},
	{
		label:'Speak along',
		labelImage:Step2,
		content:'Speak together with the native speakers. Try to match their cadence.',
		image:speakalongImg.default,
		tip:'Tip: Slow down replay.',
		tipImage: PlaybackSpeedImg
	},
	{
		label:'Read',
		labelImage:Step3,
		content:'Associate sounds with the written word. Continue speaking aloud, now alone.',
		image:readingImg.default,
		tip:'Tip: Toggle between literal and proper translations to learn unique Italian constructions.',
		tipImage: TranslationType
	},
	{
		label:'Speak alone',
		labelImage:Step4,
		content:'Participate in the conversation as each speaker',
		image:recordingImg.default,
		tip: null,
		tipImage: null
	},
	{
		label:'Pronunciation',
		labelImage:Step5,
		content:'Listen back to yourself and compare the recording to the native speaker',
		image:playbackImg.default,
		tip:'Tip: words we didn’t understand will be highlighted. Click the smiley-face to hear your voice back to back with the native speaker.',
		tipImage:ReplayImg
	},
	{
		label:'Recall',
		labelImage:Step6,
		content:"By now you've repeated this conversation dozens of times! It will be easy to recall the Italian from memory",
		image:recallImg.default,
		tip: null,
		tipImage: null
	},
]

export default carouselDat;