export const initialState = {
    isOpen:false,
    type:'register'
};

const modalReducer = (state, action) => {

    switch (action.type) {
        case 'OPEN_MODAL':
            return {

                isOpen:true,
                type:action.payload.type

            };
        case 'CLOSE_MODAL':
            return {
                ...state,
                isOpen:false,
            };
        default:
            return state;
    }
};

export default modalReducer;
