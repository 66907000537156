import axios from 'axios';
import { GET, POST, PUT } from './Http';
import Const from '../utils/Constants';

const apiBase = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : '/api';

const getProfile = async () => {
    const url = `${apiBase}/user/find_registration`;
    return POST(url, {});
}

const getSubscriptions = async () => {
    const url = `${apiBase}/user/subscriptions`;
    return GET(url);
}

const getStats = async () => {
    // [{label:'test', major:'100', 'minor':'/22'},...]
    const url = `${apiBase}/user/stats`;
    return GET(url);
}

const getProfileById = async (id) => {
    const url = `${apiBase}/user/find_registration/${id}`;
    return POST(url, {});
}

const changePassword = async (data) => {
    const headers = {
        'X-XSRF-Token': localStorage.getItem(Const.AUTH_TOKEN)
    }
    const url = `${apiBase}/change?include_auth_token`;
    return POST(url, data, headers);
}

const updateProfile = async (data) => {
    const url = `${apiBase}/user/update_registration`;
    return PUT(url, data);
}

const updateProfileById = async (data, user_id) => {
    const url = `${apiBase}/user/update_registration/${user_id}`;
    return PUT(url, data);
}

const updateEmployeeProfile = async (data) => {
    const url = `${apiBase}/user/employee_update_registration`;
    return PUT(url, data);
}

const disableUser = async (user_id) => {
    const url = `${apiBase}/user/disable/${user_id}`
    return PUT(url)
}


const UserAPI = {
    getProfile: getProfile,
    getProfileById,
    getSubscriptions:getSubscriptions,
    getStats:getStats,
    changePassword: changePassword,
    updateProfile: updateProfile,
    updateProfileById: updateProfileById,
    updateEmployeeProfile: updateEmployeeProfile,
    disableUser: disableUser,
}

export default UserAPI;
