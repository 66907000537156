import Row from 'react-bootstrap/Row';

const stats = require('../../assets/img/2x2_stats.svg');

const WordCompositionCh1Modal = () => {
	return (<>
        <Row className="bg-light my-2 align-items-center justify-content-center">
            <div className="col-md-4 p-3">
                <img
                    src={stats.default}
                    alt="Stats Chapter 1"
                    style={{height:"150px"}}
                />
            </div>
            <div className="col-md-5 p-3">
                <small>This is the word breakdown of the first 20 conversations of the course. Each number shows the percentage of words you’ll be exposed to based upon our analysis of 1.7 million words in modern Italian conversations.  For example, if you learned all the adverbs in these conversations, you'd understand spoken adverbs 76% of the time.</small>
            </div>
        </Row>
    </>
	)
}

export default WordCompositionCh1Modal;