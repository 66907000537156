import { GET, POST, DELETE } from './Http';

const apiBase = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : '/api';

const submit = async (payload) => {
    const url = `${apiBase}/practice/submit`;
    return POST(url, payload);
}

const serve = async () => {
    const url = `${apiBase}/practice/serve`;
    return GET(url);
}

const PracticeAPI = {
    submit,
    serve
}

export default PracticeAPI;