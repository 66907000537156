import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const stats = require('../../assets/img/2x2_stats.svg');

const MethodModal = () => {
	return (<>
        <Row className="text-center bg-blue-light-gray rounded-top-5">
            <h2 className="mt-5 fw-light">Innovative Method</h2>
        </Row>
        <Row className="mb-5 p-5 justify-content-around bg-blue-light-gray rounded-bottom-5">
            <Col xs={12} md={5} >
                <h4 className="">Learn naturally, not from flash cards or textbooks</h4>
                <p className="fs-6 fs-md-4">Your subconscious mind is incredibly powerful - it learns effortlessly. Think back on when you learned your first language:
                did you know an adjective from a noun before you were speaking fluently? Did you memorize verb tables? No way! You intuitively harnessed your 
                subconscious learning abilities.</p>

                <p className="fs-6 fs-md-4">Our course taps into your natural language learning abilities. It consists of short conversations that 
                you will work through in seven steps. These steps guide you through essential skills: listening, speaking, reading, and remembering. 
                By the end of each lesson, you play both parts of the dialogue and we give you immediate automated feedback on your pronunciation 
                (the “Capeesh-Score”). Once you’ve finished working through a lesson, you can then hop over to the practice mode. This feature 
                revisits phrases from previous lessons so you can test how well you can recall what you’ve learned. Practice mode also gives you 
                feedback on your pronunciation. The course is flexible, allowing you to learn at your own pace and revisit lessons as often as you 
                like.</p>
            </Col>
            <Col xs={12} md={5} className="align-items-start justify-content-center">
                <h4 className="">Reasons to try something new</h4>
                <h5>Pronunciation Over Perfect Grammar</h5>
                <p className="fs-6 fs-md-4"><span className="fs-5">Firstly</span>, even if you know the words and the grammar, bad pronunciation can make it very hard for native speakers to understand you.
                An understandable, yet grammatically incorrect, word is better than the right one that took 5 seconds to compute and is badly pronounced.</p>

                <h5>Practical Use Matters Most</h5>
                <p className="fs-6 fs-md-4"><span className="fs-5">Secondly</span>, if you're just getting started, it’s important not to ‘put the cart before 
                the horse’ by starting with with grammar! Although grammar can help you to find the right verb conjugation or gender agreement when writing, 
                it's rarely helpful in conversation because we're just to slow to consciously calculate this in real-time (not to mention that many Italians 
                    speak a mile a minute, and they'll change the subject before you remember the right preposition). In the end, to have a conversation you 
                "just feel it", you don't think about grammar.</p>
                
                <h5>Importance of Context in Learning</h5>
                <p className="fs-6 fs-md-4"><span className="fs-5">Thirdly</span>, reading and listening to words in isolation is not very helpful for understanding spoken conversation. You need to listen to full 
                phrases to learn how to parse words, there's just no substitute.</p>
            </Col>
        </Row>
    </>
	)
}

export default MethodModal;