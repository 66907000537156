import React from "react";
import { useParams, NavLink } from 'react-router-dom';
import Header from "../components/layout/Header";
import AuthAPI from '../utils/AuthAPI';
import Helper from '../utils/Helper';
import Const from '../utils/Constants';
import {ReactComponent as VaBene} from '../assets/icon/vabene.svg';
import {ReactComponent as WhatThe} from '../assets/icon/what-the.svg';
import { history } from "../App"

const ic_checked = require('../assets/icon/checked.png');

function PasswordReset() {
    const [inputParams, setInputParams] = React.useState({
        password: '',
        password_confirm: '',
    });
    const [counter, setCounter] = React.useState(null);
    const [stage, setStage] = React.useState('RESETPASSWORD');
    const [message, setMessage] = React.useState('');
    const { token } = useParams()

    React.useEffect(() => {
        if (counter!=null) {
            if (counter==0) {
                history.push('/login-sign-up');
            }

            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
        
    }, [counter]);

    const handleChangeInput = (e) => {
        setInputParams({...inputParams, [e.target.name]: e.target.value});
    }

    const next = () => {
        resetPassword();
    }

    const resetPassword = () => {
        localStorage.setItem(Const.AUTH_TOKEN, token);
        Helper.showSpinner();
        AuthAPI.resetPassword(token, inputParams)
            .then(res => {
                setCounter(5);
                Helper.hideSpinner();
                if (res.meta.code === 200) {
                    setStage("SUCCESS");
                }
            })
            .catch(err => {
                setCounter(5);
                setStage("FAILURE");
                Helper.hideSpinner();
                if(!err.response) {
                    Helper.showToast('error', 5000, `Caspita! Something went wrong. If it continues, please write andrew@italianaturally.com.`, '');
                    return;
                }
                const error = err.response.data;
                setMessage(error.error);
                if(error.response.status >= 500) {
                    Helper.showToast('error', 5000, error.response.statusText, error.response.status);
                    return;
                }
                if(error.meta.code === 400) {
                    if (error.response.errors) {
                        const errors = error.response.errors;
                        for (const property in errors) {
                            Helper.showToast('warning', 5000, `${errors[property]}`, error.meta.code);
                        }
                    }
                    if (error.response.error) {
                        const error_msg = error.response.error;
                        Helper.showToast('warning', 5000, error_msg, error.meta.code);
                    }
                }
            });
    }

    return (
        <div className="container-fluid">
            <Header />
            <div className="layout__container--single">
                {/* <div className="ml-auto p-4 start-account">
                    <span className="">Don't have an account? </span><span className="register" onClick={() => history.push("/auth/register")}>Get started</span>
                </div> */}
                { stage==='RESETPASSWORD' ?
                <div className="login-form">
                    <div>
                        <h4 className="text-headline mb-4">Create new password</h4>
                        <div className="form1-entry-input password">
                            <input type="password" 
                                className={inputParams.password.length > 7 ? "form-control valid" : "form-control" }
                                placeholder="New password"
                                name="password" 
                                value={inputParams.password} 
                                onChange={(e) => handleChangeInput(e)} />
                        </div>
                        <div className={`form1-entry-input password ${inputParams.password_confirm && inputParams.password_confirm !== inputParams.password && "invalid"}`}>
                            <input type="password" 
                                className={inputParams.password_confirm.length > 7 ? "form-control valid" : "form-control" }
                                placeholder="Repeat password"
                                name="password_confirm" 
                                value={inputParams.password_confirm} 
                                onChange={(e) => handleChangeInput(e)} />
                            {inputParams.password_confirm && inputParams.password_confirm !== inputParams.password && 
                                <small className="err-msg">Your password is invalid.</small>
                            }
                        </div>
                        <div className="layout__form-submit justify-content-start align-items-center mt-3">
                            <div className="loginSelf p-2">
                                <button type="button" 
                                className="background__button--access text__color--white"
                                disabled={! (inputParams.password.length > 7 && inputParams.password_confirm == inputParams.password)} 
                                onClick={() => next()}
                                >Next</button>
                            </div>                            
                        </div>
                    </div>
                </div> : 
                <div>
                    <div className="layout__container--single">
                        <div className="sticky-top layout__offset-top-80 align-items-center d-flex flex-column">
                            {stage=='SUCCESS' && <>
                                <VaBene style={{width:'200px', height:'auto'}}/>
                                <h4 className="text-title mt-4 ">Congratulations!</h4>
                                <h6 className="text-subline ">You reset your password.</h6>
                                <p>Redirecting to login in {counter} seconds.</p>
                            </>}
                            {stage=='FAILURE' && <>
                                <WhatThe style={{width:'200px', height:'auto'}}/>
                                <h4 className="text-title mt-4  ">Caspita!</h4>
                                <h6 className="text-subline  ">{message}</h6>
                                <p>Redirecting to login in {counter} seconds.</p>
                                {/*<NavLink className="button__layout--big-link pt-4 greenPrimary" to="/login-sign-up">Log In</NavLink>*/}
                            </>}
                        </div>      
                    </div>
                </div> }
                
                {/*  */}
            </div>
        </div>
    );
}

export default PasswordReset;