import match from "conditional-expression";
import AuthAPI from "../utils/AuthAPI";
import UserAPI from "../utils/UserAPI";
import SubscriptionAPI from "../utils/SubscriptionAPI";
import Helper from "../utils/Helper";
import { history } from "../App"

export const applyMiddleware = (dispatch) => (action) =>
    dispatch(action) ||
    match(action.type)
        .equals("SUBSCRIBE")
        .then(() => {
            SubscriptionAPI.subscribe(action.payload)
                .then(res => {
                    if (res.success) {
                        Helper.showToast('success', 3000, 'You successfully subscribed! As you get started, write andrew@italianaturally.com with any questions :)', '');
                        window.location.reload();
                    } else {
                        Helper.showToast('error', 5000, res.error, '');
                    }
                })
                .catch(err => {
                    Helper.readError(err.response);
                })
        })
        .equals("CANCEL_SUBSCRIPTION")
        .then(() => {
            SubscriptionAPI.cancelSubscription()
                .then(res => {
                    if (res.success) {
                        Helper.showToast('success', 3000, 'You successfully unsubscribed. We\'re sorry to see you go :(', '');
                        window.location.reload();
                    } else {
                        Helper.showToast('error', 5000, res.error, '');
                    }
                })
                .catch(err => {
                    Helper.readError(err.response);
                })
        })
        .equals("GOOGLE_LOGIN_REQUEST")
        .then(() => {
            AuthAPI.loginGoogle(action.payload)
                .then(res => {
                    
                    if (res.meta.code === 200) {
                        console.log(res.response);
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: {
                                data: {
                                    ...res.response,
                                }
                            },
                        });
                        history.push('/conversations');
                    }
                })
                .catch(err => {
                    
                    Helper.readError(err.response);
                });
        })
        .equals("REGISTER_GOOGLE_REQUEST")
        .then(() => {
            AuthAPI.registerGoogle(action.payload)
                .then(res => {
                    
                    if (res.meta.code === 200) {
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: {
                                data: {
                                    ...res.response,
                                }
                            },
                        });
                        history.push('/conversations');
                    }
                })
                .catch(err => {
                    
                    Helper.readError(err.response);
                });
        })
        .equals("REGISTER_REQUEST")
        .then(() => {
            AuthAPI.register(action.payload)
                .then(res => {
                    
                    if (res.meta.code === 200) {
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: {
                                data: {
                                    ...res.response,
                                    email: action.payload.email,
                                    password: action.payload.password,
                                }
                            },
                        });
                        localStorage.removeItem("password")
                        localStorage.removeItem("email")
                        history.push('/login-sign-up#notice-confirm-email');
                    }
                })
                .catch(err => {
                    
                    Helper.readError(err.response);
                });
        })
        .equals("LOGIN_REQUEST")
        .then(() => {
            AuthAPI.login(action.payload)
                .then(res => {
                    
                    if (res.meta.code === 200) {
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: {
                                data: {
                                    ...res.response,
                                    email: action.payload.email,
                                    password: action.payload.password,
                                }
                            },
                        });
                        localStorage.removeItem("password")
                        localStorage.removeItem("email")
                        history.push('/');
                    }
                })
                .catch(err => {
                    
                    Helper.readError(err.response);
                    console.log(err);
                    try {
                        if (err.response.status==403) history.push('/auth/request-confirmation-email');
                    } catch {
                        console.log('check error above')
                    }
                    
                });
        })
        .equals("EMAIL_CONFIRM_LOGIN_REQUEST")
        .then(() => {
            AuthAPI.confirmEmail(action.payload)
                .then(res => {
                    
                    if (res.meta.code === 200) {
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payload: {
                                data: {
                                    ...res.response
                                }
                            },
                        });
                    }
                })
                .catch(err => {
                    
                    Helper.readError(err.response);
                });
        })
        .equals("RECOVER_ACCOUNT")
        .then(() => {
            AuthAPI.sendRecoveryEmail(action.payload)
                .then(res => {
                    
                    if (res.meta.code === 200) {
                        history.push('/login-sign-up#notice-reset-password');
                    }
                })
                .catch(err => {
                    
                    Helper.readError(err.response);
                });
        })
        .equals("LOGOUT_REQUEST_SILENT")
        .then(() => {
            AuthAPI.logout()
                .then(res => {
                    
                    if (res.meta.code === 200) {
                        dispatch({
                            type: "LOGOUT_SUCCESS",
                            payload: {},
                        });
                    }
                })
                .catch(err => {
                    
                    Helper.readError(err.response);
                });
        })
        .equals("LOGOUT_REQUEST")
        .then(() => {
            AuthAPI.logout()
                .then(res => {
                    
                    if (res.meta.code === 200) {
                        dispatch({
                            type: "LOGOUT_SUCCESS",
                            payload: {},
                        });
                        history.push('/login-sign-up');
                    }
                })
                .catch(err => {
                    
                    Helper.readError(err.response);
                });
        })
        .equals("GET_USER_REQUEST")
        .then(() => {
            // andrew add functionality to save user info, like email and photo and levels owned and completed
            // UserAPI.getProfile(action.payload)
            //     .then(res => {
            //         
            //         if (res.data) {
            //             dispatch({
            //                 type: "GET_USER_SUCCESS",
            //                 payload: { data: res.data },
            //             });
            //         }
            //     })
            //     .catch(err => {
            //         
            //         Helper.readError(err.response);
            //     });
        })
        .else(null);
