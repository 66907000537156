import React, { useEffect, useRef } from "react";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import SubscriptionAPI from "../../utils/SubscriptionAPI";
import Helper from "../../utils/Helper";

const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
    className={`w-100 p-2 my-2 stripe-checkout-button text__inter text__inter--16 fw-normal text__color--white ${error ? "stripe-checkout-button--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
    >
    {processing ? "Processing..." : children}
    </button>
);

const CheckoutForm = (props) => {
  const { buttonText='Start talking with 7 days free!' } = props;
  const [processing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setProcessing(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setProcessing(false)
      return;
    }

    // redirects like https://localhost:5000/api/stripe_subscription/confirm_intent_and_subscribe?setup_intent=seti_1LMl4eDSz6pyl0hWoMfGNTfl&setup_intent_client_secret=seti_1LMl4eDSz6pyl0hWoMfGNTfl_secret_M4uuGdezybvZuHJU77hREcxAfNaTV76&redirect_status=succeeded
    stripe.confirmSetup(
      {
        elements,
        confirmParams: {
          // Return URL where the customer should be redirected after the SetupIntent is confirmed.
          return_url: process.env.REACT_APP_BASE_URL + '/inital-configure-subscriptions',
        },
      }
    ).then(function(result) {
      if (result.error) {
        console.log('here1');
        console.log(result.error);
        Helper.showToast('warning', 3000, result.error.message, 'Error while checking card');
        setProcessing(false);
      } 
    }).catch(err=> {
      console.log('here3');
      console.log(err);
      Helper.readError(err);
      setProcessing(false);
    })

    // const result = await stripe.confirmPayment({
    //   //`Elements` instance that was used to create the Payment Element
    //   elements,
    //   confirmParams: {
    //     return_url: "https://example.com/order/123/complete",
    //   },
    // });

/*    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }*/
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement 
        options={{terms:{card:'never'}}}
      />
      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        {buttonText}
      </SubmitButton>
    </form>
  );
};

export default CheckoutForm;