import toast from 'react-hot-toast';

import { get } from "lodash";

const browserCompatibilityAudio = () => {
    var elem = new Audio();
    var bool = false;
    try {
        bool = elem.canPlayType('audio/ogg; codecs="vorbis"').replace(/^no$/, '');
    } catch (e) {console.log(e);}

    return bool //? '✓' : 'X';

    // elem.canPlayType('audio/ogg; codecs="vorbis"').replace(/^no$/, '');
    // elem.canPlayType('audio/mpeg; codecs="mp3"').replace(/^no$/, '');
    // elem.canPlayType('audio/ogg; codecs="opus"') ||
    //       elem.canPlayType('audio/webm; codecs="opus"').replace(/^no$/, '');
    // elem.canPlayType('audio/wav; codecs="1"').replace(/^no$/, '')
    // (elem.canPlayType('audio/x-m4a;') ||
    //       elem.canPlayType('audio/aac;')).replace(/^no$/, '')

}

const scrollToRef = (ref) => {
    let y = ref.current.getBoundingClientRect().top + window.pageYOffset - document.getElementById("pageHeader").getBoundingClientRect().height - 20;
    window.scrollTo({top:y, behavior:'smooth'});
}

const browserCompatibilityRecorder = () => {

    return typeof MediaRecorder !== "undefined" //? '✓' : 'X';

}

const formatPrice = (string) => {
    return '$'+Math.floor(Number(string)).toString(10);
}

const alphaLowerCaseCharactersOnly = (string) => {
  const lowercaseOnly = /^[a-z]+$/g;
  return lowercaseOnly.test(string);
}

const isAdmin = (role) => {
    return ['org_super_admin', 'org_admin', 'de_super_admin'].includes(role)
}

const validPhoneNumber = (phone) => {
    var patt = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
    return patt.test(phone);
}

const validMail = (mail) => {
    var patt = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/im)
    return patt.test(mail);
}

const getErrors = (err) => {
    const message = []
    if (err?.response?.data?.response?.errors) {
        const errors = err?.response?.data?.response?.errors
        for (const key in errors) {
            if (errors[key]?.length)
            message.push(...errors[key]);
        }
    }
    return message.join('. ')
}

const getSubdomain = () => {
    const host = window.location.host;
    const parts = host.split(".");
    if (parts.length >= 3 || parts[1] === 'localhost:3000') {
        if (parts[0] === "www") {
            return ''
        }
        return parts[0]
    }
    return ''
}

const getHost = () => {
    const host = window.location.host;

    const parts = host.split(".");
    let newHost = ''
    if (parts.length >= 3) {
        newHost = `${parts[1]}.${parts[2]}`
    } else if (parts[1] === 'localhost:3000') {
        newHost = parts[1]
    } else if (parts.length === 2) {
        newHost = `${parts[0]}.${parts[1]}`
    } else {
        newHost = parts[0]
    }
    return newHost;
}

const getRandomBootstrapTheme = () => {
    const themes = [
        'orange',
        'secondary',
        'primary',
        'info',
        'warning',
        'danger',
        'success',
        'light'];

    return themes[Math.floor(Math.random() * themes.length)]
}

const getUsername = (user) => {
    if (!user) return ''

    if (!get(user, "fname")) return ''
    return `${get(user, "fname")} ${get(user, "lname")}`
}

const showToast = (type, time, message, title = '') => {
    if (message!=null && message!='') {
        switch (type) {
            case 'info':
                toast(message, {
                  icon: '👁️',
                });
                // NotificationManager.info(message, title, time);
                break;
            case 'success':
                toast.success(message);
                // NotificationManager.success(message, title, time);
                break;
            case 'warning':
                toast(message, {
                  icon: '📯',
                });
                //NotificationManager.warning(message, title, time);
                break;
            case 'error':
                toast.error(message);
                //NotificationManager.error(message, title, time);
                //   NotificationManager.error(message, title, time, () => {
                //     alert('callback');
                //   });
              break;
            default: break;
        }
    }
}

const getRandomXsValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const readError = (err) => {

    try {
        showToast('error', 3000, err.response.data.error, '');
        console.log(err);
        console.log('dingleberry');
        return;

    } catch {

        if (!(err && err.status)) {
            showToast('error', 3000, `Something went wrong. If it continues, please write andrew@italianaturally.com`, '');
            return;
        }
        if (err.status >= 500) {
            showToast('error', 3000, `Something went wrong. If it continues, please write andrew@italianaturally.com`, '');
        } else {
            if (err.data && err.data.error) {
                showToast('warning', 3000, err.data.error, '');
                return;
            }
            if (err.data && err.data.errors) {
                const errors = err.data.errors;
                for (const property in errors) {
                    showToast('warning', 3000, `${errors[property]}`, '');
                }
            }
            if (err.data && err.data.response) {
                if (err.data.response.errors) {
                    const errors = err.data.response.errors;
                    for (const property in errors) {
                        showToast('warning', 3000, `${errors[property]}`, '');
                    }
                }
            }
        }
    }
}

const trimLeadingTrailingWhiteSpaces = (objectParam) => {
    let newParam = {};
    for (let key in objectParam) {
        newParam[key] = (typeof objectParam[key] === 'string') ? objectParam[key].trim() : objectParam[key];
    }
    return newParam;
}



export {
    showToast,
    readError,
    trimLeadingTrailingWhiteSpaces,
    getRandomBootstrapTheme,
    getRandomXsValue,
    scrollToRef
    // 

};

const Helper = {

    formatPrice,
    browserCompatibilityRecorder,
    browserCompatibilityAudio,
    alphaLowerCaseCharactersOnly,
    showToast,
    readError,
    trimLeadingTrailingWhiteSpaces,
    // 
    
    getSubdomain,
    getHost,
    getUsername,
    isAdmin,

    validPhoneNumber,
    validMail,
    getErrors
}

export default Helper;