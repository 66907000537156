import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(
	<GoogleOAuthProvider clientId='71680960706-3vre4iushtpejgv11c256e7st6irlf7f.apps.googleusercontent.com'>
		<App />
	</GoogleOAuthProvider>
);

serviceWorkerRegistration.register();

reportWebVitals();