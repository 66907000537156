import React from "react";
import Header from "../components/layout/Header";
import {ReactComponent as Chapter1Stats} from '../assets/img/chapter_1_stats.svg'
import { history } from "../App"
import {Helmet} from "react-helmet";

function Analysis() {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ItaliaNaturally - Word Analysis</title>
                <meta name="description" content="There are 393 verbs more important than nuotare (to swim) in spoken Italian. Learn the right ones with our course! It's like immersion, but faster." />
            </Helmet>
            <Header isLogoCenter='true' />
            <div className="container analysys">
                <div className="row py-1 py-lg-2">
                    
                    <div className="pt-0 pt-lg-0 flex-column align-items-stretch">
                        <p><span role='button' onClick={() => history.push("/")}>return to <u>home</u></span></p>
                        <div>
                            <h1 className="text__lora text__lora-blog--title">The great thing about immersion</h1>
                            <p className="text__lora text__lora-blog--article">is that it's a perfectly designed classroom for learning to speak a language. Repetition of words and phrases that you need to learn happens at exactly the frequency that people use them - it's obvious, but it's also not obvious.</p>

                            <p className="text__lora text__lora-blog--article">When you study a language in school, or from a textbook, you'll march through basic seeming words. Like "to swim". Pretty basic verb, right? Probably should know that one, right?</p>

                            <p className="text__lora text__lora-blog--article">Not really! We ran an analysis of more than 1.7M words used in Italian subtitles, and, as far as verbs go, <span className='bold'>there are 392 other verbs used more frequently than nuotare (to swim).</span></p>

                            <p className="text__lora text__lora-blog--article">We have some good news, though! Only 74 verbs accounted for 80% of the verbs used in our analysis. </p>
                        </div>
                        <div className="my-4 d-flex justify-content-center"><Chapter1Stats width="auto" height="80px" /></div>
                        <div>

                                <p className="text__lora text__lora-blog--article">Put another way, <span className='bold'>if you learn the right 74 verbs, you'll understand spoken verbs about 80% of the time!</span> We built the course with this in mind:</p>
                                <p className="text__lora text__lora-blog--article">This is the breakdown of Chapter 1 alone (the first 20 conversations of the main course).  For example, if you learned all the adverbs in these conversations, you'd understand spoken adverbs 76% of the time.</p>
                        </div>
                        

                        <div>
                            <p className="text__lora text__lora-blog--article">We didn't stop at single words, we also looked at 2, 3 and 4 word combinations.</p>
                        </div>
                    </div>
                </div>
                <div className="row py-1 py-lg-2">
                    <div className="pt-0 pt-lg-0 flex-column align-items-stretch">
                        <div>
                            <h4 className="text__lora text__lora-blog--title">A bit more about our analysis</h4>
                        </div>
                    </div>
                </div>  
                <div className="row py-4 align-items-start justify-content-center layout__drop-shadow about_analysis">
                    <div className="col-lg-3 pb-3">
                        <div className='fw-semibold text__inter  my-2'>We analyzed 1.7M words in speech*</div>
                        <small>*modern-day movies and television scripts</small>
                    </div>
                    <div className='col-lg-9 d-flex flex-column'>
                        <div className="analysis_point analysis__img-selective">
                            <div className="text__inter text__inter--16my-2">Learn the words you need most</div>
                            <div className='text__inter para'>The first 20 conversations of the Main Course include over 57% of the words used in all of the subtitles we analyzed.</div>
                        </div>
                        
                        <div className="analysis_point analysis__img-immersion">
                            <div className="text__inter text__inter--16my-2">Emulate focused immersion</div>
                            <div className='text__inter para'>We also looked for repeated 2, 3 and 4 word phrases. This way you learn expressions and tenses used most frequently,
                            without memorizing tables.</div>
                        </div>
                        <div className="analysis_point analysys__img-puzzle">
                            <div className="text__inter text__inter--16my-2">We broke it down by part of speech</div>
                            <div className='text__inter para my-1'>You may be surprised by what we discovered:</div>
                            <div className='text__inter para my-1'>
                                <div className="analysis_point_li">74 verbs make up 80% of those spoken</div>
                                <div className="analysis_point_li">92 adverbs make up 98%</div>
                                <div className="analysis_point_li">152 nouns make up 50%</div>
                                <div className="analysis_point_li">114 adjectives make up 80%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row py-0 py-lg-1 cell-splash my-4">
                    <div className="pt-0 pt-lg-0 flex-column align-items-stretch">
                        <div className="d-flex flex-row justify-content-center m-4">
                            <button className="buttonTryIt text__inter fw-normal layout__drop-shadow p-3" onClick={() => history.push("/login-sign-up")}>Continue to the Course!</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Analysis;