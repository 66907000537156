import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import AuthAPI from "../../utils/AuthAPI";
import SubscriptionAPI from "../../utils/SubscriptionAPI";
import Helper from "../../utils/Helper";
import GLogin from '../GoogleOAUTH/GLogin';
import { AppContext } from "../../App";
import StripePaymentForm from "../StripePaymentForm";
import { ReactComponent as VaBene } from '../../assets/icon/vabene.svg';
import BounceLoader from "react-spinners/BounceLoader";
import { history } from '../../App';
import useModal from '../../components/Modal/useModal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import './register.scss'

const override = {
  position: "absolute",
  top: "calc(50% - 30px)",
  left: "calc(50% - 30px)",
};

const Register = (props) => {
  const { page = 'splash', startStep = 1, clientSecret = null, family = false } = props;
  const { dispatch, state } = React.useContext(AppContext);
  const [step, setStep] = React.useState(startStep);
  const [processing, setProcessing] = React.useState(false);
  const [stripeClientSecret, setStripeClientSecret] = React.useState(clientSecret);

  const refPwd = useRef(null);

  const { openModal, closeModal } = useModal();

  const [inputParams, setInputParams] = React.useState({
    email: '',
    password: '',
  });

  const pricingDat = {
      annual : {
          mainCourse:15,
          miniCourse:10,
          allCourse:20,
          unit:'/mo'
      },
      monthly : {
          mainCourse:25,
          miniCourse:20,
          allCourse:30,
          unit:'/mo'
      },
      forever : {
          mainCourse:120,
          miniCourse:80,
          allCourse:150,
          unit:''
      }
  }

  const [period, setPeriod] = useState('annual');

  const handleChangeInput = (e) => {
    setInputParams({ ...inputParams, [e.target.name]: e.target.value });
  }

  const handleKeyPress = (e, name) => {
    if (e.key === "Enter") {
      if (name === 'email') {
        refPwd.current.focus();
      } else {
        register();
      }
    }
  }

  // STEP 2
  const selectProduct = (type) => {
    setProcessing(true);
    if (type=='mainOnly') {
      SubscriptionAPI.createStripeIntent({ code: '1000000000', is_lifetime: period==='forever', is_annual: period==='annual', promo: null })
      .then(res => {
        setProcessing(false);
        // console.log(res.data);
        if (res.data) setStripeClientSecret(res.data.stripe_client_secret)
        setStep(3);
      }).catch(err => {
        setProcessing(false);
        // console.log(err);
      })
    } else if (type=='vacationOnly') {
      SubscriptionAPI.createStripeIntent({ code: '1100000000', is_lifetime: period==='forever', is_annual: period==='annual', promo: null })
      .then(res => {
        setProcessing(false);
        // console.log(res.data);
        if (res.data) setStripeClientSecret(res.data.stripe_client_secret)
        setStep(3);
      }).catch(err => {
        setProcessing(false);
        // console.log(err);
      })
    }
    

  }

  // STEP 1
  const register = () => {
    setProcessing(true);

    AuthAPI.register({
      ...inputParams,
    })
      .then(res => {
        // console.log(res);
        setProcessing(false);
        if (res.meta.code === 200) {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
              data: {
                ...res.response,
                email: inputParams.email,
                password: inputParams.password,
              }
            },
          });
          localStorage.removeItem("password")
          localStorage.removeItem("email")
          // console.log(family);
          if (family) { // skip other steps and immediately subscribe
            SubscriptionAPI.subscribeFamily({ email: res.response.email })
              .then(res => {
                history.push("/")
              })
              .catch(err => {
                Helper.readError(err.response);
              });
          } else if (page == 'splash') {
            closeModal();
            history.push("/pricing")
          } else {
            setStep(2);
          }

        }
      })
      .catch(err => {
        setProcessing(false);
        Helper.readError(err.response);
      });
  }

  const registerGoogle = (googleCredentials) => {
    setProcessing(true);

    AuthAPI.registerGoogle(googleCredentials)
      .then(res => {
        setProcessing(false);
        if (res.meta.code === 200) {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
              data: {
                ...res.response,
                email: res.response.email,
                password: '00000000',
              }
            },
          });
          localStorage.removeItem("password")
          localStorage.removeItem("email")
          // console.log(family);
          if (family) { // skip other steps and immediately subscribe
            SubscriptionAPI.subscribeFamily({ email: res.response.email })
              .then(res => {
                history.push("/conversations")
              })
              .catch(err => {
                Helper.readError(err.response);
              });
          } else {
            setStep(2);
          }

        }
      })
      .catch(err => {

        Helper.readError(err.response);
      });


  }

  return (
    <>
      {step == 1 ? (page == 'splash' ?
        <div className='register rounded bg-secondary px-md-5 py-md-4'>
          <BounceLoader speedMultiplier="1" size={60} cssOverride={override} loading={processing} color='#00AB67' />
          <h3 className='text__inter text__inter--24 fw-normal mb-4 ' style={{ color: 'hsl(212, 66%, 35%)' }}>Start Your Free Trial</h3>
          <div className="mt-4">Step 1: Enter your email</div>
          <div className='form1-entry-input email'>
            <input type="email"
              className={inputParams.email.length > 0 ? "form-control valid icon" : "form-control icon"}
              name="email"
              autoFocus={false}
              placeholder="Email Address"
              value={inputParams.email}
              onChange={(e) => handleChangeInput(e)}
              onKeyUp={(e) => handleKeyPress(e, 'email')}
            />
          </div>
          <div className="mt-4">Step 2: Choose a Password</div>
          <div className='form1-entry-input password mb-3'>
            <input type="password"
              ref={refPwd}
              className={inputParams.password.length > 7 ? "form-control valid icon" : "form-control icon"}
              name="password"
              placeholder="Password"
              value={inputParams.password}
              onChange={(e) => handleChangeInput(e)}
              onKeyUp={(e) => handleKeyPress(e, 'password')}
            />
          </div>
          <div className="layout__form-submit justify-content-start align-items-center my-4 ">
            <div className="pe-2">
              <Button 
                disabled={processing}
                variant="warning text-white"
                // disabled={!inputParams.email || !Helper.validMail(inputParams.email) || !inputParams.password} 
                onClick={register}>
                {!processing ? 'Register' : 'Submitting'}</Button>
            </div>
            <div className="pThin alternative-login d-flex align-items-center">
              <span style={{ paddingRight: '7px' }}>or</span> <GLogin myOnSuccess={registerGoogle} className='googleButton' register={true} buttonText='signup_with' size="medium" />
            </div>
          </div>
          <div className='d-flex flex-column align-items-start'>
            <div className='under-links'>By registering, you agree to our <Button variant="link p-0 fs-6 border-0" onClick={() => history.push('/terms')}>Terms</Button> and <Button variant="link p-0 fs-6 border-0" onClick={() => history.push('/terms')}>Privacy Policy.</Button></div>
            <div className='under-links'>You will receive 7 emails with information about the course.</div>
            <div className='under-links'>
              Already have an account? <Button variant="link p-0 fs-6 border-0" href='/login-sign-up#login'>Login Here</Button>
            </div>
          </div>
        </div>
        : page == 'register' &&
        <div className='login-form'>
          <BounceLoader speedMultiplier="1" size={60} cssOverride={override} loading={processing} color='#00AB67' />
          <h1 className="">{family ? 'Welcome Friends and Family!' : 'Start your free trial!'}</h1>
          <div className="form1-label mt-4">Step 1: Enter your email</div>
          <div className='form1-entry-input email'>
            <input type="email"
              className={inputParams.email.length > 0 ? "form-control valid icon" : "form-control icon"}
              name="email"
              autoFocus={false}
              placeholder="Email Address"
              value={inputParams.email}
              onChange={(e) => handleChangeInput(e)}
              onKeyUp={(e) => handleKeyPress(e, 'email')}
            />
          </div>
          <div className="form1-label mt-4">Step 2: Choose a Password</div>
          <div className='form1-entry-input password mb-3'>
            <input type="password"
              ref={refPwd}
              className={inputParams.password.length > 7 ? "form-control valid icon" : "form-control icon"}
              name="password"
              placeholder="Password"
              value={inputParams.password}
              onChange={(e) => handleChangeInput(e)}
              onKeyUp={(e) => handleKeyPress(e, 'password')}
            />
          </div>
          <div className="mt-4 mb-1 register__terms">
            By registering below, you agree to our <Button variant="link p-0 fs-6 border-0" onClick={() => history.push('/terms')}>Terms</Button> and <Button variant="link p-0 fs-6 border-0" onClick={() => history.push('/terms')}>Privacy Policy.</Button>.
          </div>
          <div className="my-1 register__terms">
            You will receive 7 emails with information about the course.
          </div>
          <div className="my-1 register__terms">

          </div>
          <div className="layout__form-submit justify-content-start align-items-center my-4 ">
            <div className="pe-2">
              <Button 
                disabled={processing}
                variant="warning text-white"
                // disabled={!inputParams.email || !Helper.validMail(inputParams.email) || !inputParams.password} 
                onClick={register}>
                {!processing ? 'Register' : 'Submitting'}</Button>
            </div>
            <div className="pThin alternative-login d-flex align-items-center">
              <span style={{ paddingRight: '7px' }}>or</span> <GLogin myOnSuccess={registerGoogle} className='googleButton' register={true} buttonText='signup_with' size="medium" />
            </div>
          </div>
          <div className='d-flex flex-column align-items-start'>
            <div className='under-links'>
              Already have an account? <Button variant="link p-0 fs-6 border-0" href='/login-sign-up#login'>Login Here</Button>
            </div>
            <div className='under-links'>
              Forgot <Button variant="link p-0 fs-6 border-0" href='/login-sign-up#forgot-password'>password</Button>
            </div>
          </div>
        </div>
      )
        : step == 2 ?
                  <div className="container">   
                      
                      <Row className="my-4 d-flex flex-row">
                          <h1 className="text__lora text-center">{ state.auth.token ? 'Add a subscription' : 'Pricing' }</h1>
                      </Row>

                      <Row className="my-3 text-center justify-content-center">
                          <Nav variant="underline justify-content-center" defaultActiveKey="annual" onSelect={ (evKey) => setPeriod(evKey) }>
                              <Nav.Item>
                                  <Nav.Link eventKey="annual">Annual</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                  <Nav.Link eventKey="monthly">Monthly</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                  <Nav.Link eventKey="forever">Forever</Nav.Link>
                              </Nav.Item>
                          </Nav>
                      </Row>
                      
                      <Row className="text-center justify-content-center">
                          <Col xs={12} md={4} lg={4} xl={3}>
                              <Card text='dark' bg='white' className="mb-4 rounded-3 shadow-sm">
                                <Card.Header className="py-3">
                                  <h5 className="my-0 fw-normal">Main Course</h5>
                                </Card.Header>
                                <Card.Body>
                                  <Card.Title className="fs-1">${pricingDat[period]['mainCourse']}<span className="text-body-secondary fw-light">{pricingDat[period]['unit']}</span></Card.Title>
                                  <Card.Text>
                                      Learn to speak and understand modern day, conversational Italian. Conversations driven by our extensive <Card.Link type="a" className="text-dark" onClick={() => openModal('analysis')}>word analysis</Card.Link>.
                                  </Card.Text>
                                  <ListGroup className="list-group-flush list-unstyled mt-3 mb-4">
                                      <ListGroup.Item>20 Conversations</ListGroup.Item>
                                      <ListGroup.Item>High impact words</ListGroup.Item>
                                      <ListGroup.Item>17 Unique accents</ListGroup.Item>
                                      <ListGroup.Item>1243 Words</ListGroup.Item>
                                      <ListGroup.Item>261 Lines</ListGroup.Item>
                                      <ListGroup.Item><Card.Link type="a" onClick={() => openModal('cultureCh1')}>Cultural Lessons</Card.Link></ListGroup.Item>
                                      <ListGroup.Item><Card.Link type="a" onClick={() => openModal('lessonsCh1')}>Grammar and Language Lessons</Card.Link></ListGroup.Item>
                                      <ListGroup.Item>Common expressions</ListGroup.Item>

                                      <ListGroup.Item>Unlimited practice</ListGroup.Item>
                                      <ListGroup.Item>Technical support</ListGroup.Item>

                                      <ListGroup.Item>Access to all future main course conversations</ListGroup.Item>
                                      <ListGroup.Item><Card.Link type="a">More info</Card.Link></ListGroup.Item>
                                  </ListGroup>
                                  
                                  { state.auth.token ?
                                    <Button variant="w-100 btn btn-lg btn-outline-primary" disabled={processing} onClick={() => selectProduct('main')}>Buy it now</Button>
                                  : 
                                    <Button variant="w-100 btn btn-lg btn-outline-primary" disabled={processing} onClick={() => openModal('register')}>Register for 7 Days Free</Button>
                                  }
                                </Card.Body>
                              </Card>
                          </Col>
                          <Col xs={12} md={4} lg={4} xl={3}>
                              <Card text='dark' bg='white' className="mb-4 rounded-3 shadow-sm">
                                <Card.Header className="py-3">
                                  <h5 className="my-0 fw-normal">Vacation Cramming</h5>
                                </Card.Header>
                                <Card.Body>
                                  <Card.Title className="fs-1">${pricingDat[period]['miniCourse']}<span className="text-body-secondary fw-light">{pricingDat[period]['unit']}</span></Card.Title>
                                  <Card.Text>Prepare for your upcoming trip to Italy. Conversations focused on what you'll need while you travel.</Card.Text>
                                  <ListGroup className="list-group-flush list-unstyled mt-3 mb-4">
                                      <ListGroup.Item>30 Conversations</ListGroup.Item>
                                      <ListGroup.Item>3 Unique accents</ListGroup.Item>
                                      <ListGroup.Item><Card.Link onClick={() => openModal('travelScenarios')}>Countless Traveling Scenarios</Card.Link></ListGroup.Item>
                                      <ListGroup.Item>XX Words</ListGroup.Item>
                                      <ListGroup.Item>XX Lines</ListGroup.Item>
                                      <ListGroup.Item>Unlimited practice</ListGroup.Item>
                                      <ListGroup.Item>Technical support</ListGroup.Item>
                                  </ListGroup>
                                  { state.auth.token ?
                                    <Button variant="w-100 btn btn-lg btn-outline-primary" disabled={processing} onClick={() => selectProduct('vacationOnly')}>Buy it now</Button>
                                  : 
                                    <Button variant="w-100 btn btn-lg btn-outline-primary" disabled={processing} onClick={() => openModal('register')}>Register for 7 Days Free</Button>
                                  }
                                </Card.Body>
                              </Card>
                          </Col>
                          <Col xs={12} md={4} lg={4} xl={3}>
                              <Card border="primary" className="mb-4 rounded-3 shadow-sm border-primary">
                                <Card.Header className="py-3 text-bg-primary border-primary">
                                  <h5 className="my-0 fw-normal">Full Access</h5>
                                </Card.Header>
                                <Card.Body>
                                  <Card.Title className="fs-1">${pricingDat[period]['allCourse']}<span className="text-body-secondary fw-light">{pricingDat[period]['unit']}</span></Card.Title>
                                  <ListGroup className="list-group-flush list-unstyled mt-3 mb-4">
                                      <ListGroup.Item>55+ Conversations</ListGroup.Item>
                                      <ListGroup.Item>High impact words</ListGroup.Item>
                                      <ListGroup.Item>17+ Actors</ListGroup.Item>
                                      <ListGroup.Item>XX+ Words</ListGroup.Item>
                                      <ListGroup.Item>XX+ Lines</ListGroup.Item>
                                      <ListGroup.Item>Unlimited practice</ListGroup.Item>
                                      <ListGroup.Item>Technical support</ListGroup.Item>
                                      <ListGroup.Item>Access to all future main course conversations</ListGroup.Item>
                                      <ListGroup.Item>Access to all future mini course conversations</ListGroup.Item>
                                  </ListGroup>
                                  { state.auth.token ?
                                    <Button variant="w-100 btn btn-lg btn-outline-primary" disabled={processing} onClick={() => selectProduct('full')}>Buy it now</Button>
                                  : 
                                    <Button variant="w-100 btn btn-lg btn-outline-primary" disabled={processing} onClick={() => openModal('register')}>Register for 7 Days Free</Button>
                                  }
                                </Card.Body>
                              </Card>
                          </Col>
                      </Row>
                      <Row className="p-4 d-flex flex-row">
                          <h5 className="text-center">or try it for free</h5>
                      </Row>
                      <Row className="text-center justify-content-center">
                          <Col xs={12} md={4} lg={4} xl={3}>
                              <Card text='dark' bg='white' className="mb-4 rounded-3 shadow-sm">
                                <Card.Header className="py-3">
                                  <h5 className="my-0 fw-normal">Free</h5>
                                </Card.Header>
                                <Card.Body>
                                  <Card.Title className="fs-1">$0<span className="text-body-secondary fw-light">/mo</span></Card.Title>
                                  <ListGroup className="list-group-flush list-unstyled mt-3 mb-4">
                                      <ListGroup.Item>3 Conversations</ListGroup.Item>
                                      <ListGroup.Item>245 Words</ListGroup.Item>
                                      <ListGroup.Item>45 Lines</ListGroup.Item>
                                  </ListGroup>
                                  { state.auth.token ?
                                    <Button variant="w-100 btn btn-lg btn-outline-primary" href="/conversations">Continue to the conversations</Button>
                                  : 
                                    <Button variant="w-100 btn btn-lg btn-outline-primary" disabled={processing} onClick={() => openModal('register')}>Register</Button>
                                  }
                                </Card.Body>
                              </Card>
                          </Col>
                      </Row>
                  </div>
        : step == 2 ?
          <div className={`login-form ${page == 'splash' && 'pt-md-4 pl-md-5 pr-md-5 pb-md-4 layout__drop-shadow'}`}>
            <BounceLoader speedMultiplier="1" size={60} cssOverride={override} loading={processing} color='#00AB67' />
            <div>
              <h5 className="">Get full access!</h5>
              {/*<div className='d-flex flex-row align-items-center'><p>Everyone gets a week free. <VaBene style={{paddingLeft:'15px',height:'45px', width:'auto'}}/></p></div>*/}
              <div className="subtext text__color--light ">30-day money back guarantee, cancel anytime</div>
            </div>
            <div className='d-flex flex-column flex-md-row justify-content-around my-3'>
              <div className='register__card-subscription'>
                <small className="register__card-tag pThin">ANNUAL - 20% OFF</small>
                <div className='text__inter text__inter--16 fw-normal'>7-days free</div>
                <small className="inter">Then <strike>$108</strike> $85 / year</small>
                <ul>
                  <li>Free access to the main course for 7 days</li>
                  <li>Works on all modern devices</li>
                  <li>Change or cancel anytime</li>
                  <li>30-day money back guarantee</li>
                </ul>
                <button disabled={processing} onClick={() => selectProduct('full')} style={{ width: '100%', backgroundColor: 'hsl(212, 50%, 65%)', border: '4px solid hsl(210,100%,90%)', color: 'white' }} className='fw-semibold text__inter text__inter--18'>Free Trial</button>
              </div>
              <div className='register__card-subscription'>
                <small className="register__card-tag pThin">MONTHLY</small>
                <div className='text__inter text__inter--16 fw-normal'>7-days free</div>
                <small className="inter">Then $9 / month</small>
                <ul>
                  <li>Free access to the main course for 7 days</li>
                  <li>Works on all modern devices</li>
                  <li>Change or cancel anytime</li>
                  <li>30-day money back guarantee</li>
                </ul>
                <button disabled={processing} onClick={() => selectProduct(false)} style={{ width: '100%', backgroundColor: 'hsl(152,66%,45%)', border: '4px solid hsl(152,50%,75%)', color: 'white' }} className='fw-semibold text__inter text__inter--18'>Free Trial</button>
              </div>
            </div>
            <Row className='justify-content-center'>
              or <Button variant='primary' className="my-2" href="/">Continue with free access</Button>
            </Row>
          </div>
        :
          <div className={`login-form ${page == 'splash' && 'pt-md-4 pl-md-5 pr-md-5 pb-md-4 layout__drop-shadow'}`}>
            <StripePaymentForm stripeClientSecret={stripeClientSecret} />
            <div className="under-links">You will not be charged until after the trial period ends</div>
            <div className="under-links">Payments are handled by Stripe.com - we never see or store your payment information</div>
            <div className="under-links">Cancel anytime</div>
            <div className="under-links">30-day refund policy, no questions asked</div>
          </div>
      }
    </>
  );
}

export default Register;