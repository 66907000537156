import React, { useState, useEffect } from 'react';
import { AppContext } from "../../App"
import { scrollToRef } from '../../utils/Helper';
import GLogout from '../GoogleOAUTH/GLogout';
import './layout.scss';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import {ReactComponent as NoMic} from '../../assets/icon/mutedMic.svg'
import {ReactComponent as Mic} from '../../assets/icon/mic.svg'
import {ReactComponent as Capeesh} from '../../assets/icon/what-the-settings.svg'
import {ReactComponent as CapeeshOff} from '../../assets/icon/what-the-off.svg'
import {ReactComponent as Playback} from '../../assets/icon/playback.svg'
import {ReactComponent as NoPlayback} from '../../assets/icon/noplayback.svg'

const Settings = (props) => {
    const {
        playback_disabled=true, 
        capeesh_disabled=false, 
        capeeshDisableCallback=null,
        showRecorderSettings=false} = props;
    const { dispatch, state } = React.useContext(AppContext);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logout = () => {
        dispatch({
            type: "LOGOUT_REQUEST",
            payload: {},
        });
    }

    const openModal = (type) => {
        dispatch({ type: 'OPEN_MODAL', payload:{type:type} });
    }

    const [micPermission, setMicPermission] = useState(null); // null, 'granted', 'denied', or 'prompt'

    useEffect(() => {
        // Check the initial permission status when the component mounts
        const checkPermissionStatus = async () => {
          try {
            const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
            setMicPermission(permissionStatus.state);

            // Update state when the permission status changes
            permissionStatus.onchange = () => {
              setMicPermission(permissionStatus.state);
            };
          } catch (error) {
            console.error('Error checking microphone permission:', error);
          }
        };

        checkPermissionStatus();
    }, []);

    const requestMicrophoneAccess = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          console.log('Microphone access granted');
          setMicPermission('granted');
          // Use the microphone stream as needed
        } catch (error) {
          console.error('Error accessing the microphone:', error);
          setMicPermission('denied');
        }
    };

    return (
        <>
            <Button variant="link w-auto" onClick={handleShow}>
                Settings
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                placement='end'
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Settings</Offcanvas.Title>
                </Offcanvas.Header>
                <ListGroup as="ol">
                    { showRecorderSettings &&
                        <>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Microphone access</div>
                                    {micPermission === 'denied' && (<>
                                          Microphone access has been denied, so you cannot listen back to yourself or get pronunciation feedback. Please enable the microphone
                                          permission in your browser settings, or use the course with this functionality.
                                    </>)}
                                    { micPermission !== 'granted' && micPermission !== 'denied' &&
                                        <>
                                        <p>We do not have access to your microphone (request access or use without the ability to listen back to yourself and get pronunciation feedback)!</p>
                                        <Button size="sm" onClick={requestMicrophoneAccess}>Request Microphone Access</Button>
                                        </>
                                    }
                                    {micPermission === 'granted' &&
                                       ('Your microphone is connected.')
                                    }
                                </div>
                                <Badge bg="primary" pill>
                                    {micPermission !== 'granted' ? <NoMic style={{height:'25px', width:'auto', fill:'#FFFFFF'}}/> : <Mic style={{height:'25px', width:'auto', fill:'#FFFFFF'}}/>}
                                </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Playback your speech</div>
                                    {playback_disabled ? 'This device does not support recording of your speech.' : 'You can record and playback your speech.'}
                                </div>
                                <Badge bg="primary" pill>
                                    {playback_disabled ? <NoPlayback style={{height:'25px', width:'auto', stroke:'#FFFFFF'}}/> : <Playback style={{height:'25px', width:'auto', stroke:'#FFFFFF'}}/>}
                                </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                                action
                                onClick={!(micPermission !== 'granted' | playback_disabled) && (() => capeeshDisableCallback(!capeesh_disabled))}
                            >
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Capeesh score: AI Pronunciation Feedback</div>
                                    {micPermission !== 'granted' | playback_disabled ? 'Capeesh score unavailable without mic or recording.' : (capeesh_disabled ? 'Click to enable Capeesh scores' : 'Click to disable Capeesh scores')}
                                </div>
                                <Badge bg="primary" pill>
                                    {micPermission !== 'granted' | playback_disabled ? <CapeeshOff style={{height:'25px', width:'auto', stroke:'#ffffff'}} /> : (capeesh_disabled ? <CapeeshOff style={{height:'25px', width:'auto', stroke:'#ffffff'}} /> : <Capeesh style={{height:'25px', width:'auto', stroke:'#FFFFFF'}} />)}
                                </Badge>
                            </ListGroup.Item>
                        </>
                    }
                    <ListGroup.Item action as="li" href="/account">My Account</ListGroup.Item>
                    {state.auth.auth.googleID ?
                        <ListGroup.Item>
                            <GLogout
                                 className="button__layout--ghost in-nav-link header__ul-buttons" 
                                 profilePic={<img className="header__profile-image" 
                                 src={`${state.auth.auth.googleImageURL}`} />} 
                                 onLogoutSuccess={logout} 
                             />
                        </ListGroup.Item>
                    :
                        <ListGroup.Item action as="li" onClick={logout}>Logout</ListGroup.Item>
                    }
                </ListGroup>
            </Offcanvas>
        </>
    );
}

export default Settings;