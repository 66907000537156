import React from 'react';
// import { GoogleLogout } from 'react-google-login';
import { googleLogout } from '@react-oauth/google';

const clientId =
  '71680960706-3vre4iushtpejgv11c256e7st6irlf7f.apps.googleusercontent.com';

function GLogout(props) {
  
  const onClick = () => {
    googleLogout();
  }

  // const onSuccess = () => {
  //   // logout from our system too
  //   // console.log('Logout made successfully');
  //   // alert('Logout made successfully ✌');
  // };

  return (
    <div>
      <button className={props.className} onClick={onClick}> {props.profilePic} Logout</button>
{/*      <GoogleLogout
        clientId={clientId}
        
        render={renderProps => (
          <button className={props.className} onClick={renderProps.onClick}> {props.profilePic} Logout</button>
        )}
        buttonText="Logout"
        icon={false}
        onLogoutSuccess={props.onLogoutSuccess}
      ></GoogleLogout>*/}
    </div>
  );
}

export default GLogout;