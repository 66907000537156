import axios from 'axios';
import Helper from './utils/Helper';
import { history } from './App';
import Const from './utils/Constants';

class AxiosConfig {
    static init() {
        axios.interceptors.response.use((response) => {
            return response;
        }, function (error) {
            // Do something with response error
            console.dir(error);
            if (error.response.status === 401) {
                localStorage.removeItem(Const.AUTH_TOKEN);
                localStorage.removeItem(Const.USER_EMAIL);
                localStorage.removeItem(Const.GIMAGE_URL);
                localStorage.removeItem(Const.GID);
                history.push(`/login-sign-up?redirectTo=${window.location.pathname}`)                    
            } else if (error.response.status === 403) {
                history.push(`/account`)                    
            }
            return Promise.reject(error);
        });
    }
}

export default AxiosConfig;
