import React from "react";
import { AppContext } from "../../App";
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import NavFooter from '../../components/layout/NavFooter'
import { Modal } from 'react-responsive-modal';
import DealColor from '../../components/DealColor'
import './main.scss'
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const courseDat = [
    {   
        title:'About the course',
        faqs : [
            {
                title:'What is Italian Naturally, exactly?',
                body:`Italian Naturally is an innovative online Italian language course consisting of 20 progressive lessons. It's 
                    based on a unique approach that analyzes over 1.7 million Italian words taken from speech to focus on the most 
                    commonly used words and phrases. The course emphasizes practical, conversational learning through natural dialogue 
                    and contextual learning, allowing you to become conversational in Italian more quickly and effectively.`
            },
            {
                title:'How is the course structured?',
                body:`The 20 lessons of the course each consist of a short conversation that you will work through in 7 steps. The 
                    steps will walk you through listening, speaking, reading, and remembering. By the end of each lesson, you play 
                    both parts of the dialogue and we give you immediate automated feedback on your pronunciation (the "Capeesh-Score"). 
                    Once you've finished working through a lesson, you can then hop over to the practice mode. Practice mode deals 
                    phrases from conversations that you've completed so you can test how well you can recall what you've learned. 
                    Practice mode also gives you feedback on your pronunciation. The course is flexible, allowing you to learn at your 
                    own pace and revisit lessons as often as you like.`
            },
            {
                title:'How long does it take to complete the Italian Naturally course?',
                body:`The completion time varies depending on your learning pace and the time you dedicate to the course. Typically, we
                    recommend that you spend around 20 minutes daily to ensure you see progress. Learners can complete the course within 
                    several months if they study regularly. However, Italian Naturally is designed to accommodate your schedule, allowing 
                    you to learn at a pace that suits you.`
            },
            {
                title:'Is Italian Naturally suitable for absolute beginners?',
                body:`Yes, Italian Naturally is great for beginners as it emulates learning a language through immersion! Of course you 
                    won't understand everything at first, but that is normal. The course starts with simpler conversations and gradually 
                    builds up to more complex conversations. Even if you have no prior knowledge of Italian, you'll find the course 
                    approachable and effective.`
            },
            {
                title:'Can I still benefit from Italian Naturally if I already know some Italian?',
                body:`Absolutely! The course is well adapted for entry to mid-level learners. Those who already know some Italian might 
                    understand of the conversation immediately, but will benefit from practicing speaking together with, and at the pace of, 
                    native speakers. This way you can expect to  improve your fluency, pronunciation, and understanding of the language.`
            },
            {
                title:'Will I learn grammar?',
                body:`Italian Naturally adopts a natural language immersion approach, similar to how you learned your first language. 
                    Grammar is absorbed subconsciously rather than through memorization of rules and tables. While explicit grammar 
                    instruction is minimal, each conversation includes succinct cultural and language notes. The first chapter includes 
                    brief, A1-level grammar overviews to aid understanding, but these are not comprehensive. They're designed to 
                    complement your learning journey, encouraging further exploration beyond the course.`
            },
            {
                title:'What will my skill level be upon completion of the course?',
                body:`Generally, once you complete all 20 lessons of Italian Naturally, learners can expect to achieve a conversational 
                    level, equivalent to a B1 level on the Common European Framework of Reference for Languages (CEFR). This means you'll 
                    be able to understand and use everyday Italian, express thoughts on familiar topics, and handle most situations you 
                    might encounter while traveling or living in Italy. There is no magic bullet in language learning (as some courses 
                    would have you believe), but our method will be among the most, if not the most, beneficial of those you will try!`
            }
        ]
    },
    {
        title:'Free Trial, Login, Payment, and Subscriptions',
        faqs : [
            {
                title:"Can I try Italian Naturally for Free?",
                body: `Yes, absolutely! We offer free access to the first lesson of Italian Naturally, allowing you to experience our unique 
                    learning method firsthand. Simply register here to start your Italian learning journey. No credit card information is 
                    required for this free trial. It's a great opportunity to see how our course fits your learning style and goals before 
                    committing to the full program.`
            },
            {
                title:"Are my details safe? Will you sell my personal information, or sign me up for random email lists?",
                body: `Your security and privacy are very important to us. We will never share any information with anyone.
                    If you decide to sign up for a subscription, we don't collect any personal information apart from your 
                    email, which is necessary to save your progress in the course and manage your settings.`
            },
            {
                title:"How can I access my free lesson?",
                body: `To access the first conversation, you must create an account (you may skip entering your payment information if you 
                    just want to try it). We will send you links and instructions by email that you may find helpful.`
            },
            {
                title:"What is the price for the course?",
                body: `We offer subscription types to suit your preferences and learning goals: monthly and annual.
                    Monthly Subscription: This option is perfect for learners who prefer a short-term commitment or wish to try out the 
                    course before deciding on a longer plan. It offers the flexibility to stop and start as needed.
                    Annual Subscription: Our annual plan is a cost-effective choice for learners committed to mastering Italian. It 
                    provides a significant discount compared to the monthly option, making it ideal for those who are dedicated to a consistent, long-term learning journey.
                    Lifetime access: For those who never want to worry about a subscription. Pay once and never worry about your access expiring.
                    Both subscription types offer full access to all course materials. Discover the right plan for you and view detailed pricing here.`
            },
            {
                title:"Do the prices listed include tax?",
                body: `Yes, all the prices listed for the course include tax. The amount you see is the total cost, with no hidden fees or additional 
                    charges. We believe in transparent pricing.`
            },
            {
                title:"What forms of payment do you accept?",
                body: `We use Stripe to process payments. We accept all major forms of credit cards through this platform, as well as e-checks. If you are
                    unable to pay by any of these methods please write andrew@italianaturally.com`
            },
            {
                title:"My credit card isn't being accepted. What should I do?",
                body: "Please contact andrew@italiannaturally.com and we will try to resolve the issue!"
            },
            {
                title:"I don't have a credit card, can I pay some other way?",
                body: "Yes! We also accept e-checks."
            },
            {
                title:"I forgot my password, what should I do?",
                body: "Nessun problema! To reset your password, simply click here. Follow the prompts there to reset your password quickly and securely. If you encounter any issues during the process or need further assistance, please don't hesitate to contact us at andrew@italianaturally.com. "
            },
            {
                title:"How can I cancel my subscription?",
                body: "You can either stop the subscription directly from the site, or you can write andrew@italianaturally.com at any point and request the end of your subscription. If for some reason you forgot about it, we will gladly refund the time not used."
            },
            {
                title:"What is your refund policy?",
                body: "We like to keep things simple and stress-free at Italian Naturally, including our refund policy. If at any point you decide that the course isn't quite right for you, no worries! Just drop an email to andrew@italiannaturally.com, and we'll take care of your request for a full refund. "
            },      
        ]
    },
    {
        title:'System/Browser Requirements',
        faqs : [
            {
                title: "What are the computer system requirements?",
                body: "We've got you covered across the board! We support the current and prior releases of Chrome, Firefox, Edge, Internet Explorer, and Safari. To make the most of your course, make sure you update your browser to the latest version. "
            },

            {
                title: "Can I use the course on my IPad, IPhone, Smartphone or Tablet?",
                body: "Yes, absolutely! Italian Naturally is designed to be fully compatible with a range of devices, including iPads, iPhones, smartphones, and tablets. Our course utilizes responsive web-application technology, so you can conveniently access and engage with your Italian lessons using the web browser on your device. "
            },

            {
                title: "Do you have an iOS or Android app?",
                body: "No, we currently dont offer a standalone app. However, Italian Naturally is developed using cross-platform web-application technology which ensures a seamless experience on various devices, including computers, tablets, and smartphones. You can easily access the course through your preferred web browser on any device. If you have technical issues, or want to make a suggestion, feel free to reach out to andrew@italianaturally.com."
            },

            {
                title: "Can I access the course off-line?",
                body: "No, the course requires an internet connection."
            }
        ]
    },
    {
        title:'Course functionality',
        faqs : [
            {
                title:"How can I slow down the audio playback speed?",
                body:`Let's dive into how you can slow down the audio playback speed and fine-tune your learning experience! 
                    When you are in a conversation, you'll find the playback speed icon in the top left corner of your lesson menu. 
                    You can choose your speed, whether it's the default normal speed (1x), or a more leisurely tempo (slow). Adjust to your heart's content and make learning a breeze!`

            },
            {
                title:"I loaded the conversation, now what?",
                body:`Go straight to the video tutorial here
                    When you first load a conversation, you will be on step 1 of 7. The speech bubbles will not show you the text 
                    because the first step is to listen to the conversation as if you were in Italy. In blue you can find 
                    instructions about what do focus on for each step. Use the arrows to go back and forth between the 7 steps.`

            },
            {
                title:"What's the difference between Itaglish and English translation?",
                body:`One of the most difficult (and fun!) part of learning a new language is learning how people actually speak. 
                You can't just translate directly from one language to another, and Italian is no exception. A great example of 
                this in Italian is how we explain how many people there are in a group. In English, we say, "There are four of us", 
                while in Italian we say, "We are in four".
                
                This is where Itaglish and English translations come in! An Italian learning English will often respond to the question, 
                "how many people are in your group" with "We are in two". This is Itaglish… a literal translation from Italian to 
                English (and incorrect). However, this has great value for us learners! Learning the Itaglish can help us understand 
                how Italians are formulating their sentences, especially when it comes to prepositions!`

            },
            {
                title:"I can't hear the audio, what should I do?",
                body:`First confirm that you can hear other sounds from your computer, that your speakers are turned on and with the 
                    volume up. If you still run into problems, write andrew@italianaturally.com and we can provide help via phone, video 
                    call, email, or texting. However you prefer.`

            },
            {
                title:"I can't record my voice, what should I do?",
                body:`Write andrew@italianaturally.com and we can provide help via phone, video call, email, or texting. However you prefer.`

            },
            {
                title:"Can I increase the font size?",
                body:`To boost the font size on your course content, you can simply zoom in on your screen using these simple steps:
                    1. On most common browsers, such as Chrome, Firefox, Edge, and Safari, hold down the Control key (or Command on a Mac) on your keyboard and press the + (plus) button to zoom in.
                    2. To zoom out, just hold down the Control key (or Command on a Mac) and press the - (minus) button.
                    3. To return to the original size, hold down the Control key (or Command on a Mac) and press the number 0.`

            },
            {
                title:"How can I access the practice mode? ",
                body:`Good question, that's very easy! Once you're logged in to Italian Naturally, you'll find the "conversations" 
                and the "practice" modes in your top menu in the middle.`
            }
        ]
    }
];

const Faq = () => {

    const { dispatch, state } = React.useContext(AppContext);
    
    return (
        <>
        <NavFooter loginButton={true} conversationListButton={false} pwaInstallButton={true} />
        {/*  */}
        <Header 
            conversationListButton={ state.auth.token } 
            aboutCourseButton = { !state.auth.token } 
            contactButton={true}
            pwaInstallButton={false} 
            faqButton={true} 
            pricingButton={!state.auth.token} 
            aboutUsButton={true}  />
        <div className="container">   
            
            <Row className="p-4 d-flex flex-row">
                <h1 className="text-center text__lora">Frequently asked questions</h1>
            </Row>
            <Row className="align-items-center">
                Can't find an answer to your question? <Button variant="link w-auto m-0" href="mailto:andrew@italiannaturally.com">Email us</Button> or use the <Button variant="link m-0 w-auto" href="/contact">Web form</Button>
            </Row>
            { courseDat.map((cd, index) => (
                <div className="row my-4 py-4 align-items-start justify-content-between layout__drop-shadow about_analysis">
                    <div className="col-lg-4 pb-3">
                        <svg style={{marginBottom:'20px'}} width="21" height="32" viewBox="0 0 27 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.93719 22.6801C7.93719 23.603 8.1833 24.5874 8.67553 25.7565H17.5356V24.9566C17.5356 23.726 18.4586 22.7416 20.6121 21.0188L21.7811 20.0343C25.1652 17.1425 26.3957 14.989 26.3957 11.728C26.3957 5.02139 20.6736 0.652863 13.4747 0.652863C7.13732 0.652863 2.39962 3.85235 0.615295 10.4359L9.22928 14.2507C10.3983 11.6049 11.4443 10.3744 13.1056 10.3744C14.5207 10.3744 15.5667 11.2973 15.5667 12.5279C15.5667 13.7584 15.013 14.4352 12.7364 16.158C9.35234 18.6192 7.93719 20.6496 7.93719 22.6801Z" fill={DealColor()}/>
                            <path d="M13.5733 41C10.2978 41 8 38.6429 8 35.5C8 32.4063 10.2978 30 13.5733 30C16.7022 30 19 32.4063 19 35.5C19 38.6429 16.7022 41 13.5733 41Z"  fill={DealColor()}/>
                        </svg>
                        <div className='fw-normal text__inter fs-4 my-2'>{cd.title}</div>
                    </div>
                    <div className='col-lg-8 d-flex flex-column'>
                        <Accordion defaultActiveKey="0">
                            {cd.faqs.map((faq,f_index) => (
                                <Accordion.Item eventKey={f_index}>
                                
                                    <Accordion.Button className="text__inter fw-semibold">
                                        {faq.title}
                                    </Accordion.Button>
                                
                                    <Accordion.Body className="lh-lg">
                                        {faq.body}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}

                        </Accordion>                    
                    </div>
                </div>
            ))
            }
            
            <div className="row my-3 justify-content-center tryItBanner">
                <div className="flex-row col-12 d-flex justify-content-around align-items-center py-5 flex-wrap">
                    <div className='fw-semibold text__banner--weight'>Ready to start speaking?</div>
                    <button className="buttonTryIt text__inter fw-normal layout__drop-shadow--white p-3" onClick=''>Try it for free!</button>
                </div>
            </div>
        </div>

        <Footer />
        </>
    );
}

export default Faq;