import React, { useEffect, useRef } from "react";
import AuthAPI from "../../utils/AuthAPI";
import SubscriptionAPI from "../../utils/SubscriptionAPI";
import Helper from "../../utils/Helper";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { AppContext } from "../../App";

import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const StripePaymentForm = (props) => {

    const { stripeClientSecret, buttonText='Start talking with 7 days free!' } = props;
    
    const options = {
      // passing the client secret obtained in step 2
      clientSecret: stripeClientSecret,
      // Fully customizable with appearance API.
      appearance: {
        theme: 'flat'
      },
      variables: {
        fontFamily: ' "Gill Sans", sans-serif',
        fontLineHeight: '1.5',
        borderRadius: '10px',
        colorBackground: '#F6F8FA',
        colorPrimaryText: '#262626'
      },
      rules: {
        '.Block': {
          backgroundColor: 'var(--colorBackground)',
          boxShadow: 'none',
          padding: '12px'
        },
        '.Input': {
          padding: '12px'
        },
        '.Input:disabled, .Input--invalid:disabled': {
          color: 'lightgray'
        },
        '.Tab': {
          padding: '10px 12px 8px 12px',
          border: 'none'
        },
        '.Tab:hover': {
          border: 'none',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
        },
        '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
          border: 'none',
          backgroundColor: '#fff',
          boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
        },
        '.Label': {
          fontWeight: '500'
        }
      }
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm buttonText={buttonText} />
        </Elements>
    )
}

export default StripePaymentForm;