import React, { useEffect, useRef } from "react";
import { useHistory, NavLink, useLocation, Link } from "react-router-dom";
import Header from "../components/layout/Header";
import Helper from "../utils/Helper";
import { AppContext } from "../App";
import Const from '../utils/Constants';
import AuthAPI from "../utils/AuthAPI";
import GLogin from '../components/GoogleOAUTH/GLogin';
import {ReactComponent as VaBene} from '../assets/icon/vabene.svg';
import {ReactComponent as Furbo} from '../assets/icon/furbo.svg'
import Register from '../components/Register';
import Button from 'react-bootstrap/Button';


const clientID = "71680960706-3vre4iushtpejgv11c256e7st6irlf7f.apps.googleusercontent.com";

//const iphone = require('../assets/img/iphone.svg');

const Login = (props) => {
	const { dispatch, state } = React.useContext(AppContext);
	const history = useHistory();
	const search = useLocation().search;
	const [inputParams, setInputParams] = React.useState({
		email: '',
		password: '',
	});
	const [stage, setStage] = React.useState('LOGIN')
	const refPwd = useRef(null);
	const location = useLocation();

	useEffect(() => {
		if (state.auth.auth && localStorage.getItem(Const.AUTH_TOKEN)) {
			// if (state.auth.auth.token) { // update this with a test to see if token is active
			// 	console.log(state);
			// 	console.log('not authed');
			// 	// const protocol = window.location.protocol;
			// 	// window.location = `${protocol}//italiannaturally.${Helper.getHost()}/auth/login`;
			// } else {
			// 	const params = new URLSearchParams(search)
			// 	if (params.get("redirectTo")) {
			// 		history.push("/conversations");
			// 	} else {
			// 		history.push("/conversations");
			// 	}
			// }
		}
	}, [state.auth.auth])

    const handleChangeInput = (e) => {
		setInputParams({...inputParams, [e.target.name]: e.target.value});
	}

	const handleKeyPress = (e, name) => {
		if(e.key === "Enter") {
			if(name === 'email') {
				refPwd.current.focus();
			} else {
				signin();
			}
		}
	}

	const submitForm = () => {
		switch (stage) {
			case 'LOGIN':
				signin();
				break;
			case 'FORGOTPASSWORD':
				forgotPassword();
				break;
			// case 'REGISTER':
			// 	register();
			// 	break;
			// case 'REGISTER_FRIENDS_FAMILY':
			// 	register();
			// 	break;
		}
	}

	const register = () => {
		dispatch({
			type: "REGISTER_REQUEST",
			payload: {
				...inputParams,
			}
		})
	}

	const forgotPassword = () => {
		dispatch({
			type: "RECOVER_ACCOUNT",
			payload: {
				...inputParams,
			},
			
		})
	}

    const signin = () => {
		dispatch({
			type: "LOGIN_REQUEST",
			payload: {
				...inputParams,
			},
			
		})		
    }

    const buttonText = () => {
    	switch (stage) {
			case 'LOGIN':
				return "Log In"
			case 'FORGOTPASSWORD':
				return "Forgot Password"
			case 'REGISTER':
				return "Register"
			case 'REGISTER_FRIENDS_FAMILY':
				return "Register"
		}
    }

    const headerText = () => {
    	switch (stage) {
			case 'LOGIN':
				return "Log In and Speak!"
			case 'FORGOTPASSWORD':
				return "Reset Your Password"
			case 'REGISTER':
				return "Start your free trial!"
			case 'REGISTER_FRIENDS_FAMILY':
				return "Friends and Family!"
			case 'NOTICECONFIRMEMAIL':
				return "Reset Email Sent"
		}
    }

    const sendConfirmation = (email) => {
		Helper.showSpinner();
		AuthAPI.sendConfirmation({'email':email})
			.then(res => {
				Helper.hideSpinner();
				if(res.meta.code === 200) {
					Helper.showToast('success', 3000, 'Riproviamo. We\'ve have sent another confirmation email to the email provided.', '');
				}
			})
			.catch(err => {
				Helper.hideSpinner();
				if(!err.response) {
					Helper.showToast('error', 5000, `Caspita! Something went wrong, if it keeps happening please write andrew@italianaturally.com.`, '');
					return;
				}
				if (err.response.status === 400 && err.response.data) {
					const error = err.response.data;
					if (error.response.errors) {
						const errors = error.response.errors;
						for (const property in errors) {
							Helper.showToast('warning', 5000, `${errors[property]}`, error.meta.code);
						}
					}
					if (error.response.error) {
						const error_msg = error.response.error;
						Helper.showToast('warning', 5000, error_msg, error.meta.code);
					}
					return;
				}
				Helper.showToast('error', 5000, err.response.statusText, err.response.status);
			})
	}

	useEffect(() => {
		switch (location.hash) {
			case '#register':
				setStage('REGISTER');
				break;
			case '#register-friends-family':
				setStage('REGISTER_FRIENDS_FAMILY');
				break;
			case '#login':
				setStage('LOGIN');
				break;
			case '#notice-reset-password':
				setStage('NOTICERESETPASSWORD');
				break;
			case '#forgot-password':
				setStage('FORGOTPASSWORD');
				break;
			case '#notice-confirm-email':
				setStage('NOTICECONFIRMEMAIL');
				break;
		}
	}, [location.hash])

	return (
		<div className="container-fluid">
			<Header logoLink="/" isLogoCenter='true' />
			<div className="container login-container d-flex flex-column justify-content-center align-items-center">
				{/* <div className="ml-auto p-4 start-account">
					<span className="">Don't have an account? </span><span className="register" onClick={() => history.push("/auth/register")}>Get started</span>
				</div> */}
				{ stage=='REGISTER' || stage=='REGISTER_FRIENDS_FAMILY' ?
					<Register page='register' family={stage=='REGISTER_FRIENDS_FAMILY'} />
				: (
				<div className="login-form">
					{stage!='NOTICECONFIRMEMAIL' && <h1 className="text__lora">{headerText()}</h1>}
					{stage=='NOTICECONFIRMEMAIL' ? 
						<div className="align-items-center d-flex flex-column">
								<VaBene style={{width:'200px', height:'auto'}}/>
								<h4 className="text-title mt-4 ">You're registered!</h4>
								<div className="text__inter para  ">Please check your email and click the confirmation link to gain access to the course.</div>
						</div>		
					: ( stage=='NOTICERESETPASSWORD' ?
						<div className="align-items-center d-flex flex-column">
								<Furbo style={{width:'200px', height:'auto'}}/>
								<h4 className="text-title mt-4 ">You requested to reset you password!</h4>
								<div className="text__inter para  ">Check your email for password reset instructions.</div>
						</div>
					: 
					<>	
						<div className={`form1-entry-input email ${stage=='REGISTER' ? 'my-1' : 'my-4'}`}>
								<input type="email" 
									className={inputParams.email.length > 0 ? "form-control valid icon" : "form-control icon" }
									name="email" 
									autoFocus={true}
									placeholder="Email Address"
									value={inputParams.email} 
									onChange={(e) => handleChangeInput(e)} 
									onKeyPress={(e) => handleKeyPress(e, 'email')}
									/>
								{/*{inputParams.email && !Helper.validMail(inputParams.email) && 
									<div className="err-msg">The email you entered is incorrect. Try to again.</div>
								}*/}
						</div>
						{stage!='FORGOTPASSWORD' &&
							<div className={`form1-entry-input password ${stage=='REGISTER' ? 'my-1' : 'my-4'}`}>
									<input type="password" 
										ref={refPwd}
										className={inputParams.password.length > 7 ? "form-control valid icon" : "form-control icon" }
										name="password" 
										placeholder="Password"
										value={inputParams.password} 
										onChange={(e) => handleChangeInput(e)} 
										onKeyPress={(e) => handleKeyPress(e, 'password')}
									/>
							</div>
						}
	
						<div className="layout__form-submit justify-content-start align-items-center">
							<div className="p-2">
								
								<Button 
				                //disabled={processing}
				                variant="warning text-white"
				                // disabled={!inputParams.email || !Helper.validMail(inputParams.email) || !inputParams.password} 
				                onClick={() => submitForm()}>
				                {buttonText()}</Button>	

								{/*<button type="button" 
								className="background__button--access text__color--white"
								// disabled={!inputParams.email || !Helper.validMail(inputParams.email) || !inputParams.password} 
								onClick={() => submitForm()}
								>{buttonText()}</button>*/}
							</div>
							{stage!='FORGOTPASSWORD' &&
							<div className="d-flex align-items-center">
								<span style={{paddingRight:'7px'}}>or</span ><GLogin className='ml-2' register = {stage=='LOGIN' ? false : true} size="medium" buttonText = {stage=='LOGIN' ? 'signin_with' : 'signup_with'}/>
							</div>}
							
						</div>
						<div className='d-flex flex-column align-items-start mt-3'>
							{stage!='REGISTER' && 
							<Link to='/login-sign-up#register'>
								<Button variant="link" className="p-0 border-0 fs-6 my-1">Don't have an account? Register Here!</Button>
							</Link>}
							{stage!='LOGIN' && 
							<Link to='/login-sign-up#login'>
								<Button variant="link" className="p-0 border-0 fs-6 my-1">Already have an account? Login Here</Button>
							</Link>}
							<Link to='/login-sign-up#forgot-password'>
								<Button variant="link" className="p-0 border-0 fs-6 my-1">Forgot password?</Button>
							</Link>
						</div>
					</>)}
				</div>)}
			</div>
		</div>
	);
}

export default Login;