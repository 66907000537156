import React, { useEffect } from "react";
import { useParams, NavLink, useHistory } from "react-router-dom";
import AuthAPI from "../utils/AuthAPI";
import Helper from '../utils/Helper';
import { AppContext } from "../App"
import { get } from "lodash";
import Header from "../components/layout/Header";
import {ReactComponent as VaBene} from '../assets/icon/vabene.svg';
import {ReactComponent as WhatThe} from '../assets/icon/what-the.svg';
import { history } from "../App"


const ConfirmEmail = () => {
	const { dispatch, state } = React.useContext(AppContext);
	const { token } = useParams()
	const [stage, setStage]=  React.useState('UNKNOWN')
	const [message, setMessage] = React.useState('')
	const [counter, setCounter] = React.useState(5);

	useEffect(() => {
		Helper.showSpinner();
		submit();
	}, [])

	React.useEffect(() => {

		if (counter==0) {
			history.push('/login-sign-up');
		}

		counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);

	const submit = () => {
		AuthAPI.confirmEmail(token)
            .then(res => {
                Helper.hideSpinner();
                if (res.meta.code === 200) {
                    setStage('SUCCESS');
                }
            })
            .catch(err => {
                Helper.hideSpinner();
                // Helper.readError(err.response);
                if(!err.response) {
					Helper.showToast('error', 5000, `We're sorry but an error occurred. Please try again later or reach out to us.`, '');
					return;
				}
				const error = err.response.data;
				Helper.showToast('error', 5000, error.error);
				setMessage(error.error);
                setStage('FAILURE');
            });
	}

	return (
		<div className="container-fluid">
			<Header />
			<div className="layout__container--single">
				{/* <div className="ml-auto p-4 start-account">
					<span className="">Don't have an account? </span><span className="register" onClick={() => history.push("/auth/register")}>Get started</span>
				</div> */}
				<div className="sticky-top layout__offset-top-80 align-items-center d-flex flex-column">
					{stage=='SUCCESS' && <>
						<VaBene style={{width:'200px', height:'auto'}}/>
						<h4 className="text-title mt-4 ">Congratulations!</h4>
						<h6 className="text-subline ">You have confirmed your email address.</h6>
						<p>Redirecting to login in {counter} seconds.</p>
					</>}
					{stage=='FAILURE' && <>
						<WhatThe style={{width:'200px', height:'auto'}}/>
						<h4 className="text-title mt-4  ">Caspita!</h4>
						<h6 className="text-subline  ">{message}</h6>
						<p>Redirecting to login in {counter} seconds.</p>
						{/*<NavLink className="button__layout--big-link pt-4 greenPrimary" to="/login-sign-up">Log In</NavLink>*/}
					</>}
				</div>		
			</div>
		</div>
	);
}

export default ConfirmEmail;