import React from 'react';
import Header from '../components/layout/Header.js';
import Footer from '../components/layout/Footer';
import { useParams, useLocation, Link} from 'react-router-dom';
import { history } from "../App"
import ProgressRing from '../components/ProgressRing'
import CapeeshScore from '../components/CapeeshScore'
import PWAInstall from '../components/PWAInstall'
import PracticeButton from '../components/PracticeButton';

import Helper from '../utils/Helper';
import HomeAPI from '../utils/HomeAPI';
import ConversationAPI from '../utils/ConversationAPI';
import Tooltip from '@mui/material/Tooltip';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';

import toast from 'react-hot-toast';


import {ReactComponent as Furbo} from '../assets/icon/furbo.svg'


import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import GrammarAccordion from '../components/GrammarAccordion';

import HTMLRenderer from 'react-html-renderer'
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import "./_conversations.scss";

import { styled } from '@mui/material/styles';

const calcAvCapeeshScore = (dat) => {

    if (dat.capeesh_scores.length==0) return "?";

    if (dat.capeesh_scores.length<dat.n_lines) return "?";

    const scores = dat.capeesh_scores.map(d => d.capeesh_score);
    return Math.round(scores.reduce((p,c) => p+c, 0) / scores.length)

}

const ConversationList = () => {

    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [conversationList, setConversationList] = React.useState({
                                                        chapter_name:null,
                                                        about:null,
                                                        lesson:null,
                                                        conversations:null
                                                    });

    let { chapter=1 } = useParams();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const loadList = () => {
        
        const toastId = toast.loading("Getting your progress...")
        
        ConversationAPI.getListConversations(chapter)
            .then(res => {
                if (res.success) {
                    console.log(res.data);
                    setConversationList(res.data);
                    toast.success('Fatto!', {
                        id:toastId,
                    });
                }
            })
            .catch(err => {
                toast.error('Caspita!', {
                    id:toastId,
                });
                if (err.response && err.response.data) {
                    Helper.showToast('error', 5000, err.response.data.response.error, '');
                } else {
                    Helper.showToast('error', 5000, `Something went wrong. If it keeps happening, please write andrew@italianaturally.com`, '');
                }
                if (err.response.status==401) {
                    history.push(`/login-sign-up?redirectTo=${window.location.pathname}`)
                }
            });
    }

    React.useEffect(() => {
        loadList();
    }, [location]);

    //console.log(conversationList);
    return (
        <div className="container">
            <Header 
                conversationListButton={ false }
                aboutCourseButton = {false}
                practiceButton = {true}
                contactButton={true}
                pwaInstallButton={true}
                faqButton={true}
                pricingButton={false}
                aboutUsButton={true} 
            />
            {/*<Header practiceButton={true} pwaInstallButton={true} loginButton={true} />*/}
            <div className="row flex-row-reverse justify-content-center">
                <div className='px-lg-5 col-sm-12 col-lg-5 d-flex flex-column mb-2'>
                    <div className="">
                        <h1 className="text__lora">The Main Course</h1>
                        <ListGroup as="ol">
                          <ListGroup.Item
                            as="li"
                            className="d-flex align-items-start"
                          >
                            <span>
                                Return to your <Button variant="link" className="m-0 p-0 border-0 align-top"> most recent conversation</Button>.
                            </span>

                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="d-flex align-items-start"
                          >
                                <span>
                                    Go to your next <Button variant="link" className="m-0 p-0 border-0 align-top">conversation</Button>.
                                </span>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="d-flex align-items-start"
                          >
                            <span>
                                <Button variant="link" className="m-0 p-0 border-0 align-top">Practice</Button> completed conversations.
                            </span>
                            <Badge bg="primary ms-auto" pill>
                              14
                            </Badge>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <span>
                                Looking for a <Button variant="link" className="m-0 p-0 border-0 align-top">mini course</Button>?
                            </span>
                          </ListGroup.Item>
                        </ListGroup>
            {/*            <p>Return to your <Button variant="link" className="m-0 p-0 border-0 align-top">most recent conversation</Button>.</p>
                        <p>Go to your next <Button variant="link" className="m-0 p-0 border-0 align-top">conversation</Button>.</p>
                        <p><Button variant="link" className="m-0 p-0 border-0 align-top">Practice</Button> completed conversations.</p>
                        <p>Looking for a <Button variant="link" className="m-0 p-0 border-0 align-top">mini course</Button>?</p>*/}
                        {/*<HTMLRenderer html={conversationList.conversations ? conversationList.about : ''} components={{chapter1stats:Chapter1Stats}} />*/}
                    </div>
                    
                    {/*<div className="grammar2 d-xs-none d-lg-block" dangerouslySetInnerHTML={{ __html: conversationList.lesson }} />*/}
                </div>
                <div className="col-sm-12 col-lg-5 mt-2">
                    <div className='d-flex flex-row align-items-center'>
                        <h2 className="text__lora my-0">Chapter {chapter}</h2>
                        <Dropdown className="ms-2 ms-auto">
                            <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                                Select Chapter
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/conversations/chapter/1" key={1}>Chapter 1</Dropdown.Item>
                                <Dropdown.Item href="/conversations/chapter/2" key={2} disabled>Chapter 2 (coming soon)</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div id='conversation_list' className='conversation_list d-flex flex-column'>
                        <div className="w-100 p-2">
                            {conversationList.conversations ? conversationList.conversations.map((conversation, index) => (
                                <div key={index} className='d-flex flex-row align-items-center table_of_contents'>
                                    <div>
                                        <div className={conversation.has_access ? "conversation_title" : "conversation_title disable" }>
                                            <Link {...conversation.has_access ? {to:`/conversations/course/${conversation.chapter}/${conversation.lecture}`} : {onClick:handleClick} } > {conversation.title}</Link></div>
                                        <div className="conversation_tags" dangerouslySetInnerHTML={{ __html:conversation.tags}}></div>
                                    </div>
                                    <div className="dot"></div>
                                    <div>
                                        {conversation.has_access && <>
                                            <Tooltip enterTouchDelay={0} disableFocusListener title="Your average Capeesh Score for this conversation. How's your accent?">
                                                <span role="button">
                                                    <CapeeshScore use="conversationAbout" score={calcAvCapeeshScore(conversation)}/>
                                                </span>
                                            </Tooltip>
                                        
                                        {/*<div className="capeesh-score-small" style={{color:`hsl(${1.3*100}, 85%, 45%)`}}>
                                            {calcAvCapeeshScore(conversation)}
                                        </div>*/}
                                        <div>
                                            <Tooltip enterTouchDelay={0} disableFocusListener title={`You're ${conversation.progress}% through this conversation.`}>
                                                <span role="button">
                                                    <ProgressRing
                                                        radius={ 13 }
                                                        stroke={ 2.5 }
                                                        progress={ conversation.progress }
                                                    />
                                                </span>
                                            </Tooltip>
                                        </div>
                                        </>}
                                    </div>
                                </div>
                            )) : <Stack width="100%" spacing={2} >
                                    {Array.from(new Array(20)).map((item, index)=>(
                                        <Grid container key={index} wrap="nowrap" alignItems="center">
                                                <Stack width="80%" spacing={0.5}>
                                                    <Skeleton variant="text" width="30%" height={25} />
                                                    <Skeleton variant="text" width="50%" height={12} />
                                                </Stack>
                                                <Stack justifyContent="flex-end" width="20%" pr={0.3} spacing={0.5} direction="row">
                                                    <Skeleton variant="circular" ml="auto" width={20} height={20} />
                                                    <Skeleton variant="circular" width={20} height={20} />
                                                </Stack>
                                        </Grid>))}
                                </Stack>}
                        </div>
                    </div>
                </div>
                <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className='p-3'>
                    <Furbo style={{float:'right', height:'80px', width:'auto'}}/>
                    <h6>Caspita!</h6>
                    <div className="text__inter para">You're not subscribed to this conversation.</div>
                    <div className="text__inter para">To review your account or purchase a subscription, <Link className="text__color--link" to="/account#subscribe">click here</Link>.</div>
                </div>
              </Popover>
            </div>

        </div>
    );
}

export default ConversationList;
