import React from "react";
import Header from "../components/layout/Header";
import {ReactComponent as VaBene} from '../assets/icon/vabene.svg';
import {ReactComponent as WhatThe} from '../assets/icon/what-the.svg';
import { history } from "../App"

const ic_checked = require('../assets/icon/checked.png');

function ConfirmEmailCourse() {

    const [counter, setCounter] = React.useState(5);
    const [stage, setStage] = React.useState('SUCCESS');

    React.useEffect(() => {
        if (counter!=null) {
            if (counter==0) {
                history.push('/');
            }

            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
        
    }, [counter]);

    return (
        <div className="container-fluid">
            <Header />
            <div className="layout__container--single">     
                <div>
                    <div className="layout__container--single">
                        <div className="sticky-top layout__offset-top-80 align-items-center d-flex flex-column">
                            {stage=='SUCCESS' && <>
                                <VaBene style={{width:'200px', height:'auto'}}/>
                                <h4 className="text-title mt-4 ">Congratulations!</h4>
                                <h6 className="text-subline ">You signed up for our email course.</h6>
                                <p>Redirecting to login in {counter} seconds.</p>
                            </>}
                            {stage=='FAILURE' && <>
                                <WhatThe style={{width:'200px', height:'auto'}}/>
                                <h4 className="text-title mt-4  ">Caspita!</h4>
                                <h6 className="text-subline  ">Whoops, something went wrong.</h6>
                                <p>Redirecting home in {counter} seconds.</p>
                            </>}
                        </div>      
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default ConfirmEmailCourse;