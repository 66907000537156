import React from "react";
import {
	Switch,
	Route,
	Redirect,
	useHistory
} from "react-router-dom";
import ConfirmEmail from "./ConfirmEmail";
import ConfirmEmailCourse from "./ConfirmEmailCourse";
import Login from './Login';
import PasswordRecovery from './PasswordRecovery';
import PasswordReset from './PasswordReset';
import RequestConfirmationEmail from './RequestConfirmationEmail'

function AuthRoutes() {
	const history = useHistory();

	React.useEffect(() => {
		
	}, [history]);
	
	return (
		<>
			<Switch>
				<Route path="/auth/confirm-email-course" exact>
					<ConfirmEmailCourse />
				</Route>
				<Route path="/auth/password-reset/:token" exact>
					<PasswordReset />
				</Route>
				<Route path="/auth/password-recovery" exact>
					<PasswordRecovery />
				</Route>
				<Route path="/auth/request-confirmation-email" exact>
					<RequestConfirmationEmail />
				</Route>
				<Route path="/auth/confirm-email/:token" exact>
					<ConfirmEmail />
				</Route>
			</Switch>
		</>
	);
}

function RegisterRoutes() {
	const history = useHistory();

	React.useEffect(() => {
		
	}, [history]);
	
	return (
		<>
			<Switch>
				<Route path="/login-sign-up" exact>
					<Login />
				</Route>
			</Switch>
		</>
	);
}

export {AuthRoutes, RegisterRoutes};
