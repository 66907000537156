import React from "react";
import {
	Switch,
	Route,
	Redirect,
	useHistory
} from "react-router-dom";
import { AppContext } from "../App"
import Conversation from "./Conversation"
import ConversationList from "./ConversationList"
import MiniCourseList from "./MiniCourseList"

function ConversationRoutes() {
	const { state } = React.useContext(AppContext);
	const history = useHistory();

	React.useEffect(() => {
		if (state.auth.user) {
			//if (!state.auth.token) {
			//	history.push('/auth/login');
			//}
			//if (state.auth.user.homepage !== 'employer' && state.auth.user.homepage !== 'nurse') {
			//	history.push('/404');
			//}
		}
	}, [state.auth.user]);

	return (
		<>
			<Switch>
				<Route path="/conversations" exact>
					<ConversationList />
				</Route>
				<Route path="/conversations/chapter/:chapter" exact>
					<ConversationList />
				</Route>
				<Route path="/conversations/mini-courses" exact>
					<MiniCourseList />
				</Route>
				<Route path="/conversations/course/:chapter/:lesson" exact>
					<Conversation />
				</Route>
				<Route path="/conversations/course/:chapter/:lesson/:variant" exact>
					<Conversation />
				</Route>
				<Route path="/conversations/mini_course/:id" exact>
					<Conversation />
				</Route>
			</Switch>
		</>
	);
}

export default ConversationRoutes;
