import React, { useState } from 'react';
import { AppContext } from "../../App"
import { scrollToRef } from '../../utils/Helper';
import GLogout from '../GoogleOAUTH/GLogout';
import './layout.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Settings from './Settings';
import {ReactComponent as NoMic} from '../../assets/icon/mutedMic.svg'
import {ReactComponent as Mic} from '../../assets/icon/mic.svg'
import {ReactComponent as Capeesh} from '../../assets/icon/what-the-settings.svg'
import {ReactComponent as Playback} from '../../assets/icon/playback.svg'
import {ReactComponent as NoPlayback} from '../../assets/icon/noplayback.svg'

const titleImg = require('../../assets/img/title_logo_rounded.svg');
const titleLogo = require('../../assets/icon/logo_header.svg');

const Header = (props) => {
    const { text = null,
        pwaInstallButton = false,
        practiceButton = false,
        conversationButton = false,
        conversationListButton = false,
        aboutCourseButton = false,
        faqButton = false,
        pricingButton = false,
        contactButton = false,
        aboutUsButton = false,
        isLogoCenter = false,
        analysisRef = null,
        methodRef = null,
        conversationRef = null,
        howUseRef = null,
        mic_disabled=true, 
        playback_disabled=true, 
        capeesh_disabled=false, 
        capeeshDisableCallback=null,
        showRecorderSettings=false} = props;
    const { dispatch, state } = React.useContext(AppContext);
    const [openNav, setOpenNav] = useState(false);
    const [headerClass, setHeaderClass] = useState('');
    // const { windowWidth, isMobileScreen } = IsMobile();


    const logout = () => {
        dispatch({
            type: "LOGOUT_REQUEST",
            payload: {},
        });
    }

    const openModal = (type) => {
        dispatch({ type: 'OPEN_MODAL', payload:{type:type} });
    }

    const expand='lg';

    return (
        <Navbar id="pageHeader" collapseOnSelect expand={expand} sticky="top" className="bg-white">
            <Container>
                <Navbar.Brand href="/">
                            <img 
                        src={titleLogo.default}
                        className="navbar-logo"
                        alt="Logo"
                    />{' '}
                    <img
                        src={titleImg.default}
                        style={{marginBottom:-5}}
                        className="navbar-image"
                        alt="Italian Naturally" 
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto text-center">
                        { conversationListButton && 
                            <Nav.Link href="/conversations">Conversations</Nav.Link> }
                        { practiceButton && <Nav.Link href="practice">Practice</Nav.Link> }
                        { aboutCourseButton && 
                            <NavDropdown 
                                title="About the course" 
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                id="basic-nav-dropdown"
                            >
                                <NavDropdown.Item onClick={()=> howUseRef ? scrollToRef(howUseRef) : openModal('howTo') }>
                                    Course structure (how-to)
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={()=> analysisRef ? scrollToRef(analysisRef) : openModal('analysis')}>
                                    Word analysis
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={()=> methodRef ? scrollToRef(methodRef) : openModal('method')}>
                                    Innovative method
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={()=> conversationRef ? scrollToRef(conversationRef) : openModal('conversation')}>
                                    Our conversations
                                </NavDropdown.Item>
                                {/*<NavDropdown.Divider />*/}
                                
                            </NavDropdown>
                        }
                        { pricingButton && <Nav.Link href="/pricing">Pricing</Nav.Link> }
                        { contactButton && <Nav.Link href="/contact">Contact</Nav.Link> }
                        { aboutUsButton && <Nav.Link href="/story">Our story</Nav.Link> }
                        { faqButton && <Nav.Link href="faq">FAQ</Nav.Link> }
                    </Nav>
                    {state.auth.token ? 
                    <Nav>
                        <Settings
                            playback_disabled={playback_disabled}
                            capeesh_disabled={capeesh_disabled}
                            capeeshDisableCallback={capeeshDisableCallback}
                            showRecorderSettings={showRecorderSettings}
                        />    
                    </Nav>
                    :
                    <Nav>
                        <Nav.Item>
                            <Button variant="outline-primary" href="login-sign-up" className="m-1 w-auto">Sign in</Button>
                        </Nav.Item>
                        <Nav.Item>
                            <Button variant="primary" className="m-1 w-auto" onClick={()=>openModal('register')}>Try it</Button>
                        </Nav.Item>
                    </Nav>
                 }
                </Navbar.Collapse>
            </Container>
        </Navbar>

  );

}

export default Header;