import React from 'react';
import SubscriptionAPI from '../../utils/SubscriptionAPI';
import { AppContext } from "../../App";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData
} from "./utils";
 
const PaymentForm = (props) => {
  const { dispatch, state } = React.useContext(AppContext);
  const paymentForm = React.useRef();
  const { number, firstName, lastName, expiry, cvc, issuer, focused, formData, footer=[], header, subHeader, buttonText, subscriptionId, anticipatedPrice } = props;
 
  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  const handleInputFocus = ({ target }) => {
    console.log(target);
    // this.setState({
    //   focused: target.name
    // });
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    // this.setState({ [target.name]: target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const { issuer } = this.state;
    let formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    formData={
      ...formData,
      subscriptionId:subscriptionId,
      anticipatedPrice:anticipatedPrice
    }

    console.log(formData);

    dispatch({
      type: "SUBSCRIBE",
      payload: {
        ...formData,
      },
      
    })

    // this.setState({ formData });
    //paymentForm.reset();
  };
  
  return (
    <div style={{width:'450px', maxWidth:'80vw'}}>
    {header &&
    <div className='d-flex flex-column align-items-start mt-3'>
       <h6>{header}</h6>
    </div>
    }
    <form ref={paymentForm} onSubmit={handleSubmit}>
      <div className="form1-entry-input ccard my-2">
        <input
          type="tel"
          name="number"
          className="form-control icon"
          placeholder="Card Number"
          pattern="[\d| ]{16,22}"
          required
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="form1-entry-input validThru my-2">
          <input
            type="tel"
            name="expiry"
            className="form-control"
            placeholder="MM/YY *"
            pattern="\d\d/\d\d"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="form1-entry-input cvc my-2">
          <input
            type="tel"
            name="cvc"
            className="form-control"
            placeholder="CVC *"
            pattern="\d{3,4}"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="form1-entry-input zip my-2">
          <input
            type="tel"
            name="zip"
            className="form-control"
            placeholder="ZIP"
            pattern="\d{4,5}"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="form1-entry-input name my-3">
          <input
            type="text"
            name="firstName"
            className="form-control"
            placeholder="First Name"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="form1-entry-input name my-3">
          <input
            type="text"
            name="lastName"
            className="form-control"
            placeholder="Last Name"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
      
      <input type="hidden" name="issuer" value={issuer} />
      <div className="form-actions">
        <button className="btn btn-primary btn-block">{buttonText ? buttonText : "Secure Pay $9"}</button>
      </div>
      <div className='d-flex flex-column align-items-start mt-3'>
        {footer && footer.map && footer.map((f,i) => <div className="under-links">{f}</div> )}
        <div className="under-links">Questions? Problems? Write andrew@italianaturally.com</div>
      </div>
    </form>
    </div>
  );
}

export default PaymentForm;