import { GET, POST } from './Http';

const apiBase = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : '/api';

const scoreRecording = async (data) => {
    const url = `${apiBase}/conversation/score_recording`;
    return POST(url, data);
}

const getListConversations = async (chapter) => {
    const url = `${apiBase}/conversation/list/${chapter}`;
    return GET(url);
}

const getListMiniCourses = async () => {
    const url = `${apiBase}/mini_course/list`;
    return GET(url);
}

const getConversationDetails = async (chapter, lecture, variant) => {
    const url = `${apiBase}/conversation/${chapter}/${lecture}/${variant}`;
    return GET(url);
}

const getConversationCapeeshScores = async (chapter, lecture) => {
    const url = `${apiBase}/conversation/capeesh/${chapter}/${lecture}`;
    return GET(url);
}

const getMiniCourseDetails = async (id) => {
    const url = `${apiBase}/mini_course/${id}`;
    return GET(url);
}

const getAudio = async (id) => {
    const url = `${apiBase}/audio/${id}`;
    return GET(url);
}

const updateProgress = async (data) => {
    const url = `${apiBase}/conversation/set_progress`;
    return POST(url, data);
}

const getProgressById = async (conversation_id) => {
    const url = `${apiBase}/conversation/get_progress/${conversation_id}`;
    return GET(url);
}

const getProgress = async (chapter, lesson) => {
    const url = `${apiBase}/conversation/get_progress/${chapter}/${lesson}`;
    return GET(url);
}

const ConversationAPI = {
    scoreRecording: scoreRecording,
    getListMiniCourses: getListMiniCourses,
    getListConversations: getListConversations,
    getConversationDetails: getConversationDetails,
    getConversationCapeeshScores:getConversationCapeeshScores,
    getMiniCourseDetails: getMiniCourseDetails,
    getAudio: getAudio,
    updateProgress:  updateProgress,
    getProgress:getProgress,
    getProgressById:getProgressById

}

export default ConversationAPI;
