import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

function OffCanvas(props) {
  const {
    buttonText='Open',
    className=null,
    copy=null,
    title=null} = props;
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="warning rounded-pill text-white" size="sm" className={"mx-1 "+className} onClick={handleShow}>
        {buttonText}
      </Button>

      <Offcanvas placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton >
          {title && <Offcanvas.Title>{title}</Offcanvas.Title>}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="mb-0">
            {copy}
          </p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvas;