import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { AppContext } from "../../../App"
import PWAInstall from '../../PWAInstall'
import LoginButton from '../../LoginButton'
import AccountButton from '../../AccountButton'
import PracticeButton from '../../PracticeButton'
import ConversationListButton from '../../ConversationListButton'
import LogoutButton from '../../LogoutButton';
import './nav-footer.scss';
import IsMobile from '../../../utils/IsMobile';



const NavFooter = (props) => {
    const { loginButton = true, accountButton = true, logoutButton = false, installButton = true, practiceButton = false, conversationListButton = false } = props;
    const { dispatch, state } = React.useContext(AppContext);
    const { windowWidth, isMobileScreen } = IsMobile();

    

    return (
        isMobileScreen() ?
            <nav className="footer-nav navbar fixed-bottom py-0 bg-light navbar-light d-flex d-sm-block d-md-none control_panel_icon_group">
                {installButton && <PWAInstall className='footer-nav__buttons' />}
                {practiceButton && <PracticeButton className='footer-nav__buttons' />}
                {conversationListButton && <ConversationListButton className='footer-nav__buttons' />}

                {!state.auth.token ?
                    loginButton &&
                    <div className="footer-nav--mobile">
                        <ul className="nav-lists">
                            <li className="nav-item">
                                <NavLink className='button__layout-signin' to='/login-sign-up'>sign in</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className='button__layout-try' to='/#register'>try it</NavLink>
                            </li>
                        </ul>
                    </div> :
                    (
                        accountButton && <AccountButton className='footer-nav__buttons'  />,
                        logoutButton && <LogoutButton className='footer-nav__buttons'  />
                    )
                }
            </nav>
            : null)
}

export default NavFooter;