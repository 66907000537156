import { history } from "../../App";
import IconButton from '@mui/material/IconButton';
import {ReactComponent as Icon} from '../../assets/icon/conversation_list.svg'

const ConversationListButton = (props) => {
	const {height='30px', width='auto', className=""} = props;
	return (
		<IconButton disableRipple={true} size="large" onClick={() => history.push('/conversations')} className={className}>
		    <Icon style={{height:height, width:width}} />
		</IconButton>
		)
	
}

export default ConversationListButton