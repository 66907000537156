import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const stats = require('../../assets/img/chapter_1_stats.svg');

const ConversationModal = () => {
	return (<>
        <Row className="bg-orange-light-gray text-center rounded-top-5">
            <h2 className="mt-5 fw-light">Conversation Is the Foundation of Our Course</h2>
        </Row>
        <Row className="mb-5 p-5 bg-orange-light-gray rounded-bottom-5 justify-content-around">
            <Col xs={12} md={5} className="my-1 align-items-start justify-content-start d-flex flex-column">
                <div className="p-3">
                    <img
                        src={stats.default}
                        alt="Stats Chapter 1"
                        style={{width:'100%', maxHeight:"80px"}}
                    />
                </div>
                <div className="p-3">
                    <small>Discover the word breakdown of our first 20 course conversations. Each number shows the percentage of words you’ll 
                    be exposed to based on our unique analysis of 1.7 million words in modern Italian conversations. For example, mastering 
                    the adverbs from these conversations means you'll understand spoken adverbs 76% of the time.</small>
                </div>
            </Col>
            <Col xs={12} md={5} >
                <p className="fs-6 fs-md-4">As a student, you will jump directly into conversations that are specifically designed to introduce you to the words you need to know. Each conversation is about four exchanges between two native speakers and mimics the immersive experience of natural language learning. Initially, you will listen and try to understand, and then you’ll practice speaking as native speakers do. This is essential to be understood!</p>

                <p className="fs-6 fs-md-4">While our primary focus is on dialogue, each conversation is enriched with a brief grammar/cultural lesson that has been curated by a university Italian professor. This approach ensures a deeper understanding and context, especially crucial for understanding the numerous idioms prevalent in daily Italian conversation.</p>

                <p className="fs-6 fs-md-4">We understand that speaking to your phone or computer might feel awkward! But you know what's even worse? That look of confusion on someone's face when your pronunciation is all wrong, and, despite your best efforts, they don't understand you.</p>

                <p className="fs-6 fs-md-4">Ultimately, practicing in the privacy of your room is a gentle step compared to approaching a stranger in Italy for a recommendation! And besides, our system never saves your voice. Recordings are temporary, and just for you.</p>
            </Col>
        </Row>
    </>
	)
}

export default ConversationModal;