import React from "react";
import Header from "../layout/Header";
import {ReactComponent as Chapter1Stats} from '../../assets/img/chapter_1_stats.svg'
import {ReactComponent as PracticeCardSVG} from '../../assets/img/practiceCard.svg'
// import {ReactComponent as ScrollDown} from '../../assets/icon/scroll-down.svg'
import {ReactComponent as ModalPopOut} from '../../assets/icon/modal-popout.svg'
import {ReactComponent as CapeeshImg} from '../../assets/img/main/capeesh.svg';
import {ReactComponent as ReplayImg} from '../../assets/img/main/replay.svg';
import {ReactComponent as PlaybackSpeedImg} from '../../assets/img/main/playbackSpeed.svg';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const listenImg = require('../../home/Main/conversation_hidden.svg');
const capeeshFeedbackImg = require('../../assets/img/feedback.png');
const recallImg = require('../../assets/img/recall.png');
const hiddenImg = require('../../assets/img/hidden.png');

const HowToModal = () => {
	return (
        <> 
            <div className="row py-1 py-lg-2 px-3">
                <div className="pt-0 pt-lg-0 flex-column align-items-stretch">
                    <div>                    
                        <h1 className="text__lora">How to get the most out of this course</h1>

                        <div className="row pt-2 mb-4 align-items-start">
                            <div className="col-lg-4 d-flex flex-column align-items-center justify-content-start px-4">
                                <div className='howto__bubbles howto__bubbles--1 my-2'></div>
                                <p className="fs-5 fs-md-4   my-2">Listen and Speak Along with Conversations</p>
                            </div>
                            <div className="col-lg-4 d-flex flex-column align-items-center justify-content-start px-4">
                                <div className='howto__bubbles howto__bubbles--2 my-2'></div>
                                <p className="fs-5 fs-md-4   my-2">Work on your Pronunciation</p>
                            </div>
                            <div className="col-lg-4 d-flex flex-column align-items-center justify-content-start px-4">
                                <div className='howto__bubbles howto__bubbles--3 my-2'></div>
                                <p className="fs-5 fs-md-4   my-2">Practice</p>
                            </div>                    
                        </div>

                        <p className="fs-5 fs-md-4 text__lora">
                            Try to spend <span>20 minutes each day</span> working through the conversations.
                        </p>

                        <h2 className="text__lora">Listen, without grand expectations</h2>

                        <div className='d-flex flex-row flex-wrap'>
                            <div className="col-12 col-lg-7 pl-0">
                                <p className="fs-5 fs-md-4 text__lora">
                                    We recommend that you start with listening - no pressure, just try to figure out where the words start and stop. Can you understand anything?
                                </p>

                                <div className='d-flex flex-row pb-1 text__inter bg-light rounded'>
                                    <div><PlaybackSpeedImg style={{margin:'10px', width:'45px', height:'auto'}} /></div>
                                    <div className='d-flex align-items-center'>
                                        <span className="pl-2 text__lora">Don't be afraid to slow down the playback at any point!</span>
                                    </div>
                                </div>

                                <p className="fs-5 fs-md-4 text__lora">
                                    Once you feel ready, move on to the next step: talk along with the native speakers. We know, it's awkward at first, but you'll get used to it! Do this until you think you're ready to try on your own.
                                </p>
                            </div>
                            <div className="col-12 col-lg-5 justify-content-center d-flex align-items-center">
                                <img 
                                    src={listenImg.default}
                                    alt="Listen"
                                    style={{height:"270px"}}
                                />
                                {/*<img style={{height:"270px"}} src={hiddenImg.default} />*/}
                            </div>
                        </div>


                        <h2 className="text__lora">Work on your pronunciation and pacing</h2>

                        <p className="fs-5 fs-md-4 text__lora">
                            Next, you'll speak each half of the conversation alone. Remember to slow it down! Once you're done, listen to yourself and the native speaker, back to back!
                        </p>

                        <div className='d-flex flex-row align-items-center justify-content-center flex-wrap'>
                            <div className="col-12 col-lg-6 d-flex justify-content-center">
                                <img style={{width:"80%", height:"auto"}} src={capeeshFeedbackImg.default} />
                            </div>
                            <div className="col-12 col-lg-6 p-0 p-lg-2">
                                <p className="fs-5 fs-md-4 text__lora">Words that we didn't quite understand will be highlighted.</p>
                                <p className="fs-5 fs-md-4 text__lora">The CapeeshScore, a smiley (or frowny) face, will let you know how well we understood you.</p>
                            </div>
                        </div>

                        <div className='d-flex flex-row bg-light text__inter rounded'>
                            <div className="align-items-start d-flex p-2">
                                <ReplayImg style={{margin:'0px', width:'45px', height:'auto'}} />
                            </div>
                            <div className="d-flex align-items-start px-2">
                                <p className="fs-5 fs-md-4 text__lora">TIP! These feedback tools are helpful, but it's more important that you listen back to yourself (by clicking the smiley face above). If the CapeeshScore is disabled on your device, you'll see this icon instead.</p>
                            </div>
                        </div>

                        <h2 className="text__lora">Recall Italian</h2>

                        <p className="fs-5 fs-md-4 text__lora">
                            By now you've repeated this conversation dozens of times, and it's time to recall the Italian from memory!
                        </p>

                        <div className='d-flex flex-row flex-wrap'>
                            <div className="col-12 col-lg-6 pl-0">
                                <p className="fs-5 fs-md-4 text__lora">Words that we didn't quite understand will be highlighted.</p>
                                <p className="fs-5 fs-md-4 text__lora">The CapeeshScore, a smiley (or frowny) face, will let you know how well we understood you.</p>
                            </div>
                            <div className="col-12 col-lg-6 justify-content-center d-flex align-items-center">
                                <img style={{maxWidth:"80%", maxHeight:"200px"}} src={recallImg.default} />
                            </div>
                        </div>

                        <h2 className="text__lora">We help you retain what you've learned</h2>

                        <p className="fs-5 fs-md-4 text__lora">Once you've finished a conversation, jump over to Practice Mode. We keep track of how well you've learned something, and use that to optimize your practice.</p>

                        <div className="my-4 d-flex justify-content-center"><PracticeCardSVG style={{width:'80%', height:'auto'}}/></div>

                        <p className="fs-5 fs-md-4 text__lora">If it was easy for you to remember, we'll show it to you less often. If it was hard to remember, we'll prompt you again soon!</p>

                        <h2 className="text__lora">Enough reading, let's talk! Parliamo!</h2>

                    </div>
                </div>
            </div>
            
            <div className="row py-0 py-lg-1 my-4 justify-content-center">
                <Button href="/login-sign-up">Continue to the Course!</Button>
            </div>
        </>
    );
}

export default HowToModal;