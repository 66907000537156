import React from "react";
import {
	Switch,
	Route,
	Redirect,
	useHistory
} from "react-router-dom";
import { AppContext } from "../App"
import Practice from "./index.js"

function PracticeRoutes() {
	const { state } = React.useContext(AppContext);
	const history = useHistory();

	React.useEffect(() => {
		if (state.auth.user) {
			//if (!state.auth.token) {
			//	history.push('/auth/login');
			//}
			//if (state.auth.user.homepage !== 'employer' && state.auth.user.homepage !== 'nurse') {
			//	history.push('/404');
			//}
		}
	}, [state.auth.user]);

	return (
		<>
			<Switch>
				<Route path="/practice" exact>
					<Practice />
				</Route>
			</Switch>
		</>
	);
}

export default PracticeRoutes;
