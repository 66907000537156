import React, { useEffect, useRef, useState } from "react";
import Register from "../../components/Register";
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import NavFooter from '../../components/layout/NavFooter'

import './pricing.scss'

const Pricing = () => {
    
    return (
        <>
        <NavFooter loginButton={true} conversationListButton={false} pwaInstallButton={true} />
        {/*  */}
        <Header conversationListButton={ false } aboutCourseButton = {true} contactButton={true} pwaInstallButton={false} faqButton={true} pricingButton={true} aboutUsButton={true}  />

        <Register startStep={2} />

        <Footer />
        </>
    );
}

export default Pricing;